import { Box, Button, Container, Divider, Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Grid from "@mui/material/Unstable_Grid2";

import ControlPanelImage from "../assets/ControlPanelCapture.png";
import MultipleServers from "../assets/multipleServers.png";
import QuickButtons from "../assets/quickButtonCapture.png";
import PlayerTab from "../assets/playersTab.png";
import World from "../assets/worldCapture.png";
import ModSearch from "../assets/modSearch.png";
import FileSearch from "../assets/fileCapture.png";
import { Link } from "react-router-dom";
import AdventureBanner from "../components/AdventureBanner";

type feature = { image: string; title: string; description: string };

const Features = () => {
  const features: feature[] = [
    {
      image: ControlPanelImage,
      title: "Bespoke Control Panel",
      description:
        "Unlike many other hosting companies, we utilise our own home made control panel, this means our product gives more freedom than anyother, and you can be sure the best is yet to come",
    },
    {
      image: MultipleServers,
      title: "Own Multiple Servers",
      description:
        "Thinking of something bigger? Scale your servers with us and have all your servers in one place. (Integrated support for BungeeCord coming soon!)",
    },
    {
      image: QuickButtons,
      title: "Reusability at Your Command",
      description:
        "Waste no time searching for and tediously entering commands. All our servers come with preconfigured quick buttons to make managing your server a breeze. We allow for you to add additional buttons and modify ours free of charge, anything you want to put into the console in a much sleeker and efficient way!",
    },
    {
      image: PlayerTab,
      title: "Player Managment in One Place",
      description:
        "Whitelist, ban and authorise all your players in one place. Our player table provides insight into your players server time and provides you with excellent management tools",
    },
    {
      image: World,
      title: "Download, Upload, Back Up and More!",
      description:
        "Manage your players experience all from one interface, download your worlds and upload them as you choose and reset any dimension as and when you need. Access your back ups and acquire more slots whenever you need. Bored of what you're playing now? Pick from our list of version compatible hand chosen list of adventures and content from other members. Made something your proud of? Upload your world and share it with the rest of OP Servers!",
    },
    {
      image: ModSearch,
      title: "Manage your modded and plugin experience from the console",
      description:
        "Browse an active and growing list of mods, powered by Modrinth and Forge, installing mods and mod packs for your server has never been easier",
    },
    {
      image: FileSearch,
      title: "FTP is a thing of the past",
      description:
        "Rather than relying on file transfer servers, we have developed a way for you to access all your server files right from the control panel. You can upload, download and edit all your files in one place",
    },
  ];

  return (
    <Box>
      <NavBar />
      <AdventureBanner />
      {features.map((feature, index) => {
        return (
          <Box
            sx={{
              backgroundColor:
                index % 2 !== 0
                  ? "background.elevation2"
                  : "background.elevation3",
            }}
          >
            <Container
              maxWidth="lg"
              disableGutters
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                paddingY: "40px",
              }}
            >
              <Grid container spacing={2} width="100%" pl="16px" mb="32px">
                <Grid xs={12} md={6}>
                  {index % 2 !== 0 ? (
                    <img
                      src={feature.image}
                      width="100%"
                      style={{
                        borderColor: "#4BB543",
                        borderWidth: "1px",
                        borderStyle: "double",
                      }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      height="100%"
                    >
                      <Typography
                        variant="h2"
                        fontSize="32px"
                        textAlign="center"
                      >
                        {feature.title}
                      </Typography>
                      <Divider sx={{ my: "16px" }} />
                      <Typography>{feature.description}</Typography>
                    </Box>
                  )}
                </Grid>
                <Grid xs={12} md={6}>
                  {index % 2 == 0 ? (
                    <img
                      src={feature.image}
                      width="100%"
                      style={{
                        borderColor: "#4BB543",
                        borderWidth: "1px",
                        borderStyle: "double",
                      }}
                    />
                  ) : (
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography
                        variant="h2"
                        fontSize="32px"
                        textAlign="center"
                      >
                        {feature.title}
                      </Typography>
                      <Divider sx={{ my: "16px" }} />
                      <Typography>{feature.description}</Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        );
      })}
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          component={Link}
          to="/pricing"
          sx={{ my: "16px" }}
        >
          <Typography color="white">SEE OUR PRICING PLANS</Typography>
        </Button>
      </Box>
      <Footer />
    </Box>
  );
};

export default Features;
