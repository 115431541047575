import { Box, Container, Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <NavBar />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{ px: { xs: "16px", lg: "0" }, py: "32px" }}
      >
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          PRIVACY POLICY
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          WHO WE ARE AND HOW YOU CAN CONTACT US
        </Typography>
        <Typography gutterBottom>
          We are OP Servers LTD, incorporated in the United Kingdom of Great
          Britain and Northern Ireland. You can contact us at
          opserverpals@gmail.com
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          WHY WE USE THE DATA WE COLLECT
        </Typography>
        <Typography gutterBottom>
          We collect your name and sign in details (not password) to link you to
          any servers you have purchased, and to provide correct invoices and
          refunds
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          LAWFUL BASIS FOR DATA PROCESSING
        </Typography>
        <Typography gutterBottom>
          We have reviewed the purposes of our processing activities and collect
          data for our own purposes only, we collect minimal data, the only
          personal link being your sign in details so we can correctly deliver
          products you have paid for, or refunds
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          RETENTION PERIODS FOR PERSONAL DATA
        </Typography>
        <Typography gutterBottom>
          We retain personal data for aslong as your account is active
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          YOUR RIGHTS (WHO TO COMPLAIN TO)
        </Typography>
        <Typography gutterBottom>
          Complaints should be sent to the Information Commisioner's Office
        </Typography>
      </Container>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
