import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormLabel } from "@mui/material";

const versions = [
  "Minecraft 1.0",
  "Minecraft 1.1",
  "Minecraft 1.2",
  "Minecraft 1.3",
  "Minecraft 1.4",
  "Minecraft 1.5",
  // Add more versions here
];

const Version = () => {
  const [selectedVersion, setSelectedVersion] = useState<string>(""); // Explicitly specify the type here

  const handleChange = (event: SelectChangeEvent<string>) => {
    // Specify the correct event type
    setSelectedVersion(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <FormLabel>Select a Minecraft Version</FormLabel>
      <Select value={selectedVersion} onChange={handleChange}>
        {versions.map((version, index) => (
          <MenuItem key={index} value={version}>
            {version}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default Version;
