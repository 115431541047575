import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const autoRenewIcon = <AutorenewIcon className="spin-animation" />;

interface ColourDictionary {
  [key: string]: string;
}
interface IconDictionary {
  [key: string]: JSX.Element;
}

interface StatusTextDictionary {
  [key: string]: string;
}

export const statusColours: ColourDictionary = {
  unknown: "grey",
  offline: "#e57373",
  starting: "#ffb74d",
  online: "#66bb6a",
  stopping: "#81c784",
  restarting: "#81c784",
};

export const statusIcons: IconDictionary = {
  unknown: <QuestionMarkIcon />,
  offline: <PlayArrowIcon />,
  starting: autoRenewIcon,
  online: <StopIcon />,
  stopping: autoRenewIcon,
  restarting: autoRenewIcon,
};

export const statusText: StatusTextDictionary = {
  unknown: "UNKNOWN",
  offline: "START",
  starting: "STARTING",
  online: "STOP",
  stopping: "STOPPING",
  restarting: "RESTARTING",
};
