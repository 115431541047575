import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  versions,
  possibleCategories,
  searchTypes,
  search,
} from "./ModSearchVariables";
import ModTile from "./ModTile";

interface Props {
  searchType: searchTypes;
}

const ModSearch: React.FC<Props> = ({ searchType }): JSX.Element => {
  const [modsData, setModsData] = useState<{
    hits: Array<{
      project_id: string;
      title: string;
      description: string;
      icon_url: string;
      author: string;
      client_side: string;
      server_side: string;
    }>;
    total_hits: number;
  } | null>(null);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const version = useRef<string>("1.18.2");
  const modLoader = useRef<string>(
    search.get(searchType)!.modLoaders[0].toLowerCase()
  );
  const searchQuery = useRef<string>("");
  const serverSide = useRef<boolean>(false);
  const clientSide = useRef<boolean>(false);
  const categories = useRef<Array<string>>([]);
  const facets = useRef<string[][]>([
    [`categories:${modLoader.current}`],
    [`versions:${version.current}`],
  ]);
  const limit = useRef<number>(10);
  const pageNo = useRef<number>(1);

  const handlePageChange = (value: number): void => {
    pageNo.current = value;
    offSet.current = (value - 1) * limit.current;
    getMods();
  };

  const offSet = useRef<number>((pageNo.current - 1) * limit.current);
  const [total, setTotal] = useState<number>(100);

  const addCategory = (category: string): void => {
    categories.current.includes(category)
      ? (categories.current = categories.current.filter(
          (item) => item !== category
        ))
      : (categories.current = [...categories.current, category]);
    handlePageChange(1);
  };

  const generateFacets = (): void => {
    const makeCategories = (): Array<Array<string>> => {
      return categories.current.map((category) => {
        return [`categories:${category}`];
      });
    };

    const newFacets = [
      [`categories:${modLoader.current}`],
      [`versions:${version.current}`],
    ].concat(makeCategories());

    if (searchType == searchTypes.mod) {
      if (serverSide.current) {
        newFacets.push(["server_side:required"]);
      }

      if (clientSide.current) {
        newFacets.push(["client_side:required"]);
      }
    }

    facets.current = newFacets;
  };

  useEffect(() => {
    handlePageChange(1); // Initially get mods
  }, []);

  const getMods = () => {
    if (modLoader.current == "spigot") {
      const baseUrl: string = "https://api.spiget.org/v2/search/resources/";
      console.log(baseUrl);
    } else {
      const baseUrl: string = "https://api.modrinth.com/v2/search";
      console.log(baseUrl);
      generateFacets();

      const queryParams = new URLSearchParams({
        query: searchQuery.current,
        facets: JSON.stringify(facets.current), // Convert facets to JSON if needed
        offset: offSet.current.toString(),
        limit: limit.current.toString(),
      });
      const apiUrl: string = `${baseUrl}?${queryParams.toString()}`;

      console.log("SENDING: " + apiUrl);

      async function fetchDataAndHandleResponse(apiUrl: string): Promise<void> {
        fetch(apiUrl, {
          headers: {
            "User-Agent":
              "op-servers.com, please contact me at jackhicks02@outlook.com :)",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setModsData(data);
            console.log(data);
            setTotal(data.total_hits);
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
          });
      }
      fetchDataAndHandleResponse(apiUrl);
    }
  };

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "6px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <TextField
          sx={{
            backgroundColor: "background.elevation1",
            flex: 6,
            minWidth: "200px",
          }}
          placeholder={search.get(searchType)!.placeholder}
          onChange={(e) => {
            searchQuery.current = e.target.value;
            handlePageChange(1);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        ></TextField>
        <FormControl
          sx={{ backgroundColor: "background.elevation1", width: "120px" }}
        >
          <Select
            defaultValue={version.current}
            value={version.current}
            onChange={(e) => {
              version.current = e.target.value;
              handlePageChange(1);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px", // Adjust the max height as needed
                },
              },
            }}
          >
            {versions.map((version) => {
              return (
                <MenuItem key={version} value={version}>
                  {version}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            backgroundColor: "background.elevation1",
            minWidth: "150px",
            flex: 1,
          }}
        >
          <Select
            defaultValue={modLoader.current}
            value={modLoader.current.toLowerCase()}
            onChange={(e) => {
              modLoader.current = e.target.value.toLowerCase();
              handlePageChange(1);
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px", // Adjust the max height as needed
                },
              },
            }}
          >
            {search.get(searchType)!.modLoaders.map((modLoader) => {
              return (
                <MenuItem key={modLoader} value={modLoader.toLowerCase()}>
                  {modLoader}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Button
          size="large"
          startIcon={
            showFilters ? (
              <VisibilityIcon sx={{ color: "white" }} />
            ) : (
              <VisibilityOffIcon sx={{ color: "white" }} />
            )
          }
          onClick={() => setShowFilters((prevState) => !prevState)}
          variant="contained"
          sx={{
            width: "120px",
            display: {
              xl: "inline-flex",
              lg: "inline-flex",
              md: "inline-flex",
              sm: "inline-flex",
              xs: "inline-flex",
            },
            backgroundColor: "background.elevation1",
            "&:hover": {
              backgroundColor: "background.elevation2",
            },
          }}
        >
          <Typography color="white">Filters</Typography>
        </Button>
      </Paper>
      <Collapse in={showFilters} sx={{ mt: showFilters ? "16px" : 0 }}>
        <Paper
          elevation={4}
          sx={{
            display: "inline-block",
            alignItems: "center",
            padding: "6px",
            justifyContent: "space-between",
          }}
        >
          {searchType == searchTypes.mod ? (
            <Grid container>
              <Grid xs={6} sm={4} md={3}>
                <FormControlLabel
                  control={<Checkbox checked={serverSide.current} />}
                  label="Server Side"
                  onChange={() => {
                    serverSide.current = !serverSide.current;
                    handlePageChange(1);
                  }}
                />
              </Grid>
              <Grid xs={6} sm={4} md={3}>
                <FormControlLabel
                  control={<Checkbox checked={clientSide.current} />}
                  label="Client Side"
                  onChange={() => {
                    clientSide.current = !clientSide.current;
                    handlePageChange(1);
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          {searchType == searchTypes.plugin ? (
            <Grid container>
              <Grid xs={6} sm={4} md={3}>
                <FormControlLabel
                  label={"BungeeCord"}
                  control={<Checkbox />}
                  onChange={() => addCategory("bungeecord")}
                />
              </Grid>
              <Grid xs={6} sm={4} md={3}>
                <FormControlLabel
                  label={"Velocity"}
                  control={<Checkbox />}
                  onChange={() => addCategory("velocity")}
                />
              </Grid>
              <Grid xs={6} sm={4} md={3}>
                <FormControlLabel
                  label={"Waterfall"}
                  control={<Checkbox />}
                  onChange={() => addCategory("waterfall")}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Box>
            <Divider />
          </Box>
          <Grid container>
            {Object.entries(possibleCategories).map(
              ([categoryName, categoryNameForAPI]) => {
                return (
                  <Grid xs={6} sm={4} md={3} key={categoryName}>
                    <FormControlLabel
                      label={categoryName}
                      control={<Checkbox />}
                      onChange={() => addCategory(categoryNameForAPI)}
                    />
                  </Grid>
                );
              }
            )}
          </Grid>
        </Paper>
      </Collapse>
      <Paper sx={{ p: "16px", mt: "16px" }}>
        <Grid container spacing={2} display="flex">
          {modsData !== null && modsData ? (
            modsData.hits.map((id) => (
              <Grid xs={12} sm={12} md={6} key={id.project_id.toString()}>
                <ModTile
                  name={id.title}
                  description={id.description}
                  imageUrl={id.icon_url}
                  clientSide={id.client_side}
                  serverSide={id.server_side}
                  author={id.author}
                />
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
      </Paper>
      <Paper sx={{ p: "4px", mt: "16px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignContent: "center",
          }}
        >
          <div style={{ flex: 1 }}></div>
          <div
            style={{
              flex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(total / limit.current)}
              page={pageNo.current}
              onChange={(e, page) => handlePageChange(page)}
              color="primary"
              showFirstButton
              showLastButton
            />
          </div>
          <div style={{ flex: 1, alignItems: "center" }}>
            <FormControl>
              <Select
                value={limit.current}
                onChange={(e) => {
                  limit.current = Number(e.target.value);
                  handlePageChange(1);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Box>
      </Paper>
    </>
  );
};

export default ModSearch;
