import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import { FC, useState } from "react";
import Flag from "react-flagkit";

interface LocationDropDownProps {
  defaultLocation: string | null;
}

const LocationDropDown: FC<LocationDropDownProps> = ({ defaultLocation }) => {
  const [location, setLocation] = useState(defaultLocation);

  const handleChangeLocation = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };
  return (
    <FormControl>
      <FormLabel>Choose Location</FormLabel>
      <Select
        id="location"
        value={location ?? "choose a location"}
        onChange={handleChangeLocation}
      >
        <MenuItem value={"London"}>
          <Box sx={{ display: "flex" }}>
            <Flag country="GB" />
            ⠀London
          </Box>
        </MenuItem>
        <MenuItem value={"Frankfurt am Main"}>
          <Box sx={{ display: "flex" }}>
            <Flag country="DE" />
            ⠀Frankfurt
          </Box>
        </MenuItem>
        <MenuItem value={"Berlin"}>
          <Box sx={{ display: "flex" }}>
            <Flag country="DE" />
            ⠀Berlin
          </Box>
        </MenuItem>
        <MenuItem value={"Paris"}>
          <Box sx={{ display: "flex" }}>
            <Flag country="FR" />
            ⠀Paris
          </Box>
        </MenuItem>
        <MenuItem value={"Logrono"}>
          <Box sx={{ display: "flex" }}>
            <Flag country="ES" />
            ⠀Logroño
          </Box>
        </MenuItem>
        <MenuItem value={"Las Vegas"}>
          <Box sx={{ display: "flex" }}>
            <Flag country="US" />
            ⠀Las Vegas
          </Box>
        </MenuItem>
        <MenuItem value={"Newark"}>
          <Box sx={{ display: "flex" }}>
            <Flag country="US" />
            ⠀Newark
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LocationDropDown;
