import { Box, IconButton, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FunctionComponent, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageMyServersPopup from "./ManageMyServersPopup";

export interface ServerDescriptionProps {
  name: string;
  ip: string;
  location: string;
  datePurchased: string;
}

const ServerDescription: FunctionComponent<ServerDescriptionProps> = ({
  name,
  ip,
  location,
  datePurchased,
}) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  return (
    <Paper>
      <Grid container borderRadius="8px" border="1px solid white">
        <Grid
          xs={12}
          p="8px"
          borderBottom="1px solid white"
          display="flex"
          alignItems="center"
        >
          <Box flex={1}></Box>
          <Typography
            flex={1}
            fontWeight="bold"
            variant="h6"
            textAlign="center"
          >
            {name}
          </Typography>
          <Box flex={1} display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setOpenPopup(true)}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid xs={6} md={4} lg={4} p="8px" borderRight="1px solid white">
          <Typography fontWeight="bold">IP</Typography>
          <Typography>{ip}</Typography>
        </Grid>
        <Grid
          xs={6}
          md={4}
          lg={4}
          p="8px"
          borderRight={{ xs: "", md: "1px solid white" }}
        >
          <Typography fontWeight="bold">LOCATION</Typography>
          <Typography>{location}</Typography>
        </Grid>
        <Grid
          xs={12}
          md={4}
          lg={4}
          p="8px"
          borderTop={{ xs: "1px solid white", md: "0px" }}
        >
          <Typography fontWeight="bold">DATE PURCHASED</Typography>
          <Typography>{datePurchased}</Typography>
        </Grid>
      </Grid>
      <ManageMyServersPopup
        name={name}
        open={openPopup}
        closeFunction={setOpenPopup}
      />
    </Paper>
  );
};

export default ServerDescription;
