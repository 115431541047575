import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const TermsOfService = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <NavBar />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{ px: { xs: "16px", lg: "0" }, py: "32px" }}
      >
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          TERMS AND CONDITIONS
        </Typography>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Last Updated October 30, 2023
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          AGREEMENT TO OUR LEGAL TERMS
        </Typography>
        <Typography gutterBottom>
          We are OP SERVERS LTD ('Comapny', 'we', 'us' or 'our'), company reg
          no. 15035420, registered in The United Kingdom of Great Britain and
          Northern Ireland
        </Typography>
        <Typography gutterBottom>
          The terms and conditions (the "Terms and Conditions") govern the use
          of www.op-servers.com (the "Site"). This Site is owned and operated by
          OP Servers.
        </Typography>
        <Typography gutterBottom>
          By using this Site, you indicate that you have read and understand
          these Terms and Condtions and agree to abide by them at all times.
        </Typography>
        <Typography gutterBottom>
          These terms and conditions contain a dispute resolution clause that
          impacts your rights about how to resolved disputes. Please read it
          carefully
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Intellectual Property
        </Typography>
        <Typography gutterBottom>
          All content published and made available on our Site is the property
          of OP Servers and the Site's creators. This includes, but is not
          limited to images, text, logos, documents, downloadable files and
          anything that contributes to the composition of our Site.
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Accounts
        </Typography>
        <Typography gutterBottom>
          When you create an account on our Site, you agree to the following:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary={
                "1. You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attatched to that account."
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                "2. All personal information you provide to us through your account is up to date, accurate, truthful and that you will update your personal information if it changes."
              }
            />
          </ListItem>
        </List>
        <Typography gutterBottom>
          We reserve the right to suspend or terminate your account if you are
          using our Site illegally or if you violate these Terms and Conditions
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Sales of Goods And Services
        </Typography>
        <Typography gutterBottom>
          These Terms and Conditions govern the sale of goods and services
          available on our Site.
        </Typography>
        <Typography gutterBottom>
          We offer Minecraft Server Hosting which is accessible through our
          online digital panel
        </Typography>
        <Typography gutterBottom>
          These services will be paid for in full when the services are ordered.
        </Typography>
        <Typography gutterBottom>
          These Terms and Conditions apply to all the goods and services that
          are displayed on our Site at the time you access it. This includes all
          products listed as being out of stock. All information, descriptions,
          or images that we provide about our goods and services are as accurate
          as possible. However, we are not legally bound by such information,
          descriptions, or images as we cannot gaurantee the accuracy of all
          goods are services we provide. You agree to purchase goods and
          services from our Site at your own risk.
        </Typography>
        <Typography gutterBottom>
          We reserve the right to modify, reject or cancel your order whenever
          it becomes nescessary. If we cancel your order and have already
          processed your payment, we will give you a refund equal to the amount
          you paid. You agree that it is your responsibility to monitor your
          payment instrument to verify receipt of any refund
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Subscriptions
        </Typography>
        <Typography gutterBottom>
          Your subscriptions automatically renews and you will be automatically
          billed until we receive notification that you want to cancel the
          subscriptions
        </Typography>
        <Typography gutterBottom>
          To cancel the subscription head over to the account details panel and
          locate your server under "MANAGE MY SERVERS"
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Money back gaurantee
        </Typography>
        <Typography gutterBottom>
          We provide a money back gaurantee where you can cancel your
          subscription within the first three days of your first server purchase
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Payments
        </Typography>
        <Typography gutterBottom>
          We accept the following payment methods on our Site:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary={"1. Credit Card"} />
          </ListItem>
          <ListItem>
            <ListItemText primary={"2. Debit Card"} />
          </ListItem>
        </List>
        <Typography gutterBottom>
          When you provide us with your payment information, you authorise our
          use of and access to the payment instrument you have chosen to use. By
          providing us with your payment information, you authorise us to charge
          the amount due to this payment instrument.
        </Typography>
        <Typography gutterBottom>
          If we believe your payment has violated any law or these Terms and
          Conditions, we reserve the right to cancel or reverse your transaction
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Legal rights
        </Typography>
        <Typography gutterBottom>
          These terms and conditions do not limit your legal rights
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography gutterBottom>
          OP Servers and our directors, officers, agents, employees,
          subsidiaries, and affiliates will not be liable for any actions,
          claims, losses, damages, liabilities and expenses including legal fees
          from your use of the Site.
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Indemity
        </Typography>
        <Typography gutterBottom>
          Except where prohibited by law, by using this Site you indemnify and
          hold harmless OP Servers and our directors, officers, agents,
          employees, subsidiaries, and affiliates from any actions, claims,
          losses, damages, liabilities, and expenses including legal fees
          arising out of your use of our Site or your violation of these Terms
          and Conditions.
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Applicable Law
        </Typography>
        <Typography gutterBottom>
          These Terms and Conditions are governed by the laws of the Country of
          England
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Dispute Resolution
        </Typography>
        <Typography gutterBottom>
          Subject to any exceptions specified in these Terms and Conditions, if
          you and OP Servers are unable to resolve any dispute through informal
          discussion, then you and OP Servers agree to submit the issue before
          an arbitrator. The decision of the arbitrator will be final and
          binding. Any arbitrator must be a neutral party acceptable to both you
          and OP Servers.
        </Typography>
        <Typography gutterBottom>
          Subject to any exceptions specified in these Terms and Conditions, if
          you and OP Servers are unable to resolve any dispute through informal
          discussion, then you and OP Servers agree to submit the issue before
          an arbitrator. The decision of the arbitrator will be final and
          binding. Any arbitrator must be a neutral party, located in Bristol,
          acceptable to both you and OP Servers.
        </Typography>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Changes
        </Typography>
        <Typography gutterBottom>
          These Terms and Conditions may be amended from time to time in order
          to maintain compliance with the law and to reflect any changes to the
          way we operate our Site and the way we expect users to behave on our
          Site. We will notify users by email of changes to these Terms and
          Conditions or post a notice on our Site.
        </Typography>
      </Container>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default TermsOfService;
