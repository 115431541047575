import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useJem } from "../../../utils/JemStore";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  Label,
} from "recharts";

type DataInstance = {
  ram: number;
  cpu: number;
  time: number;
};

export const RamUsage = () => {
  const cpuPercentJem = useJem<number>("cpu-percent");
  const ramPercentJem = useJem<number>("ram-percent");
  const statusJem = useJem<string>("status");
  const [data, setData] = useState<DataInstance[]>([]); // ram, cpu, time

  const updateData = () => {
    const time = Date.now();
    const ramUsage = ramPercentJem.getValue()
    const cpuUsage = cpuPercentJem.getValue()

    setData((prevState) => {
      prevState.length >= 20 && prevState.shift();
      return [...prevState, { ram: ramUsage, cpu: cpuUsage, time: time }];
    });
  };

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const tick = () => {
      statusJem.getValue() === "online" && updateData();
      timer.current = setTimeout(tick, 1000);
    };

    // Start the timer when the component mounts
    timer.current = setTimeout(tick, 1000);

    // Cleanup the timer when the component unmounts
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <Box flex={1}>
      <ResponsiveContainer width="99%">
        <AreaChart // Use AreaChart instead of LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" label={"Time (s)"} /> {/* Update dataKey to "time" */}
          <YAxis domain={[0, 100]} ><Label position="insideLeft" angle={0}>{"(%)"}</Label></YAxis>
          <Tooltip />
          <Legend />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="cpu"
            stroke="#8884d8"
            fill="#8884d8" // Specify the area fill color
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="ram"
            stroke="#82ca9d"
            fill="#82ca9d" // Specify the area fill color
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
