import {
  Box,
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import GoodPaper from "../../GoodPaper";
import { getUsernameUUID } from "../../../firebase";

export type BanData = {
  uuid: string;
  name: string;
  created: string;
  source: string;
  expires: string;
  reason: string;
}[];

interface Props {
  name: string;
  data: BanData;
  onAdd: (toAddEntry: {
    uuid: string;
    name: string;
    created: string;
    source: string;
    expires: string;
    reason: string;
  }) => void
  onRemove: ((name: string) => void)
}

const BanList = ({ name, data, onAdd, onRemove }: Props): JSX.Element => {
  const [showError, setShowError] = useState<boolean>(false);

  const [shown, setShown] = useState<boolean>(true);
  const [nameValue, setNameValue] = useState<string>("");
  const [reasonValue, setReasonValue] = useState<string>("");
  const [localData, setLocalData] = useState<any[]>(data);

  useEffect(() => {
    setLocalData(data) // double rerender tho
  }, [data])

  const isError = localData.map((d) => d.name).includes(nameValue);

  function getCurrentFormattedDate(): string {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const timezoneOffset = -now.getTimezoneOffset();
    const timezoneOffsetHours = String(Math.floor(timezoneOffset / 60)).padStart(2, '0');
    const timezoneOffsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} +${timezoneOffsetHours}${timezoneOffsetMinutes}`;

    return formattedDate;
  }

  const revertOnError = (revertName: string) => {
    setLocalData((prevArray) => prevArray.filter((d) => d.name !== revertName));
    // TODO: ADD SNACK HERE PLEASE
  }
  const handleKeyDown = (e: React.KeyboardEvent, value: string) => {
    if (e.key === "Enter") {
      handleAdd(value);
      setNameValue("");
    }
  };
  const handleAdd = (newEntry: string) => {
    if (isError || newEntry.trim() === "") {
      return;
    }
    // Update UI (will revert on failure)
    const date = getCurrentFormattedDate()
    setLocalData((prevArray) => [...prevArray, { uuid: "", name: newEntry, created: date, source: "OPServers", expires: "forever", reason: reasonValue }]);

    // Add to the actual server's whitelist
    getUsernameUUID(newEntry).then((uuidResponse) => {
      if (uuidResponse != null) {
        const banListData = { uuid: uuidResponse, name: newEntry, created: date, source: "OPServers", expires: "forever", reason: reasonValue }
        onAdd(banListData)
      } else {
        // Revert the addition to the UI
        revertOnError(newEntry)
      }
    }).catch(() => {
      // Revert the addition to the UI
      revertOnError(newEntry)
    })
    setNameValue("");
    setReasonValue("");
    setShowError(false);
  };
  const handleRemove = (value: string) => {
    setLocalData((prevArray) => prevArray.filter((item) => item.name !== value));
    onRemove(value)
  };

  interface RowProps {
    username: string;
    reason: string;
  }

  const Row: React.FC<RowProps> = ({ username, reason }): JSX.Element => {
    return (
      <Box key={username} display="flex" alignItems="center">
        <Box flex={1} width="50%">
          <Typography key={username} sx={{ ml: "2px" }}>
            {username}
          </Typography>
        </Box>
        <Box width="50%" display="flex" alignItems="center" pl="2px">
          <Box flex={1}>
            <Typography display={{ xs: "none", md: "block" }}>
              {reason}
            </Typography>
          </Box>
          <Box display="inline" sx={{ float: "right" }}>
            <IconButton
              onClick={() => handleRemove(username)}
              sx={{ display: { xs: "inline-flex", lg: "none" } }}
            >
              <RemoveCircleOutlineIcon fontSize="small" color="error" />
            </IconButton>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleRemove(username)}
              sx={{
                display: { xs: "none", lg: "inline-flex" },
                mr: { xs: "none", lg: "4px" },
                my: { xs: "none", lg: "4px" },
              }}
            >
              Pardon
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.elevation1",
      }}
    >
      <GoodPaper
        elevation={3}
        styles={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Typography sx={{ ml: "2px" }}>{name}</Typography>
        <IconButton
          onClick={() => {
            setShown(!shown);
          }}
        >
          <ExpandMoreIcon
            style={{
              transform: shown ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </IconButton>
      </GoodPaper>
      <Collapse in={shown}>
        <Box
          sx={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderTop: "0px",
          }}
        >
          {localData.map((d, index) => {
            return index % 2 === 0 ? (
              <Row username={d.name} reason={d.reason} />
            ) : (
              <Box key={d.name} sx={{ backgroundColor: "background.elevation2" }}>
                <Row username={d.name} reason={d.reason} />
              </Box>
            );
          })}

          <Box
            display="flex"
            sx={{
              backgroundColor:
                localData.length % 2 === 0
                  ? "inherit"
                  : "background.elevation2",
            }}
          >
            <TextField
              value={nameValue}
              variant="outlined"
              error={showError && (isError || nameValue.trim() === "")}
              helperText={
                showError
                  ? nameValue.trim() !== ""
                    ? isError && "Name already in list"
                    : "Please enter a name"
                  : ""
              }
              placeholder="Enter Name"
              focused={false}
              onFocus={() => {
                setShowError(true);
                console.log(showError);
              }}
              onChange={(e) => setNameValue(e.target.value)}
              size="small"
              onKeyDown={(e) => {
                handleKeyDown(e, nameValue);
              }}
              sx={{ width: { xs: "100%", md: "50%" } }}
            />

            <TextField
              variant="outlined"
              error={showError && (isError || nameValue.trim() === "")}
              helperText={
                showError
                  ? nameValue.trim() !== ""
                    ? isError && "Name already in list"
                    : "Please enter a name"
                  : ""
              }
              placeholder="Enter Reason"
              focused={false}
              onFocus={() => {
                setShowError(true);
                console.log(showError);
              }}
              onChange={(e) => {
                setReasonValue(e.target.value);
              }}
              size="small"
              onKeyDown={(e) => {
                handleKeyDown(e, nameValue);
              }}
              sx={{
                width: "calc(50% - 36px)",
                display: { xs: "none", md: "flex" },
              }}
            />

            <Box>
              <IconButton
                onClick={() => {
                  handleAdd(nameValue);
                  setNameValue("");
                }}
                color="primary"
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default BanList;
