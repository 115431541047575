import { Box, Container, Typography } from "@mui/material";

import { CSSProperties, FC, createElement } from "react";
import { ElementObject } from "../../utils/UploadBlog";

const isElementObject = (child: any): child is ElementObject => {
  return child != null && typeof child === "object" && "Tag" in child;
};

const tagMap = new Map<string, React.FC<any>>([
  ["Container", Container],
  ["Box", Box],
  ["Typography", Typography],
]);

const GetTag = (tag: string) => {
  return tagMap.get(tag) ?? tag;
};

const ElementGenerator: FC<ElementObject> = ({
  Tag,
  style,
  child,
  url,
  variant,
}): JSX.Element => {
  const localStyle: CSSProperties = style ?? {};
  if (isElementObject(child)) {
    return createElement(
      GetTag(Tag),
      { style: localStyle },
      <ElementGenerator
        Tag={child.Tag}
        style={child.style}
        child={child.child}
        url={child.url}
        variant={child.variant}
      />
    );
  }
  return createElement(
    GetTag(Tag),
    { style: localStyle, src: url, variant: variant },
    child ? <>{child}</> : null
  );
};

interface BlogGeneratorProps {
  content: ElementObject[];
}

const BlogGenerator: FC<BlogGeneratorProps> = ({ content }) => {
  return (
    <Box>
      {content.map((element, index) => (
        <ElementGenerator key={index} {...element} />
      ))}
    </Box>
  );
};

export default BlogGenerator;
