import { Box, Button, Tooltip, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import GoodPaper from "../../GoodPaper";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface PlayersButtonsProps {
  players: string[];
  clickedPlayers: React.MutableRefObject<string[]>;
}

const PlayersButtons: FunctionComponent<PlayersButtonsProps> = ({
  players,
  clickedPlayers,
}) => {
  const [localClickedPlayers, setLocalClickedPlayers] = useState<string[]>([]);

  useEffect(() => {
    clickedPlayers.current = localClickedPlayers;
  }, [localClickedPlayers]);

  function handleClick(player: string) {
    setLocalClickedPlayers((prevArr) => {
      const playerIndex = prevArr.indexOf(player);

      if (playerIndex !== -1) {
        const updatedArr = [...prevArr];
        updatedArr[playerIndex] =
          updatedArr[playerIndex] === "*" ? player : "*";
        return updatedArr;
      }

      const asteriskIndex = prevArr.indexOf("*");
      if (asteriskIndex !== -1) {
        const updatedArr = [...prevArr];
        updatedArr[asteriskIndex] = player;
        return updatedArr;
      }

      return [...prevArr, player];
    });
  }

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      mb="16px"
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap-reverse",
          flexDirection: "",
          mb: "8px",
        }}
      >
        {players.map((player) => {
          return (
            <Box ml={"8px"}>
              <Button
                key={player}
                variant="contained"
                onClick={() => {
                  /*localClickedPlayers.includes(player)
                    ? setLocalClickedPlayers((prevState) =>
                        prevState.filter((v) => {
                          return v != player;
                        })
                      )
                    : setLocalClickedPlayers((prevState) =>
                        prevState.concat(player)
                      );*/
                  handleClick(player);
                }}
                sx={{
                  mt: "8px",
                  backgroundColor: localClickedPlayers.includes(player)
                    ? "#347E2E"
                    : "",
                }}
              >
                <Typography color="white">{player}</Typography>
                <Typography color="white" fontWeight="bold">
                  {localClickedPlayers.includes(player)
                    ? "⠀" + (localClickedPlayers.indexOf(player) + 1)
                    : ""}
                </Typography>
              </Button>
            </Box>
          );
        })}
      </Box>
      <Box>
        <GoodPaper elevation={2} styles={{ width: "100%" }}>
          <Box display="flex">
            <Box flex={1}></Box>
            <Typography
              flex={1}
              textAlign="center"
              variant="h5"
              fontWeight="bold"
            >
              ONLINE PLAYERS
            </Typography>
            <Box
              flex={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              pr="8px"
            >
              <Tooltip title="Player names you click will replace any quick commands in the console line with the players you have clicked, in that order. For example /tp (player1) (player2) will become /tp Name1 Name2">
                <HelpOutlineIcon />
              </Tooltip>
            </Box>
          </Box>
        </GoodPaper>
      </Box>
    </Box>
  );
};

export default PlayersButtons;
