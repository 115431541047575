import { Box, Paper, Typography } from "@mui/material";
import { useState } from "react";

const PlayerTableBanner = () => {
  const playerLimit = 20;
  const playersOnline = 5;

  const [percentageOffline, setPercentageOffline] = useState(
    ((playerLimit - playersOnline) / playerLimit) * 100
  );

  //DEM DO THE TIME OUT THING HERE TO SET IT?

  return (
    <Paper
      sx={{
        position: "relative",
        flex: 1,
        display: "flex",
        padding: "10px",
        justifyContent: "space-between",
        background:
          "repeating-linear-gradient( -45deg,#60BF63,#60BF63 10px,#469A48 10px,#469A48 20px)",
        borderBottomRightRadius: "10px",
        borderTopRightRadius: "10px",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          position: "absolute",
          right: 0,
          top: 0,
          height: "100%",
          width: `${percentageOffline}%`,
          background:
            "repeating-linear-gradient(-45deg,#363636,#363636 10px,#323232 10px,#323232 20px)",
          borderBottomRightRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      ></Box>
      <Typography
        flex={1}
        zIndex={1}
        color="white"
        fontWeight="bold"
        textAlign="center"
      >
        {playersOnline}/{playerLimit} Players
      </Typography>
    </Paper>
  );
};

export default PlayerTableBanner;
