import { ButtonBase, Paper, Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";

interface BlogPreviewProps {
  title: string;
  description: string;
  link: string;
  height?: number;
}

const BlogPreview: FunctionComponent<BlogPreviewProps> = ({
  title,
  description,
  link,
  height,
}) => {
  return (
    <ButtonBase
      sx={{ width: "100%", height: height ?? "480px" }}
      onClick={() => (window.location.href = `/blog/${link}`)}
    >
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.elevation4",
        }}
      >
        <Box
          flex={2}
          sx={{
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/op-servers.appspot.com/o/BlogThumbnails%2F${link}.png?alt=media&token=3b442f40-8a8a-4899-aa09-5555fbbf1839&_gl=1*sxveiv*_ga*NjU4MjU1OTU0LjE2OTYyNzA0MTk.*_ga_CW55HF8NVT*MTY5Nzg1MzgzNy4yMS4xLjE2OTc4NTYwMDkuOC4wLjA.)`,
            backgroundSize: "cover",
          }}
        ></Box>
        <Box flex={1} px="4px" borderTop="1px white solid">
          <Typography gutterBottom variant="h6" fontWeight="bold">
            {title}
          </Typography>
          <Typography textAlign="left">{description}</Typography>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

export default BlogPreview;
