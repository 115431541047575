import {
  FormControl,
  FormLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Box,
  Divider,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import GeneratorDefault from "./GeneratorDefault";

const OverWorld = () => {
  const [ultraWarm, setUltraWarm] = useState<boolean>(false);
  const [natural, setNatural] = useState<boolean>(true);
  const [piglinSafe, setPiglinSafe] = useState<boolean>(false);
  const [respawnAnchorWorks, setRespawnAnchorWorks] = useState<boolean>(false);
  const [bedWorks, setBedWorks] = useState<boolean>(true);
  const [hasRaids, setHasRaids] = useState<boolean>(true);
  const [hasSkyLight, setHasSkylights] = useState<boolean>(true);
  const [hasCeiling, setHasCeiling] = useState<boolean>(false);
  const [coordinateScale, setCoordinateScale] = useState<number>(1);
  const [ambientLight, setAmbientLight] = useState<number>(0);
  const [effects, setEffects] = useState("Unset");
  const [infiniburn, setInfiniburn] = useState(
    "minecraft:infiniburn_overworld"
  );
  const [dimension, setDimension] = useState("Unset");
  const [generator, setGenerator] = useState("Default");

  const generatorComponentDictionary: { [key: string]: JSX.Element } = {
    Default: <GeneratorDefault />,
  };

  // Handle Coordinate Scale change
  const handleCoordinateScaleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Use a regular expression to check if the input is a number
    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setCoordinateScale(Number(inputValue));
    }
  };

  const handleAmbientLightChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Parse the input value to a float and ensure it's within the range [0, 1]
    const floatValue = parseFloat(inputValue);
    if (!isNaN(floatValue) && floatValue >= 0 && floatValue <= 1) {
      setAmbientLight(floatValue);
    }
  };

  return (
    <Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridTemplateRows="repeat(4, 1fr)"
        gap="8px"
        alignItems="center"
        mb="32px"
      >
        <Box gridRow={"1"} gridColumn={"1 / 4"}>
          <FormControl fullWidth>
            <FormLabel>Name</FormLabel>
            <TextField disabled value="minecraft: overworld" fullWidth />
          </FormControl>
        </Box>
        <Box gridRow={1} gridColumn={"7 / 9"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ultraWarm}
                onChange={(e) => setUltraWarm(e.target.checked)}
              />
            }
            label="Ultra warm"
          />
        </Box>
        <Box gridRow={1} gridColumn={"9 / 11"}>
          <FormControlLabel
            control={
              <Checkbox
                checked={natural}
                onChange={(e) => setNatural(e.target.checked)}
              />
            }
            label="Natural"
          />
        </Box>
        <Box gridRow={1} gridColumn="11 / 13">
          <FormControlLabel
            control={
              <Checkbox
                checked={piglinSafe}
                onChange={(e) => setPiglinSafe(e.target.checked)}
              />
            }
            label="Piglin safe"
          />
        </Box>
        <Box gridRow={2} gridColumn="7 / 9">
          <FormControlLabel
            control={
              <Checkbox
                checked={respawnAnchorWorks}
                onChange={(e) => setRespawnAnchorWorks(e.target.checked)}
              />
            }
            label="Respwan anchor works"
          />
        </Box>
        <Box gridRow={2} gridColumn="9 / 11">
          <FormControlLabel
            control={
              <Checkbox
                checked={bedWorks}
                onChange={(e) => setBedWorks(e.target.checked)}
              />
            }
            label="Bed works"
          />
        </Box>
        <Box gridRow={2} gridColumn=" 11 / 13">
          <FormControlLabel
            control={
              <Checkbox
                checked={hasRaids}
                onChange={(e) => setHasRaids(e.target.checked)}
              />
            }
            label="Has raids"
          />
        </Box>
        <Box gridRow={3} gridColumn="7 / 9">
          <FormControlLabel
            control={
              <Checkbox
                checked={hasSkyLight}
                onChange={(e) => setHasSkylights(e.target.checked)}
              />
            }
            label="Has skylight"
          />
        </Box>
        <Box gridRow={3} gridColumn="9 / 11">
          <FormControlLabel
            control={
              <Checkbox
                checked={hasCeiling}
                onChange={(e) => setHasCeiling(e.target.checked)}
              />
            }
            label="Has ceiling"
          />
        </Box>
        <Box gridRow={1} gridColumn={"4 / 7"}>
          <FormControl fullWidth>
            <FormLabel>Coordinate Scale</FormLabel>
            <TextField
              fullWidth
              value={coordinateScale.toString()} // Convert the number to a string for display
              onChange={handleCoordinateScaleChange}
              type="text"
              placeholder="Enter Coordinate Scale"
            />
          </FormControl>
        </Box>
        <Box gridRow={2} gridColumn={"1 / 4"}>
          <FormControl fullWidth>
            <FormLabel>Ambient Light</FormLabel>
            <TextField
              value={ambientLight.toString()}
              onChange={handleAmbientLightChange}
              type="text"
              placeholder="Enter Ambient Light (0-1)"
            />
          </FormControl>
        </Box>
        <Box gridRow={2} gridColumn={"4 / 7"}>
          <FormControl fullWidth>
            <FormLabel>Fixed time</FormLabel>
            <TextField
              value={ambientLight.toString()}
              onChange={handleAmbientLightChange}
              type="text"
              placeholder="Enter Ambient Light (0-1)"
            />
          </FormControl>
        </Box>
        <Box gridRow={3} gridColumn={"1 / 4"}>
          <FormControl fullWidth>
            <FormLabel>Local Height</FormLabel>
            <TextField
              value={ambientLight.toString()}
              onChange={handleAmbientLightChange}
              type="text"
              placeholder="Enter Ambient Light (0-1)"
            />
          </FormControl>
        </Box>
        <Box gridRow={3} gridColumn={"4 / 7"}>
          <FormControl fullWidth>
            <FormLabel>Dimension</FormLabel>
            <Select
              value={dimension}
              onChange={(e) => setDimension(e.target.value as string)}
            >
              <MenuItem value="Unset">Unset</MenuItem>
              <MenuItem value="Overworld">Overworld</MenuItem>
              <MenuItem value="The nether">The nether</MenuItem>
              <MenuItem value="The end">The End</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box gridRow={4} gridColumn={"1 / 4"}>
          <FormControl fullWidth>
            <FormLabel>Infiniburn</FormLabel>
            <Select
              value={infiniburn}
              onChange={(e) => setInfiniburn(e.target.value as string)}
            >
              <MenuItem value="minecraft:infiniburn_overworld">
                minecraft:infiniburn_overworld
              </MenuItem>
              <MenuItem value="Overworld">
                Literally no clue what the other options are yet
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box gridRow={4} gridColumn={"4 / 7"}>
          <FormControl fullWidth>
            <FormLabel>World Generator</FormLabel>
            <Select
              value={generator}
              onChange={(e) => setGenerator(e.target.value as string)}
            >
              <MenuItem value="Default">Default</MenuItem>
              <MenuItem value="Superflat">Superflat</MenuItem>
              <MenuItem value="Debug">Debug world</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box mb="16px">
        <Divider>{generator}</Divider>
      </Box>
      {generatorComponentDictionary[generator]}
    </Box>
  );
};

export default OverWorld;
