import {
  Box,
  ButtonBase,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EmailIcon from "@mui/icons-material/Email";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { FaDiscord } from "react-icons/fa";

type card = {
  title: string;
  description: string;
  icon: JSX.Element;
  function: () => void;
};

const ContactInformation = () => {
  const cards: card[] = [
    {
      title: "live chat",
      description:
        "Thinking of joining us? Click here to open our live chat portal and we'll be happy to help!",
      icon: <LiveHelpIcon fontSize="large" color="primary" />,
      function: () => {
        console.log("poggers");
      },
    },
    {
      title: "submit a ticket",
      description:
        "Are you already a customer? Submit a ticket and a team member will respond ASAP",
      icon: <EmailIcon fontSize="large" color="warning" />,
      function: () => {
        console.log("poggers");
      },
    },
    {
      title: "join our discord",
      description:
        "Thinking of joining us? Click here to open our live chat portal and we'll be happy to help!",
      icon: <FaDiscord size={35} color="#7289DA" />,
      function: () => {
        window.open("https://discord.gg/SpUS8k9CPE", "_blank");
      },
    },
  ];

  return (
    <Container maxWidth="lg" disableGutters sx={{ py: "32px" }}>
      <Grid container spacing={2} width="100%" pl="16px">
        {cards.map((card) => {
          return (
            <Grid md={4} sm={6} xs={12} key={card.title}>
              <ButtonBase onClick={card.function} sx={{ height: "100%" }}>
                <Paper
                  sx={{
                    backgroundColor: "background.elevation1",
                    padding: "16px",
                    height: "100%",
                  }}
                >
                  <Stack spacing="16px">
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {card.title.toLocaleUpperCase()}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      {card.icon}
                    </Box>
                    <Typography textAlign="center">
                      {card.description}
                    </Typography>
                  </Stack>
                </Paper>
              </ButtonBase>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default ContactInformation;
