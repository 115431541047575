import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import AccountDetailsContainer from "../components/account-details/AccountPageContainer";
import UserContextProvider from "../components/account-details/UserContextProvider";
import React from "react";

const AccountDetails = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box sx={{ flex: "1" }}>
        <NavBar />
        <UserContextProvider>
          <AccountDetailsContainer />
        </UserContextProvider>
      </Box>
      <Footer />
    </Box>
  );
};

export default AccountDetails;
