import { TextField } from "@mui/material";
import { useEffect, useRef } from "react";
import { useJemListener } from "../../../utils/JemStore";

const ConsoleLine = (props: {
  handleConsoleUpdate: any;
  getLatestLogForConsole: () => void;
}) => {
  // const [consoleData, setConsole] = useState<string>(
  //   "Welcome to the Server Console!"
  // );
  const consoleJem = useJemListener<string>("console");
  const consoleText = consoleJem.getValue();

  const textFieldRef = useRef<HTMLTextAreaElement | null>(null);

  // const handleSetConsole = (message: string) => {
  //   setConsole((prevOutput) => prevOutput.concat("\n" + message));
  // };

  useEffect(() => {
    props.handleConsoleUpdate();
    if (textFieldRef.current) {
      textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*consoleData*/ consoleText]);

  useEffect(() => {
    //props.setConsole.current = handleSetConsole;
    if (consoleText.length < 150) {
      props.getLatestLogForConsole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextField
      inputRef={(el) => (textFieldRef.current = el)}
      disabled
      multiline
      fullWidth
      minRows={20}
      maxRows={20}
      value={consoleText}
      InputProps={{
        style: { fontFamily: "monospace" },
      }}
      sx={{ backgroundColor: "black" }}
    />
  );
};

export default ConsoleLine;
