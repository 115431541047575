import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { updateJem, useJemListener } from "../../../../utils/JemStore";
import AddIcon from "@mui/icons-material/Add";
import CommandInput from "./CommandInput";
import { CommandInputWithKey } from "./CustomButtonOverlay";

const AddCommands = () => {
  const isBlockedJem = useJemListener<boolean>("qb-is-blocked");
  const inputsJem = useJemListener<CommandInputWithKey[]>("qb-inputs");

  const [shouldScrollBottomRefresh, setShouldScrollBottomRefresh] =
    useState<number>(0);

  const commandScrollRef = useRef<HTMLElement | null>(null);
  const nextAvailableCommandID = useRef<number>(1);

  useEffect(() => {
    // Scroll to the bottom after the new button is added
    if (commandScrollRef.current) {
      commandScrollRef.current.scrollTop =
        commandScrollRef.current.scrollHeight;
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [shouldScrollBottomRefresh]);

  const addButton = () => {
    const n = Number(nextAvailableCommandID.current);
    updateJem(inputsJem, [
      ...inputsJem.getValue(),
      {
        key: n,
        command: "",
        jsx: <CommandInput key={n} id={n} />,
      },
    ]);
    nextAvailableCommandID.current += 1;
    setShouldScrollBottomRefresh((shouldScrollBottomRefresh + 1) % 2);
  };

  return (
    <Box
      ref={commandScrollRef}
      sx={{
        backgroundColor: "background.elevation2",
        height: "150px",
        overflowY: "scroll",
        marginBottom: "8px",
      }}
    >
      {inputsJem.getValue().map((kvp) => kvp.jsx)}
      <Box sx={{ width: "100%", textAlign: "center" }}>
        {!isBlockedJem.getValue() ? (
          <IconButton
            color="primary"
            onClick={() => {
              addButton();
            }}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default AddCommands;
