import { Typography } from "@mui/material";
import GoodPaper from "../GoodPaper";
import ModSearch from "./mods-components/ModSearch";
import { searchTypes } from "./mods-components/ModSearchVariables";

const Plugins = () => {
  return (
    <>
      <GoodPaper elevation={2} styles={{ marginBottom: "10px" }}>
        <Typography>This is where the installed plugins would go</Typography>
      </GoodPaper>
      <ModSearch searchType={searchTypes.plugin} />
    </>
  );
};

export default Plugins;
