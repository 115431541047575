import { FC } from "react";
import { JemContextProvider } from "../../../../utils/JemStore";
import CustomButtonOverlay from "./CustomButtonOverlay";
import { QuickButtonData } from "../../../../backend/ServerData";
import CommandInput from "./CommandInput";

export interface QuickButtonJemWrapperProps {
  handleSnackOpen: (buttonName: string) => void;
  handleCreation: (qbd: QuickButtonData) => boolean;
  handleEdit: (qbd: QuickButtonData, oldLabel: string) => void;
  handleDeletion: (label: string) => void;
  qbd: QuickButtonData | null | undefined;
}

const QuickButtonJemWrapper: FC<QuickButtonJemWrapperProps> = (props) => {
  return (
    <JemContextProvider contextKey="qb-name" defaultValue="">
      <JemContextProvider contextKey="qb-is-blocked" defaultValue={false}>
        <JemContextProvider
          contextKey="qb-inputs"
          defaultValue={[
            {
              key: 0,
              command: "",
              jsx: <CommandInput key={0} id={0} />,
            },
          ]}
        >
          <CustomButtonOverlay {...props} />
        </JemContextProvider>
      </JemContextProvider>
    </JemContextProvider>
  );
};

export default QuickButtonJemWrapper;
