import { Box, IconButton, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { TileColours } from "./Styles";

const PlayerFeed = () => {
  return (
    <Paper sx={{ flex: 1, padding: "5px" }}>
      <Paper
        sx={{
          backgroundColor: TileColours["Paper.Main"],
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "10px",
        }}
      >
        <Typography variant="h5" ml="5px">
          Player Feed:
        </Typography>
        <IconButton>
          <AddIcon color="primary" />
        </IconButton>
      </Paper>
      <Box sx={{ overflowY: "auto" }} pl="5px" maxHeight="80%">
        <Typography>[20:33] Player Disconnected</Typography>
        <Typography>
          [20:35] Demofmeister Made Advancement (Crafting)
        </Typography>
        <Typography>
          [20:39] Demofmeister killed Bob with a stone shovel
        </Typography>
        <Typography>[20:42] OpposedBubble1 joined the server</Typography>
        <Typography>
          [20:47] OpposedBubble1 got blown up by a creeper
        </Typography>
        <Typography>[20:50] Demofmeister kicked OpposedBubble1</Typography>
        <Typography>[20:52] Server de-opped Demofmeister</Typography>
        <Typography>[20:42] Demofmeister said "smh"</Typography>
        <Typography>[20:47] Ollie2302 joined the game</Typography>
        <Typography>[20:50] Ollie2302 killed Demofmeister</Typography>
        <Typography>[20:52] ToothTiger joined the game</Typography>
        <Typography>[20:50] Demofmeister killed Ollie2302</Typography>
      </Box>
    </Paper>
  );
};

export default PlayerFeed;
