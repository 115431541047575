import React, { FC, useRef } from "react";
import VersionHome from "./VersionHome";
import VersionPage from "./VersionPage";

export type VersionDisplay = {
  name: string;
  latestVersion: string;
  image: JSX.Element;
  isInstalled: boolean;
  backgroundColour: string;
  changeFunction?: (shouldReset: boolean, versionDict?: string) => void;
};

interface VersionProps {
  changeVanillaServerVersion: (
    shouldReset: boolean,
    versionDict?: string
  ) => void;
}

const Version: FC<VersionProps> = ({ changeVanillaServerVersion }) => {
  const [display, setDisplay] = React.useState<number>(0);
  const versionNameRef = useRef<string>("");
  const imageRef = useRef<JSX.Element>(<></>);

  const displayMap = new Map<number, JSX.Element>([
    [
      0,
      <VersionHome
        setDisplay={setDisplay}
        versionNameRef={versionNameRef}
        imageRef={imageRef}
        changeVanillaServerVersion={changeVanillaServerVersion}
      />,
    ],
    [
      1,
      <VersionPage
        setDisplay={setDisplay}
        versionNameRef={versionNameRef}
        imageRef={imageRef}
      />,
    ],
  ]);

  return displayMap.get(display) ?? <></>;
};

export default Version;
