import { FC, useEffect, useState, useContext } from "react";
import { Box, TextField, Button, Paper, Stack, Divider } from "@mui/material";
import { useUserContext } from "./UserContextProvider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { updateJem, useJemListener } from "../../utils/JemStore";

interface MenuProps {
  changeDisplay: React.Dispatch<React.SetStateAction<string>>;
  currentTab: string;
}

export const displayNames: string[] = [
  "account details",
  "payments",
  "support tickets",
  "manage my servers",
];

const Menu: FC<MenuProps> = ({ changeDisplay, currentTab }) => {
  return (
    <Paper sx={{ backgroundColor: "background.elevation4", p: "8px" }}>
      <Stack spacing="8px">
        <Box display="flex" justifyContent="center">
          <AccountCircleIcon fontSize="large" />
        </Box>
        <Divider />
        {displayNames.map((tab, index) => {
          return (
            <Button
              key={index}
              variant={tab == currentTab ? "contained" : "outlined"}
              onClick={() => changeDisplay(tab)}
            >
              {tab}
            </Button>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default Menu;
