import { initializeApp } from "firebase/app";
import { getBytes, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { getFirestore, query, getDocs, collection, where, DocumentData, doc, setDoc, QuerySnapshot, updateDoc, increment, getDoc, runTransaction, arrayUnion } from 'firebase/firestore';
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { initializeAppCheck, getToken, ReCaptchaV3Provider, CustomProvider } from 'firebase/app-check';
import { getAnalytics, logEvent } from "firebase/analytics";
import { QuickButtonData, ServerDataStruct } from "./backend/ServerData";
import { Blog } from "./utils/UploadBlog";
import { PresetWorldsDataStruct, VersionsDataStruct } from "./backend/WorldData";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const appCheckDebugProvider = new CustomProvider({
  getToken: () => {
    return new Promise((resolve, _reject) => {
      const appCheckToken = {
        token: process.env.DEBUG_MODE_TOKEN ?? "",
        expireTimeMillis: 1_000_000_000
      };

      resolve(appCheckToken);
    });
  }
});

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
const analytics = getAnalytics();


export const fetchOwnedServerData = async (): Promise<ServerDataStruct[] | null> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return null
  }

  try {
    const serversRef = collection(db, "servers");
    const q = query(serversRef, where("ownerUID", "==", user.uid))
    console.log("Querying servers owned by: " + user.uid)

    const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(q)
    //console.log("Query Snapshot:", querySnapshot);
    if (querySnapshot.empty) {
      console.log("No servers found for user! " + user.uid)
      return null
    }

    //console.log("Query Snapshot Docs:", querySnapshot.docs);
    const servers: ServerDataStruct[] = querySnapshot.docs.map((doc: DocumentData) => {
      return doc.data();
    })

    //console.log(servers)
    return servers
  } catch (err) {
    console.error("Error fetching servers from firebase: " + err)
    return null
  }
}

export const fetchOwnedServerDataAtID = async (id: number): Promise<ServerDataStruct | null> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return null
  }

  try {
    const serversRef = collection(db, "servers");
    const serverDocRef = doc(serversRef, id.toString())
    console.log("Querying servers owned by: " + user.uid + " with ID: " + id)

    const docSnapshot: DocumentData = await getDoc(serverDocRef)
    //console.log("Query Snapshot:", querySnapshot);
    if (!docSnapshot.exists()) {
      console.log("No servers found for ID! " + id)
      return null
    }

    const server = docSnapshot.data()

    console.log(JSON.stringify(server))

    if (server.ownerUID !== user.uid) {
      console.error("User does not own this server!")
      return null
    }
    //console.log("Query Snapshot Docs:", querySnapshot.docs);
    return server
  } catch (err) {
    console.error("Error fetching servers from firebase: " + err)
    return null
  }
}

export const uploadOwnedServerData = async (data: ServerDataStruct): Promise<void> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return
  }
  if (user.uid !== data.ownerUID) {
    console.error("User does not have permissions to upload this Server!")
    return
  }

  try {
    const serversRef = collection(db, "servers");
    const serverDocRef = doc(serversRef, data.id.toString())

    await setDoc(serverDocRef, data).then(() => {
      console.log("Uploaded server data successfully!")
    })
      .catch((error) => {
        console.error("Error uploading servers for user: ", error);
      });
  } catch (err) {
    console.error("Error fetching servers from firebase: " + err)
  }

  console.log("Successfuly uploaded a ServerDataStruct")

  //await uploadServerDataToAvailableVPS(data)
}

/*export const uploadServerPropertiesData = async (id: number, data: { [key: string]: any }): Promise<void> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return
  }
  // TODO: Could add server owner check here

  try {
    const serversRef = collection(db, "servers");
    const serverDocRef = doc(serversRef, id.toString())
    console.log("New server_properties: " + JSON.stringify(data))

    await updateDoc(serverDocRef, {
      server_properties: data
    }).then(() => {
      console.log("Uploaded new server_properties successfully!")
    }).catch((error) => {
      console.error("Error uploading server_properties for user: ", error);
    });
  } catch (err) {
    console.error("Error fetching servers from firebase: " + err)
    return
  }
}*/

export const uploadQuickButton = async (id: number, data: QuickButtonData): Promise<void> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return
  }

  try {
    const serversRef = collection(db, "servers");
    const serverDocRef = doc(serversRef, id.toString())

    await updateDoc(serverDocRef, {
      quick_buttons: arrayUnion({
        label: data.label,
        colour: data.colour,
        iconKey: data.iconKey,
        isDarkText: data.isDarkText,
        commands: data.commands,
        directEntry: data.directEntry
      })
    }).then(() => {
      console.log("Uploaded new button successfully!")
    }).catch((error) => {
      console.error("Error uploading new button for user: ", error);
    });
  } catch (err) {
    console.error("Error fetching servers from firebase: " + err)
    return
  }

  console.log("Successfuly uploaded a Button")
}

export const editQuickButton = async (id: number, oldLabel: string, data: QuickButtonData): Promise<void> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!");
    return;
  }

  try {
    const serverData: ServerDataStruct | null = await fetchOwnedServerDataAtID(id)

    if (serverData) {
      const updatedQuickButtons: QuickButtonData[] = serverData.quick_buttons
      console.log(serverData.quick_buttons)
      updatedQuickButtons[serverData.quick_buttons.findIndex((qbd) => qbd.label === oldLabel)] = {
        label: data.label,
        colour: data.colour,
        iconKey: data.iconKey,
        isDarkText: data.isDarkText,
        commands: data.commands,
        directEntry: data.directEntry,
      }
      console.log()

      const serversRef = collection(db, "servers");
      const serverDocRef = doc(serversRef, id.toString())
      await updateDoc(serverDocRef, {
        quick_buttons: updatedQuickButtons,
      });

      console.log("Editted button successfully!");
    } else {
      console.error("Server data not found!");
    }
  } catch (error) {
    console.error("Error uploading editted button for user: ", error);
    return
  }

  console.log("Successfully editted a Button");
};

export const deleteQuickButton = async (id: number, data: QuickButtonData): Promise<void> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!");
    return;
  }

  try {
    const serverData: ServerDataStruct | null = await fetchOwnedServerDataAtID(id)

    if (serverData) {
      const quickButtons: QuickButtonData[] = serverData.quick_buttons
      const updatedQuickButtons: QuickButtonData[] = quickButtons.filter((qbd) => qbd.label !== data.label);

      const serversRef = collection(db, "servers");
      const serverDocRef = doc(serversRef, id.toString())
      await updateDoc(serverDocRef, {
        quick_buttons: updatedQuickButtons,
      });

      console.log("Removed button successfully!");
    } else {
      console.error("Server data not found!");
    }
  } catch (error) {
    console.error("Error removing button: ", error);
    return
  }

  console.log("Successfully removed a Button");
};

export const uploadServerDataToAvailableVPS = async (data: ServerDataStruct): Promise<string | null> => {
  try {
    const serverDocRef = doc(db, "vps_servers", "1"); // Assuming the document ID is "1"
    let vpsaddress = ""

    await runTransaction(db, async (transaction) => {
      const vpsServerDocSnapshot = await transaction.get(serverDocRef);
      const vpsServerData = vpsServerDocSnapshot.data();

      if (vpsServerData) {
        // Atomically increment the ram_used field
        const newRamUsed = vpsServerData.ram_used + data.ram;
        transaction.update(serverDocRef, { ram_used: newRamUsed });

        // Add the new element to the servers array
        const vpsServersServersArray = vpsServerData.servers || [];
        vpsServersServersArray.push({ ownerUID: data.ownerUID, serverID: data.id });
        transaction.update(serverDocRef, { servers: vpsServersServersArray });

        console.log("Successfuly uploaded a ServerDataStruct to VPS Docs")
        //console.log(JSON.stringify(vpsServerData) + " -> " + vpsServerData.address)
        vpsaddress = vpsServerData.address
      }
    });

    return vpsaddress === "" ? null : vpsaddress
  } catch (err) {
    console.error("Error fetching vps_servers from firebase: " + err)
    return null
  }
}


export const getAvailableServerID = async (): Promise<number> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return -1
  }

  try {
    const serverCollection = collection(db, 'servers');
    const serverDoc = doc(serverCollection, 'atomic_identifier');

    await updateDoc(serverDoc, {
      availableID: increment(1)
    });

    const docSnapshot = await getDoc(serverDoc);
    if (docSnapshot.exists()) {
      const updatedValue = docSnapshot.data()?.availableID;
      console.log("Successfully increased the value:", updatedValue);
      return updatedValue
    } else {
      console.log("Document does not exist");
      return -1
    }
  } catch (err) {
    console.error("Error fetching servers from firebase: " + err)
    return -1
  }
}


export const getFileExplorerDataFromFirebase = async (storagePath: string): Promise<string> => {
  try {
    const fileRef = ref(storage, storagePath);
    //console.log("Getting " + storagePath + " from firebase storage...")
    const fileSnapshot = await getBytes(fileRef, 1_000_000);
    //console.log("Got data back! Decoding...")

    // Convert the downloaded bytes to a string
    const fileDataString = new TextDecoder().decode(fileSnapshot);
    //console.log("Done decoding: " + fileDataString)

    return fileDataString as string
  } catch (error) {
    console.error('getFileExplorerDataFromFirebase: Error downloading file:', error);
    return ""
  }
}
export const setFileExplorerDataOnFirebase = async (storagePath: string, file: File): Promise<boolean> => {
  try {
    const fileRef = ref(storage, storagePath);
    //console.log("Getting " + storagePath + " from firebase storage...")
    await uploadBytes(fileRef, file);

    return true
  } catch (error) {
    console.error('setFileExplorerDataOnFirebase: Error uploading file:', error);
    return false
  }
}

export const getWorldDownloadFromFirebase = async (storagePath: string): Promise<void> => {
  try {
    const fileRef = ref(storage, storagePath);
    //console.log("Getting " + storagePath + " from firebase storage...")
    //const fileSnapshot = await getBytes(fileRef, 1_000_000_000); // TODO: This may have to change
    //console.log("Got data back! Decoding...")

    const downloadURL = await getDownloadURL(fileRef)
    const response = await fetch(downloadURL)
    if (!response.ok) {
      console.error('Failed to download file, response not ok');
      return
    }
    const b = await response.blob()
    const blob = new Blob([b], { type: b.type });
    // Create a hidden anchor tag to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = storagePath.split('/').pop() || 'world-archive.tgz';
    link.rel = 'noopener noreferrer'; // Add this line
    document.body.appendChild(link);

    // Trigger the download and remove the anchor tag
    link.click();
    document.body.removeChild(link);
    return
  } catch (error) {
    console.error('getWorldDownloadFromFirebase: Error downloading file:', error);
    return
  }
}


export const fetchPresetWorldsDoc = async (): Promise<PresetWorldsDataStruct | null> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return null
  }

  try {
    const publicCollection = collection(db, 'public');
    const presetWorldsDoc = doc(publicCollection, 'preset-worlds');


    const docSnapshot = await getDoc(presetWorldsDoc);
    if (docSnapshot.exists()) {
      const worlds: PresetWorldsDataStruct = docSnapshot.data() as PresetWorldsDataStruct;
      console.log("Successfully got the preset worlds doc");
      return worlds
    } else {
      console.log("fetchPresetWorldsDoc: Document does not exist");
      return null
    }
  } catch (err) {
    console.error("Error fetchPresetWorldsDoc from firebase: " + err)
    return null
  }
}

export const UploadBlog = async (blog: Blog): Promise<void> => {

  try {
    const publicRef = collection(db, "public");
    const blogsDocRef = doc(publicRef, "blogs-data")

    await updateDoc(blogsDocRef, {
      blogs: arrayUnion(blog)
    }).then(() => {
      console.log("Uploaded new blog successfully!")
    }).catch((error) => {
      console.error("Error uploading new blog for user: ", error);
    });
  } catch (err) {
    console.error("Error uploading blogs from firebase: " + err);
    return;
  }

  console.log("Successfuly uploaded a blog");
}

export const fetchBlogs = async (): Promise<Blog[]> => {

  try {
    const publicRef = collection(db, "public");
    const blogsDocRef = doc(publicRef, "blogs-data")

    const blogs = await getDoc(blogsDocRef)

    console.log("Successfully fetched blogs")
    return blogs.data()?.blogs as Blog[] ?? []

  } catch (err) {
    console.error("Error fetching blogs from firebase: " + err);
    return [];
  }


}
export const fetchVersionsData = async (): Promise<VersionsDataStruct | null> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return null
  }

  try {
    const publicCollection = collection(db, 'public');
    const versionsDataDoc = doc(publicCollection, 'versions-data');


    const docSnapshot = await getDoc(versionsDataDoc);
    if (docSnapshot.exists()) {
      const versions: VersionsDataStruct = docSnapshot.data() as VersionsDataStruct;
      console.log("Successfully got the versions data doc");

      const lastUpdated: number = versions.last_updated
      const timeNow: number = Date.now()
      const hoursCooldown: number = 24
      const twentyFourHoursInMilliseconds: number = hoursCooldown * 60 * 60 * 1000;
      const timeDifference: number = timeNow - lastUpdated;
      if (timeDifference >= twentyFourHoursInMilliseconds) {
        //X hours have passed...
        const updatedVersions = await getVersionsFromAPI()
        if (updatedVersions) {
          setDoc(versionsDataDoc, updatedVersions) // No need to await this
          return updatedVersions
        } else {
          console.log("Failed to update versions data")
          return versions
        }
      } else {
        return versions
      }
    } else {
      console.log("fetchVersionsData: Document does not exist");
      return null
    }
  } catch (err) {
    console.error("Error fetchVersionsData from firebase: " + err)
    return null
  }
}



export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    return
  }
};

export const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    return
  }
};

export const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    return
  }
};

export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    return
  }
};

export const logout = () => {
  signOut(auth);
};


const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const appCheck = initializeAppCheck(app, {
  // ReCaptchaV3Provider
  //provider: new ReCaptchaV3Provider("" + process.env.REACT_APP_RECAPTCHA_SITE_KEY),
  provider: isLocalhost ? appCheckDebugProvider : new ReCaptchaV3Provider("" + process.env.REACT_APP_RECAPTCHA_SITE_KEY),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});


/*const getVPSAddress = async (data: ServerDataStruct): Promise<string> => {
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return ""
  }
  if (user.uid !== data.ownerUID) {
    console.error("User does not own this Server!")
    return ""
  }

  try {
    const vpsServersRef = collection(db, "vps_servers");
    const querySnapshot = await getDocs(vpsServersRef);

    if (querySnapshot.empty) {
      console.log("No VPS Servers found! " + user.uid);
      return "";
    }

    const vpsServerDoc = querySnapshot.docs.find((doc) => {
      const serverData = doc.data();
      //console.log(serverData.servers[0].serverID + " === " + data.id)
      return serverData.servers.some((server: { serverID: number, address: string }) => server.serverID === data.id);
    });

    if (vpsServerDoc) {
      const vpsData = vpsServerDoc.data();
      const address = vpsData.address;

      console.log("Server Address:", address);
      return address
    } else {
      console.log("VPS Server not found for the specified ID.");
      return ""
    }
  } catch (err) {
    console.error("Error fetching servers from firebase: " + err)
    return ""
  }
}*/

export const callVPSApiWithAppCheck = async (data: ServerDataStruct, _endpoint: string, _method: string, _params: {} = {}): Promise<any | null> => {
  try {
    //const apiAddress = await getVPSAddress(data)
    const apiAddress = data.vpsaddress
    return await callApiWithAppCheckHelper(_endpoint, _method, _params, apiAddress)
  } catch (err) {
    // Handle any errors if the token was not retrieved.
    console.error("API Request error: " + err)
    return null
  }
}
export const callDirectApiWithAppCheck = async (_fullHttp: string, _method: string, _params: {} = {}): Promise<any | null> => {
  try {
    return await callApiWithAppCheckHelper("", _method, _params, _fullHttp)
  } catch (err) {
    // Handle any errors if the token was not retrieved.
    console.error("API Request error: " + err)
    return null
  }
}

const callApiWithAppCheckHelper = async (_endpoint: string, _method: string, _params: {} = {}, _https: string): Promise<any | null> => {
  const https = _https + _endpoint

  // ================
  // CHECK LOGGED IN:
  // ================
  const user = auth.currentUser;
  if (!user) {
    console.error("User not logged in!")
    return null
  }


  // ===========================
  // GET TOKEN FOR API REQUESTS:
  // ===========================
  let appCheckTokenResponse;
  try {
    appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ true);
  } catch (err) {
    // Handle any errors if the token was not retrieved.
    console.error("API Request error: " + err)
    return null
  }


  // ===========================================
  // CALL FETCH ON PROVIDED ENDPOINT AND METHOD:
  // ===========================================
  console.log(https)
  console.log(_method)
  console.log(_params)
  const apiResponse = await fetch(https, {
    method: _method,
    headers: {
      'X-Firebase-AppCheck': appCheckTokenResponse.token,
      'Content-Type': 'application/json',
    },
    body: _params === undefined || (Object.keys(_params).length === 0) ? undefined : JSON.stringify(_params)
  });

  // =========================
  // LOG THE RESULT IN EVENTS:
  // =========================
  logEvent(analytics, "api_call", {
    endpoint: _endpoint,
    method: _method,
    status: apiResponse.status,
  });


  // ================
  // HANDLE RESPONSE:
  // ================
  let data: {}
  if (apiResponse.ok) {
    data = await apiResponse.json();
    //console.log("Response: (message)" + data.message);
  } else {
    console.error('API Request failed:', apiResponse.status);
    return null
  }


  return data;
};

//Get UUID
export const fallBackUUID: string = "069a79f4-44e9-4726-a5be-fca90e38aaf5"
export async function getUsernameUUID(name: string): Promise<string | null> {
  const endpoint1 = `https://api.minetools.eu/uuid/${name}`;

  try {
    const response = await fetch(endpoint1);
    if (response.ok) {
      const data = await response.json();
      return data.id ?? null;
    }

    return null; // User not found
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

export async function getVersionsFromAPI(): Promise<VersionsDataStruct | null> {
  const endpoint = `https://launchermeta.mojang.com/mc/game/version_manifest.json`;

  try {
    const response = await fetch(endpoint);
    if (response.ok) {
      const data = await response.json();
      const versions = data as VersionsDataStruct
      versions.last_updated = Date.now()
      return versions;
    }

    console.error("Versions API response: " + response.status)
    return null;
  } catch (error) {
    console.error("getVersionsFromAPI: Error fetching data:", error);
    return null;
  }
}

export function formatTimeDifference(seconds: number): string {
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const months = days / 30;  // Rough estimation of months

  if (months >= 1) {
    return `${Math.floor(months)} month${Math.floor(months) !== 1 ? 's' : ''}`;
  } else if (days >= 1) {
    return `${Math.floor(days)} day${Math.floor(days) !== 1 ? 's' : ''}`;
  } else if (hours >= 1) {
    return `${Math.floor(hours)} hour${Math.floor(hours) !== 1 ? 's' : ''}`;
  } else if (minutes >= 1) {
    return `${Math.floor(minutes)} minute${Math.floor(minutes) !== 1 ? 's' : ''}`;
  } else {
    return `${Math.floor(seconds)} second${Math.floor(seconds) !== 1 ? 's' : ''}`;
  }
}


export default storage;