import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GoodPaper from "../GoodPaper";
import HelpIcon from "@mui/icons-material/Help";
import {
  ServerPropertyType,
  serverPropertyFields,
} from "./ServerPropertiesFields";
import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GoodNumberTextField from "../GoodNumberTextField";
import { DiscardPopup, ErrorPopup } from "../Popup";

const ServerProperties = (props: any) => {
  //const serverData: ServerDataStruct = props.serverData.current;
  const serverPropertiesData: { [property: string]: string } =
    props.serverPropertiesData?.current ?? {};
  const sendServerProperties: (properties: {
    [property: string]: string;
  }) => void = props.sendServerProperties ?? (() => {});
  const handleClose = props.handleClose;
  const handleSnackOpen = props.handleSnackOpen;

  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );

  const [changes, setChanges] = useState<{
    [key: string]: { old: any; new: any };
  }>({});

  const [serverProperties, setServerProperties] = useState<{
    [key: string]: any;
  }>(serverPropertiesData);

  const setError = (key: string, isError: boolean) => {
    setFieldErrors((prevErrors) => {
      const newErrors = prevErrors;
      newErrors[key] = isError;
      return newErrors;
    });
  };

  function handlePropertyChange(key: string, newValue: any) {
    setServerProperties((prevProperties) => ({
      ...prevProperties,
      [key]: newValue.toString(),
    }));
  }

  const handleDiscard = () => {
    const changesMade = getChanges();
    if (Object.keys(changesMade).length <= 0) {
      handleClose();
      return;
    }
    setChanges(changesMade);
    setUnsavedChangesPopupOpen(true);
  };

  const handleSubmit = () => {
    const isErrors =
      fieldErrors &&
      Object.values(fieldErrors).length > 0 &&
      Object.values(fieldErrors).reduce((c, n) => {
        return c || n;
      });

    if (!isErrors) {
      if (!props.serverPropertiesData) {
        console.error("Could not get local properties somehow");
        return;
      }
      //uploadServerPropertiesData(serverData.id, serverProperties);
      sendServerProperties(serverProperties);
      props.serverPropertiesData.current = serverProperties;
      handleSnackOpen();
      handleClose();
      return;
    }
    console.log(JSON.stringify(fieldErrors));
    setErrorPopupOpen(true);
  };

  const getChanges = () => {
    const changes: { [key: string]: { old: any; new: any } } = {};

    for (const key of Object.keys(serverProperties)) {
      if (!(key in serverPropertiesData)) {
        console.warn("No properties: " + key + " in server_properties");
        continue;
      }
      if (serverPropertiesData[key] !== serverProperties[key]) {
        changes[key] = {
          old: serverPropertiesData[key],
          new: serverProperties[key],
        };
      }
    }

    return changes;
  };

  return (
    <Box
      width="100vw"
      height="100vh"
      sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <Grid
        container
        xs={12}
        md={8}
        lg={5}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid item xs={12}>
          <GoodPaper
            elevation={3}
            styles={{
              display: "inline-block",
              padding: "8px",
              width: "100%",
            }}
          >
            <GoodPaper elevation={4}>
              <Box display="flex" alignItems="center">
                <Box
                  sx={{ position: "absolute", right: 0, mr: "6px", flex: "1" }}
                >
                  <IconButton color="primary" onClick={handleSubmit}>
                    <CheckCircleIcon />
                  </IconButton>
                  <IconButton color="error" onClick={handleDiscard}>
                    <CancelIcon />
                  </IconButton>
                </Box>
                <Typography sx={{ ml: "6px" }}>Server Properties</Typography>
              </Box>
            </GoodPaper>
            <Grid
              container
              xs={12}
              spacing={0}
              sx={{ height: "70vh", overflowY: "auto" }}
            >
              {Object.entries(serverProperties)
                .filter(([key]) => {
                  //console.log("KEY: " + key)
                  return serverPropertyFields[key]["accessible"];
                })
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(([key, value], index) => {
                  // Add index parameter
                  const currentKey = key;
                  const isOddRow = index % 2 === 0;
                  return (
                    <>
                      <Grid
                        key={key}
                        item
                        xs={6}
                        sx={{
                          backgroundColor: isOddRow
                            ? "background.elevation1"
                            : "background.elevation2",
                          borderBottomStyle: "dotted",
                          borderWidth: "1px",
                          borderColor: "#f5f5f5",
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                            }}
                            color="#808080"
                            paddingTop="4px"
                            paddingLeft="4px"
                          >
                            {key}
                          </Typography>
                          <Tooltip
                            title={`${serverPropertyFields[key].description}`}
                          >
                            <IconButton
                              disableRipple
                              sx={{ marginLeft: "4px" }}
                            >
                              <HelpIcon
                                fontSize="small"
                                sx={{
                                  color: "#808080",
                                  position: "absolute",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          backgroundColor: isOddRow
                            ? "background.elevation1"
                            : "background.elevation2",
                        }}
                      >
                        {serverPropertyFields[key].type ==
                          ServerPropertyType.Boolean && (
                          <Select
                            fullWidth
                            variant="standard"
                            defaultValue={value.toString()}
                            value={value.toString()}
                            onChange={(event) => {
                              handlePropertyChange(key, event.target.value);
                            }}
                          >
                            <MenuItem value={true as any}>true</MenuItem>
                            <MenuItem value={false as any}>false</MenuItem>
                          </Select>
                        )}
                        {serverPropertyFields[key].type ==
                          ServerPropertyType.Integer && (
                          <GoodNumberTextField
                            setError={setError}
                            defaultValue={value}
                            currentKey={currentKey}
                            placeholder={serverPropertyFields[key].placeholder}
                            regex={serverPropertyFields[key].regex}
                            handlePropertyChange={handlePropertyChange}
                          />
                        )}
                        {serverPropertyFields[key].type ==
                          ServerPropertyType.String && (
                          <TextField
                            onChange={(event) => {
                              handlePropertyChange(key, event.target.value);
                            }}
                            fullWidth
                            defaultValue={value.toString()}
                            variant="standard"
                          />
                        )}
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </GoodPaper>
        </Grid>
      </Grid>
      <DiscardPopup
        open={unsavedChangesPopupOpen}
        setOpen={setUnsavedChangesPopupOpen}
        discardFunction={handleClose}
        unsavedChanges={changes}
      />
      <ErrorPopup
        open={errorPopupOpen}
        setOpen={setErrorPopupOpen}
        fieldErrors={fieldErrors}
      />
    </Box>
  );
};

export default ServerProperties;
