import { Button, Typography } from "@mui/material";
import { FC, Fragment } from "react";
import {
  statusColours,
  statusIcons,
  statusText,
} from "../../../GlobalConstants";
import { useJemListener } from "../../../utils/JemStore";

interface StartButtonProps {
  isLoadingStatus: boolean;
  handleClick: () => void;
}

const StartButton: FC<StartButtonProps> = ({
  isLoadingStatus,
  handleClick,
}): JSX.Element => {
  const statusJem = useJemListener<string>("status");
  const status = statusJem.getValue()
  return (
    <Fragment>
      {isLoadingStatus ? (
        <Button
          sx={{ borderRadius: 0 }}
          style={{ backgroundColor: statusColours["unknown"] }}
          variant="contained"
          fullWidth
          startIcon={statusIcons["unknown"]}
          disabled={true}
        >
          <Typography>{"LOADING"}</Typography>
        </Button>
      ) : (
        <Button
          sx={{ borderRadius: 0 }}
          style={{ backgroundColor: statusColours[status] }}
          variant="contained"
          fullWidth
          startIcon={statusIcons[status]}
          disabled={!(status === "online" || status === "offline")}
          onClick={handleClick}
        >
          <Typography>{statusText[status]}</Typography>
        </Button>
      )}
    </Fragment>
  );
};

export default StartButton;
