import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import MainArticle from "../components/homepage/MainArticle";

import { Box, Paper } from "@mui/material";

import NavBar from "../components/NavBar";

import NewCarousel from "../components/homepage/NewCarousel";
import Features from "../components/homepage/Features";
import Locations from "../components/homepage/Locations";
import FAQS from "../components/homepage/FAQS";
import Footer from "../components/Footer";
import AdventureBanner from "../components/AdventureBanner";
import ConstructionWarning from "../components/ConstructionWarming";
import LatestBlogs from "../components/homepage/LatestBlogs";

function MainPage() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("uid", "==", user?.uid ?? "guest")
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };
  useEffect(() => {
    if (loading) return;
    //if (!user) return navigate("/login");
    fetchUserName();
  }, [user, loading]);

  return (
    <Box>
      <NavBar />
      <ConstructionWarning />
      <header>
        <MainArticle />
      </header>
      <body>
        <Features />
        <NewCarousel />
        <LatestBlogs />
        <Locations />
        <FAQS />
        <AdventureBanner />
      </body>
      <footer>
        <Footer />
      </footer>
    </Box>
  );
}

export default MainPage;
