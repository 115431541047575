import {
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import { updateJem, useJemListener } from "../../../../utils/JemStore";
import HelpIcon from "@mui/icons-material/Help";

const GotoCommandLineFirst = () => {
  const isBlockedJem = useJemListener<boolean>("qb-is-blocked");

  return (
    <Box
      sx={{
        backgroundColor: "background.elevation2",
        paddingX: 1,
        flex: 1,
        marginBottom: "8px",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isBlockedJem.getValue()}
            onChange={(event) => {
              updateJem(isBlockedJem, event.target.checked);
            }}
          />
        }
        label="Go to command line first"
      />
      <Tooltip
        sx={{ float: "right" }}
        title="Tick me if you want to put the desired commands into the command line,
    and not straight into the console, you can use (playerN) where N is a number to have it replaced with any players you have ticked in future
    note: this means you can only pick
    one command!"
      >
        <IconButton>
          <HelpIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default GotoCommandLineFirst;
