import {
  Box,
  Button,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import GoodPaper from "../../GoodPaper";
import buttonTextMap from "./buttonTextMap";
import { getUsernameUUID } from "../../../firebase";

interface Props {
  name: string;
  data: { uuid: string; name: string }[];
  onAdd: (add: {
    uuid: string;
    name: string;
    level: number;
    bypassesPlayerLimit: boolean;
  }) => void;
  onRemove: (name: string) => void;
}

const OpsListTable = ({ name, data, onAdd, onRemove }: Props): JSX.Element => {
  const [shown, setShown] = useState<boolean>(true);
  const [value, setValue] = useState<string>("");
  const [localData, setLocalData] = useState<any[]>(data);

  useEffect(() => {
    setLocalData(data); // double rerender tho
  }, [data]);

  const isError = localData.map((d) => d.name).includes(value);

  const revertOnError = (revertName: string) => {
    setLocalData((prevArray) => prevArray.filter((d) => d.name !== revertName));
    // TODO: ADD SNACK HERE PLEASE
  };
  const handleKeyDown = (e: React.KeyboardEvent, value: string) => {
    if (e.key === "Enter") {
      handleAdd(value);
      setValue("");
    }
  };
  const handleAdd = (newEntry: string) => {
    if (isError || newEntry.trim() === "") {
      return;
    }
    // Update UI (will revert on failure)
    setLocalData((prevArray) => [
      ...prevArray,
      { uuid: "", name: newEntry, level: 4, bypassesPlayerLimit: false },
    ]);

    // Add to the actual server's whitelist
    getUsernameUUID(newEntry)
      .then((uuidResponse) => {
        if (uuidResponse != null) {
          const opsListData = {
            uuid: uuidResponse,
            name: newEntry,
            level: 4,
            bypassesPlayerLimit: false,
          };
          onAdd(opsListData);
        } else {
          // Revert the addition to the UI
          revertOnError(newEntry);
        }
      })
      .catch(() => {
        // Revert the addition to the UI
        revertOnError(newEntry);
      });
  };

  const handleRemove = (value: string) => {
    setLocalData((prevArray) =>
      prevArray.filter((item) => item.name !== value)
    );
    onRemove(value);
  };

  interface RowProps {
    text: string;
  }

  const Row: React.FC<RowProps> = ({ text }): JSX.Element => {
    return (
      <Box
        key={text}
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography display="inline" key={text} sx={{ ml: "2px" }}>
          {text}
        </Typography>
        <IconButton
          onClick={() => handleRemove(text)}
          sx={{ display: { xs: "inline-flex", lg: "none" } }}
        >
          <RemoveCircleOutlineIcon fontSize="small" color="error" />
        </IconButton>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleRemove(text)}
          sx={{
            display: { xs: "none", lg: "inline-flex" },
            mr: { xs: "none", lg: "4px" },
            my: { xs: "none", lg: "4px" },
          }}
        >
          {buttonTextMap(name)}
        </Button>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.elevation1",
      }}
    >
      <GoodPaper
        elevation={3}
        styles={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Typography sx={{ ml: "2px" }}>{name}</Typography>
        <IconButton
          onClick={() => {
            setShown(!shown);
          }}
        >
          <ExpandMoreIcon
            style={{
              transform: shown ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </IconButton>
      </GoodPaper>
      <Collapse in={shown}>
        <Box
          sx={{
            borderStyle: "solid",
            borderWidth: "1px",
            borderTop: "0px",
          }}
        >
          {localData.map((d, index) => {
            const name = d?.name ?? d;
            return index % 2 === 0 ? (
              <Row text={name} />
            ) : (
              <Box key={name} sx={{ backgroundColor: "background.elevation2" }}>
                <Row text={name} />
              </Box>
            );
          })}
          <Box
            display="flex"
            sx={{
              backgroundColor:
                localData.length % 2 === 0
                  ? "inherit"
                  : "background.elevation2",
            }}
          >
            <TextField
              variant="outlined"
              error={isError}
              helperText={isError ? "Name already in list!" : null}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              placeholder="Enter Name"
              size="small"
              fullWidth
              sx={{ display: "inline", width: "100%" }}
              onKeyDown={(e) => {
                handleKeyDown(e, value);
              }}
            />
            <IconButton
              onClick={() => {
                handleAdd(value);
                setValue("");
              }}
              color="primary"
              size="small"
              sx={{ width: "40px" }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default OpsListTable;
