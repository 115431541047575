import { Box } from "@mui/material";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import BlogGenerator from "./BlogGenerator";
import { Blog, ElementObject } from "../../utils/UploadBlog";
import { useState, useEffect } from "react";
import { fetchBlogs } from "../../firebase";

const BlogPage = () => {
  const { link } = useParams();

  console.log(link);

  const [blogs, setBlogs] = useState<Blog[]>([] as Blog[]);

  useEffect(() => {
    const GetBlogs = async () => {
      const fetchedBlogs = await fetchBlogs();
      console.log("BLOGS: ", fetchedBlogs); // Log the fetched data
      setBlogs(fetchedBlogs);
    };
    GetBlogs();
  }, []);

  const GetThisBlogContent = (): ElementObject[] => {
    let raw = [] as ElementObject[];

    for (let index = 0; index < blogs.length; index++) {
      const blog = blogs[index];
      if (blog.link == link) {
        raw = JSON.parse(blog.raw);
        console.log("match");
      }
    }

    return raw;
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <NavBar />
      <BlogGenerator content={GetThisBlogContent()} />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default BlogPage;
