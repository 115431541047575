import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  ListItemIcon,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const HeroFeaturesList = () => {
  const features = [
    "Java and Bedrock Edition Servers",
    "Responsive chat & ticket support",
    "1 click Mod & Mod Pack installation",
    "1 click Plugins and Datapacks",
    "Unlimited player slots",
    "Quick and easy world transfer",
    "Full online file access",
    "3-Day Money-Back Guarantee",
    "Full customisable console",
    "24/7 Servers with 99.9% Uptime",
    "Get started in under 5 minutes",
    "Safeguared worlds with backups",
  ];

  return (
    <div>
      <Grid container spacing={2} width="100%" pl="16px">
        {features.map((feature, index) => (
          <Grid item xs={6} sm={6} md={6} lg={4} key={index}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={feature} />
              </ListItem>
            </List>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HeroFeaturesList;
