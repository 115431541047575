import { Box, Container, Stack } from "@mui/material";
import { FC, useState } from "react";
import ProfileInfo from "./ProfileInfo";
import Menu from "./Menu";
import Payments from "./Payments";
import AccountTabContainer from "./AccountTabContainer";
import Tickets from "./Tickets";
import ManageMyServers from "./ManageMyServers";

const AccountDetailsContainer: FC = () => {
  const [display, setDisplay] = useState<string>("account details");

  const displays: { [tab: string]: JSX.Element } = {
    "account details": <ProfileInfo />,
    payments: <Payments />,
    "support tickets": <Tickets />,
    "manage my servers": <ManageMyServers />,
  };

  return (
    <Container sx={{ mt: "32px", mb: "32px" }}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing="16px">
        <Box>
          <Menu changeDisplay={setDisplay} currentTab={display} />
        </Box>
        <Box flex={1}>
          {" "}
          <AccountTabContainer>{displays[display]}</AccountTabContainer>
        </Box>
      </Stack>
    </Container>
  );
};

export default AccountDetailsContainer;
