import Cancel from "@mui/icons-material/Cancel";
import defaultBackupImage from "../../../assets/defaultMinecraft.webp";
import { Box, ButtonBase, IconButton, Paper } from "@mui/material";
import { useState } from "react";
import { updateJem, useJem, useJemListener } from "../../../utils/JemStore";
import { usePopupContext } from "./PopupContextProvider";

interface BackupTileProps {
  backupName: string;
  handleDelete: (backupName: string) => void;
  restoreSpecificBackup: (backupName: string) => void;
}

const BackupTile: React.FC<BackupTileProps> = ({
  backupName,
  handleDelete,
  restoreSpecificBackup,
}) => {
  const [hovered, setHovered] = useState(false);
  const statusJem = useJemListener<string>("status");
  const popupJem = useJem<boolean>("world-popup");

  const popupData = usePopupContext();

  function cancelButton() {
    popupData.current = {
      title: "delete a backup",
      description: `Are you sure you want to delete this backup: ${backupName} `,
      func: () => handleDelete(backupName),
      buttonText: "delete",
      buttonColour: "error",
    };
  }

  function mainButton() {
    popupData.current = {
      title: "restore backup",
      description: `Are you sure you would like to restore this back up NEWLINE${backupName}`,
      func: () => restoreSpecificBackup(backupName),
      buttonText: "restart and restore",
      buttonColour: "primary",
    };
  }

  return (
    <Paper
      style={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        height: "100%",
      }}
    >
      <ButtonBase
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
        onMouseEnter={() => {
          setHovered(true);
          mainButton();
        }}
        onMouseLeave={() => setHovered(false)}
        onClick={() => updateJem(popupJem, true)}
      >
        <IconButton
          color="error"
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            display: hovered ? "inline-flex" : "none",
          }}
          onMouseEnter={() => cancelButton()}
          onMouseLeave={() => mainButton()}
        >
          <Cancel />
        </IconButton>
        <Box
          flex={1}
          width="100%"
          sx={{
            backgroundImage: `url(${defaultBackupImage})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        ></Box>

        <Box display={{ xs: "none", md: "block" }} width="100%">
          <p
            style={{
              fontSize: "1rem",
              marginTop: "2px",
              marginBottom: "2px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {backupName}
          </p>
        </Box>
        <Box display={{ xs: "block", md: "none" }} width="100%">
          <p
            style={{
              fontSize: "1rem",
              marginTop: "2px",
              marginBottom: "2px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {backupName.substring(0, 5) + "..."}
          </p>
        </Box>
      </ButtonBase>
    </Paper>
  );
};

export default BackupTile;
