import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import OverWorld from "./OverWorld";

interface WorldCreatorProps {
  handleBack: () => void;
}

const WorldCreator: FC<WorldCreatorProps> = ({ handleBack }) => {
  // Define constants for initial field values
  const initialDimension = "Over World";
  const initialSeed = "";

  // Initialize state with the constants
  const [dimension, setDimension] = useState(initialDimension);
  const [seed, setSeed] = useState(initialSeed);
  const [generateFeatures, setGenerateFeatures] = useState(false);
  const [spawnBonusChest, setSpawnBonusChest] = useState(false);

  // Handle form submission
  const handleGenerate = () => {
    // You can access the field values like dimension and seed here
    // Do something with the form data
  };

  return (
    <Box minHeight="100%" display="flex">
      <Box display="flex" mr="16px">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "8px",
            paddingX: "16px",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" flexDirection="column">
            <Button
              onClick={handleBack}
              variant="contained"
              sx={{ mb: "8px", backgroundColor: "grey" }}
            >
              BACK
            </Button>
            <FormControl sx={{ mb: "8px" }}>
              <FormLabel>Dimension</FormLabel>
              <Select
                value={dimension}
                onChange={(e) => setDimension(e.target.value as string)}
              >
                <MenuItem value="Over World">Over World</MenuItem>
                <MenuItem value="Nether">Nether</MenuItem>
                <MenuItem value="End">The End</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mb: "8px" }}>
              <FormLabel>Seed</FormLabel>
              <TextField
                value={seed}
                onChange={(e) => setSeed(e.target.value)}
              />
            </FormControl>
            <FormControlLabel
              sx={{ mb: "8px" }}
              control={
                <Checkbox
                  checked={generateFeatures}
                  onChange={(e) => setGenerateFeatures(e.target.checked)}
                />
              }
              label="Generate Features"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={spawnBonusChest}
                  onChange={(e) => setSpawnBonusChest(e.target.checked)}
                />
              }
              label="Spawn bonus chest"
            />
          </Box>
          <Button
            onClick={handleGenerate}
            variant="contained"
            sx={{ justifySelf: "flex-end", mb: "8px" }}
          >
            <Typography color="white">Generate</Typography>
          </Button>
        </Paper>
      </Box>
      <Box flex={1} display="flex">
        <Paper sx={{ flex: 1, padding: "8px" }}>
          <OverWorld />
        </Paper>
      </Box>
    </Box>
  );
};

export default WorldCreator;
