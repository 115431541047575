import { Box, Container, Divider, Paper, Typography } from "@mui/material";

const FAQS = () => {
  const faqs: { title: string; description: string }[] = [
    {
      title: "Why Should I choose OP Servers?",
      description:
        "We've been where you are, setting up our own servers to play with friends and struggling with other server hosting providers. We know what we're doing and are dedicated to making the best experience possible",
    },
    {
      title: "What is Minecraft server hosting?",
      description:
        "Minecraft is a multiplayer game, and having several people on the same world requires a Minecraft server. Setting up a Minecraft server on your own computer can be complex, involving constant uptime, port forwarding, and security risks. With us, you get hassle-free Minecraft server hosting. Our user-friendly platform allows you to effortlessly manage your server, from version updates to mod installations. Enjoy your customized Minecraft experience without technical headaches.",
    },
    {
      title: "What versions and modpacks do you support?",
      description:
        "We support all versions from 1.0 onwards (please contact us if you would like an older server or any other experience you can't find through our site). We support Forge, Fabric, NeoForge, Quilt and Rift mod loaders with the ability to one click install mods and modpacks for each of these. Can't find a mod, modpack or plugin you want? No worries, simply upload them through our file manager.",
    },
    {
      title: "How can I set up a Minecraft Server with you?",
      description:
        "Setting up a Minecraft server with us is easy and takes less than 5 minutes. Simply select the plan you want with the version you want and let the fun begin.",
    },
    {
      title: "Can I switch between different  versions or modpacks?",
      description:
        "Absolutely, to change the version you want simply navigate to the server manager and choose the version you want. To change mod pack go to the mods tab on the control panel, search for the mod pack you want to play and click insall. ",
    },
    {
      title: "How much does it cost?",
      description:
        "Our prices are extremely competitive and varies based on the plan you choose. You are free to upgrade the amount of RAM you want at any time ",
    },
    {
      title: "What Kind of Hardware do you use?",
      description:
        "Our servers are equipped with powerful CPUs, the amount of RAM you want, and lightning-fast SSD storage to deliver the best performance for your Minecraft world. ",
    },
    {
      title: "Do you offer automatic backups?",
      description:
        "Yes, we offer full automatic backups which save not just your world but also your settings, aswell as access to your own manual backups.",
    },
    {
      title: "Can I upgrade or downgrade my hosting plan?",
      description:
        "Yes, you can upgrade or downgrade your hosting plan at any time to accommodate your changing needs. We offer flexibility to ensure your server suits your requirements.",
    },
    {
      title: "What security measures do you have in place?",
      description:
        "Your diamonds are safe with us. All of our server locations boast impressive security from 24/7 security to biometric entry on site.",
    },
    {
      title: "What payment methods do you accept?",
      description: "We accept credit and debit card and cryptocurrency",
    },
    {
      title: "Can I transfer an existing world to your hosting service?",
      description:
        "Absolutely, simply nagivate to the Worlds tab on the control panel and upload it there",
    },
  ];
  return (
    <Box paddingY="40px">
      <Typography variant="h4" textAlign="center" mb="20px">
        FREQUENTLY ASKED QUESTIONS
      </Typography>
      <Container maxWidth="lg" disableGutters>
        <Box display={{ xs: "none", sm: "flex" }}>
          <Box
            flex={1}
            sx={{
              mr: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {faqs.map((faq, index) => {
              return (
                index % 2 !== 0 && (
                  <Paper
                    sx={{
                      backgroundColor: "background.elevation2",
                      padding: "8px",
                      mb: "16px",
                    }}
                    key={index}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {faq.title}
                    </Typography>
                    <Divider />
                    <Typography mt="8px">{faq.description}</Typography>
                  </Paper>
                )
              );
            })}
          </Box>
          <Box
            flex={1}
            sx={{
              ml: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {faqs.map((faq, index) => {
              return (
                index % 2 == 0 && (
                  <Paper
                    sx={{
                      backgroundColor: "background.elevation2",
                      padding: "8px",
                      mb: "16px",
                    }}
                    key={index}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {faq.title}
                    </Typography>
                    <Divider />
                    <Typography mt="8px">{faq.description}</Typography>
                  </Paper>
                )
              );
            })}
          </Box>
        </Box>
        <Box>
          {faqs.map((faq, index) => (
            <Paper
              sx={{
                backgroundColor: "background.elevation2",
                padding: "8px",
                width: "100%",
                display: { xs: "flex", sm: "none" },
                mb: "16px",
                flexDirection: "column",
              }}
              key={index}
            >
              <Typography variant="h6" fontWeight="bold">
                {faq.title}
              </Typography>
              <Divider />
              <Typography mt="8px">{faq.description}</Typography>
            </Paper>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default FAQS;
