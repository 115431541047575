import { Box } from "@mui/material";
import BlogGenerator from "../components/blog/BlogGenerator";
import { GetDevBlog } from "../utils/UploadBlog";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const BlogPreview = () => {
  const forceReRender = useState(false)[1];
  const navigate = useNavigate();

  useEffect(() => {
    const handleUnSubscribe = () => {
      unsubscribe();
    };

    // Set up the listener when the component mounts
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (!authUser || "YZkcQpyfI2eKwqqb2M8Yb5CJuYk1" !== authUser.uid) {
        // User is not the owner of this server

        navigate("/");
      } else {
        forceReRender((p) => !p);
      }
    });

    // Clean up the listener when the component unmounts
    return () => handleUnSubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((auth.currentUser?.uid ?? "") != "YZkcQpyfI2eKwqqb2M8Yb5CJuYk1") {
    return <Box></Box>;
  }

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <NavBar />
      <BlogGenerator content={GetDevBlog()} />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default BlogPreview;
