import { Cancel } from "@mui/icons-material";
import {
  Dialog,
  Paper,
  Stack,
  Typography,
  IconButton,
  Button,
  Box,
  TextField,
} from "@mui/material";
import { FunctionComponent, useEffect, useRef, useState } from "react";

interface ManageMyServersPopupProps {
  open: boolean;
  closeFunction: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

const ManageMyServersPopup: FunctionComponent<ManageMyServersPopupProps> = ({
  open,
  closeFunction,
  name,
}) => {
  const [showVerifyDelete, setShowVerfiyDelete] = useState<boolean>(false);
  const [confirmationString, setConfirmationString] = useState<string>("");

  const randomString = useState<string>(generateRandomString(6));

  function generateRandomString(length: number): string {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }

  return (
    <Dialog open={open}>
      <Paper sx={{ p: "8px", width: "320px" }}>
        <Stack spacing="16px">
          <Paper
            sx={{
              p: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "background.elevation4",
            }}
          >
            <Typography fontWeight="bold">
              {name.toLocaleUpperCase()}
            </Typography>
            <IconButton color="error" onClick={() => closeFunction(false)}>
              <Cancel />
            </IconButton>
          </Paper>
          {!showVerifyDelete ? (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setShowVerfiyDelete(true);
              }}
            >
              Delete this server
            </Button>
          ) : (
            <Stack spacing="8px">
              <Typography>
                Please enter the following string to confirm deletion:{" "}
                {randomString[0]}
              </Typography>
              <TextField
                onChange={(e) => setConfirmationString(e.target.value)}
              />
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  confirmationString === randomString[0]
                    ? closeFunction(false)
                    : alert("Code did not match");
                }}
              >
                Confirm Deletion
              </Button>
            </Stack>
          )}
        </Stack>
      </Paper>
    </Dialog>
  );
};

export default ManageMyServersPopup;
