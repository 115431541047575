import { Box, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import clockFace from "../../../assets/clock.png";
import { TileColours } from "./Styles";
import { useJemListener } from "../../../utils/JemStore";

const TimeOnline = () => {
  const statusJem = useJemListener<string>("status");
  const status = statusJem.getValue()
  const timeOnlineJem = useJemListener<string>("time-online");
  const timeOnlineText = (status !== "online" ? status.toUpperCase() : timeOnlineJem.getValue());

  return (
    <Box display="flex" flex={1}>
      <Paper
        sx={{
          flex: 1,
          display: "flex",
          backgroundColor: TileColours["Paper.Main"],
        }}
      >
        <Box
          flex={1}
          minHeight="120px"
          position="relative"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundImage: `url(${clockFace})`, // Set the background image
            backgroundSize: "cover", // Cover the entire box with the image
            backgroundPosition: "center",
          }}
        >
          <Brightness5Icon
            fontSize="small"
            style={{
              top: 10,
              left: 10,

              position: "absolute",
              animation:
                status !== "offline"
                  ? "moveDiv 4s linear infinite"
                  : "none",
            }}
          />
          <Typography>TIME ONLINE</Typography>
          <Typography variant="h6">{timeOnlineText}</Typography>
          <Brightness3Icon
            fontSize="small"
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              animation:
                status !== "offline"
                  ? "moveDiv2 4s linear infinite "
                  : "none",
            }}
          />
        </Box>
      </Paper>

      <style>
        {`
          @keyframes moveDiv {
            0% {
              top: 10px;
              left: 10px;
            }
            25% {
              top: 10px;
              left: calc(100% - 30px);
            }
            50% {
              top: calc(100% - 30px);
              left: calc(100% - 30px);
            }
            75% {
              top: calc(100% - 30px);
              left: 10px;
            }
            100% {
              top: 10px;
              left: 10px;
            }
          }
      `}
        {`
          @keyframes moveDiv2 {
            0% {
              top: calc(100% - 30px);
              left: calc(100% - 30px);
            }
            25% {
              top: calc(100% - 30px);
              left: 10px;
            }
            50% {
              top: 10px;
              left: 10px;
            }

            75% {
              top: 10px;
              left: calc(100% - 30px);
            }
            100% {
              top: calc(100% - 30px);
              left: calc(100% - 30px);
            }
          }
      `}
      </style>
    </Box>
  );
};

export default TimeOnline;
