import { Box, Container, IconButton, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { FaTiktok } from "react-icons/fa";

const Footer = () => {
  console.log("rendered");
  return (
    <Box
      sx={{
        backgroundColor: "background.elevation4",

        paddingY: "10px",
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="grid"
          flex={1}
          sx={{ gridTemplateColumns: "3fr 2fr 1fr " }}
          gridTemplateRows={{ md: "1fr", xs: "2fr 1fr" }}
        >
          <Box display="flex" flexDirection="column" gridColumn="1">
            <Typography fontWeight="bold" variant="body2">
              OP SERVERS LTD
            </Typography>
            <Typography variant="body2">
              Company Reg no. 15035420 Registered in England and Wales
            </Typography>

            <Typography variant="body2">16 Sophia Gardens</Typography>
            <Typography variant="body2">JackHicks02@outlook.com</Typography>
            <Typography variant="body2">
              VAT NUMBER - WE ARE NOT CURRENTLY TRADING
            </Typography>
          </Box>
          <Box
            gridColumn={{ md: "2", xs: "1" }}
            gridRow={{ md: "1", xs: "2" }}
            mx="8px"
            display="flex"
            justifyContent="flex-start"
            alignItems="end"
          >
            <a
              href={
                "https://uk.linkedin.com/company/op-servers?trk=public_profile_experience-item_profile-section-card_subtitle-click"
              }
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <IconButton aria-label="LinkedIn">
                <LinkedInIcon fontSize="large" />
              </IconButton>
            </a>
            <a
              href={"https://www.facebook.com"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <IconButton aria-label="Facebook">
                <FacebookIcon fontSize="large" />
              </IconButton>
            </a>
            <a
              href={"https://www.instagram.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton aria-label="Instagram">
                <InstagramIcon fontSize="large" />
              </IconButton>
            </a>
            <a
              href={"https://www.twitter.com"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <IconButton aria-label="Twitter">
                <TwitterIcon fontSize="large" />
              </IconButton>
            </a>
            <a
              href={"https://www.tiktok.com"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Tiktok"
            >
              <IconButton aria-label="Tiktok">
                <FaTiktok fontSize="35px" />
              </IconButton>
            </a>
          </Box>
          <Box
            gridColumn="3"
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Grid container spacing={0} width="100%">
              <Grid xs={6}>
                <a href={"/pricing"} target="_blank" rel="noopener noreferrer">
                  <Typography>Pricing</Typography>
                </a>
                <a href={"/features"} target="_blank" rel="noopener noreferrer">
                  <Typography>Features</Typography>
                </a>
                <a href={"/support"} target="_blank" rel="noopener noreferrer">
                  <Typography>Support</Typography>
                </a>
              </Grid>
              <Grid xs={6}>
                <a href={"/contact"} target="_blank" rel="noopener noreferrer">
                  <Typography>Contact</Typography>
                </a>
                <a
                  href={"/terms-and-conditions"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>T&C</Typography>
                </a>
                <a
                  href={"/privacy-policy"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>Privacy</Typography>
                </a>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
