import {
  Box,
  ButtonBase,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { useRef, useState } from "react";
import { ServerDataStruct } from "../../backend/ServerData";
import { ContentCopy } from "@mui/icons-material";
import { updateJem, useJem } from "../../utils/JemStore";
import enterIcon from "../../assets/entericon.svg";
import forgeIcon from "../../assets/forge-white.svg";

interface TileInterface {
  server: ServerDataStruct;
  state: State;
}

export enum State {
  Day = "day",
  Night = "night",
  Offline = "offline",
}

const Tile: React.FC<TileInterface> = (props): JSX.Element => {
  const popupJem = useJem("popup");

  const server: ServerDataStruct = props.server;

  const [disableRipple, setDisableRipple] = useState(false);
  const [disableAddressRipple, setDisableAddressRipple] = useState(false);

  // let backgroundImage;

  // backgroundImage =
  //   props.state === State.Offline
  //     ? offlineImage
  //     : props.state === State.Day
  //     ? dayImage
  //     : nightImage;

  const defaultFunction = () => {
    return (window.location.href = "../dashboard/" + server.id.toString());
  };

  const functionRef = useRef<() => void>(() => {
    window.location.href = "../dashboard/" + server.id.toString();
  });

  const copyAddress = () => {
    const textToCopy = server.address;
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);

    updateJem(popupJem, true);
  };

  const nothingDoer = () => {};

  const updateFunction = (func: () => void) => {
    functionRef.current = func;
  };

  return (
    <ButtonBase
      sx={{
        flex: 1,
        display: "flex",
      }}
      onClick={() => {
        functionRef.current();
      }}
      disableRipple={disableRipple}
    >
      <Paper
        sx={{
          flex: 1,
          display: "flex",
          backgroundColor: "background.elevation4",
          p: "16px",
        }}
      >
        <Stack spacing="8px" flex={1}>
          <ButtonBase
            sx={{ cursor: "copy", flex: 1 }}
            style={{ display: "flex", alignItems: "flex-start" }}
            disableRipple={disableAddressRipple}
            onMouseEnter={() => {
              setDisableRipple(true);
              updateFunction(copyAddress);
            }}
            onMouseLeave={() => {
              setDisableRipple(false);
              updateFunction(defaultFunction);
            }}
          >
            <Paper
              sx={{
                backgroundColor: "background.elevation3",
                p: "8px",
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>{server.address}</Typography>
              <IconButton
                onMouseEnter={() => setDisableAddressRipple(true)}
                onMouseLeave={() => setDisableAddressRipple(false)}
              >
                <ContentCopy />
              </IconButton>
            </Paper>
          </ButtonBase>
          <Box display="flex" flex={1} flexWrap="wrap">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                mb="8px"
                width="100%"
              >
                <Typography textAlign="left" mr="4px">
                  Version:{" "}
                </Typography>
                <Typography> NO VERSION IN STRUCT?</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mb="8px"
                width="100%"
              >
                <Typography textAlign="left" mr="4px">
                  Created:{" "}
                </Typography>
                <Typography>{server.created.toString()}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography textAlign="left" mr="4px">
                  Uptime:{" "}
                </Typography>
                <Typography>{server.uptime_minutes.toString()}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <Box width="64px" height="64px">
                <img src={forgeIcon} width="100%" height="100%" />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              flex={1}
            >
              <Box width="64px" height="64px">
                <img src={enterIcon} width="100%" height="100%" />
              </Box>
            </Box>
          </Box>
        </Stack>
      </Paper>
    </ButtonBase>
  );
};

export default Tile;
