import { Box, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";

const GoodNumberTextField = (props: any) => {
  const defaultValue = props.defaultValue;
  const currentKey = props.currentKey;
  const regex = props.regex;

  const placeholder = props.placeholder;
  const setError = props.setError;

  const [value, setValue] = useState(defaultValue);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    if (inputValue === "" || RegExp(regex).test(inputValue)) {
      const handlePropertyChange = props.handlePropertyChange;
      handlePropertyChange(currentKey, inputValue);
    }
  };
  const isError: boolean = !RegExp(regex).test(value);

  setError(currentKey, isError);

  return (
    <Box
      sx={{
        borderBottomStyle: "solid",
        borderWidth: value !== "" && isError ? "1px" : "0px",
        borderColor: "white",
      }}
    >
      <TextField
        fullWidth
        defaultValue={defaultValue}
        variant="standard"
        placeholder={placeholder}
        onChange={handleInputChange}
        error={isError}
        helperText={value !== "" && isError ? placeholder : ""}
      />
    </Box>
  );
};

export default GoodNumberTextField;
