import HouseIcon from "@mui/icons-material/House";
import AccessTimeSharpIcon from "@mui/icons-material/AccessTimeSharp";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import CloudIcon from "@mui/icons-material/Cloud";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import CakeIcon from "@mui/icons-material/Cake";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import MessageIcon from "@mui/icons-material/Message";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import WorkIcon from "@mui/icons-material/Work";
import StoreIcon from "@mui/icons-material/Store";
import HotelIcon from "@mui/icons-material/Hotel";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import ExploreIcon from "@mui/icons-material/Explore";
import TollIcon from "@mui/icons-material/Toll";
import DiamondIcon from "@mui/icons-material/Diamond";
import LandscapeSharpIcon from "@mui/icons-material/LandscapeSharp";
import GamepadIcon from "@mui/icons-material/Gamepad";
import PetsSharpIcon from "@mui/icons-material/PetsSharp";
import SquareIcon from "@mui/icons-material/Square";
import CircleIcon from "@mui/icons-material/Circle";
import AnchorIcon from "@mui/icons-material/Anchor";
import RocketLaunchSharpIcon from "@mui/icons-material/RocketLaunchSharp";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export type IconDictionary = { [key: string]: React.ElementType };
export const iconDictionary: IconDictionary = {
  home: HouseIcon,
  op: ManageAccountsIcon,
  clock: AccessTimeSharpIcon,
  tp: TransferWithinAStationIcon,
  day: LightModeIcon,
  night: DarkModeIcon,
  sunny: WbSunnyIcon,
  storm: ThunderstormIcon,
  rain: CloudIcon,
  farm: AgricultureIcon,
  village: HolidayVillageIcon,
  cake: CakeIcon,
  health: HealthAndSafetyIcon,
  message: MessageIcon,
  run: DirectionsRunIcon,
  work: WorkIcon,
  shop: StoreIcon,
  bed: HotelIcon,
  flower: LocalFloristIcon,
  compass: ExploreIcon,
  currency: TollIcon,
  diamond: DiamondIcon,
  mountain: LandscapeSharpIcon,
  game: GamepadIcon,
  pet: PetsSharpIcon,
  square: SquareIcon,
  circle: CircleIcon,
  anchor: AnchorIcon,
  rocket: RocketLaunchSharpIcon,
};