import { Typography, Stack, Button, Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import backgroundImage from "../../assets/mainArticle.avif";
import HeroFeaturesList from "./HeroFeaturesList";

const MainArticle = () => {
  return (
    <Box
      style={{
        backgroundImage: `url(${backgroundImage})`,

        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          paddingY: "80px",
        }}
      >
        <Box
          sx={{
            boxShadow: "0 0 50px 52px rgba(0,0,0,.5);",
            backgroundColor: "rgba(0,0,0,.49)",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            fontWeight="bold"
            style={{ fontSize: "48px" }}
          >
            YOUR NEXT ADVENTURE STARTS HERE
          </Typography>

          <section>
            <Typography
              variant="h2"
              fontWeight="bold"
              style={{ fontSize: "16px", marginBottom: "32px" }}
            >
              We proudly offer low-cost fast and reliable servers. Be it Java or
              Bedrock, vanilla or modded - we have Minecraft covered.
            </Typography>
          </section>
          <HeroFeaturesList />
          <Button
            variant="contained"
            component={Link}
            to="/pricing"
            sx={{ marginTop: "16px" }}
          >
            <Typography color="white">SEE OUR PRICING PLANS</Typography>
          </Button>
        </Box>
      </Container>
    </Box>
    /*}
    <section className="section_one">
      <div className={"mainPage"}>
        <article className={"verticalAlign"}>
          <h1 style={{ fontSize: "32px" }}>YOUR NEXT ADVENTURE STARTS HERE</h1>
          <p>
            We proudly offer low-cost fast and reliable servers. Be it Java or
            Bedrock, vanilla or modded - we have you covered.
          </p>
        </article>
      </div>
    </section>
    */
  );
};

export default MainArticle;
