import { createContext, useContext, useEffect, useRef } from "react";

interface FileContextProps {
  children: React.ReactNode;
}

const FileContext = createContext<
  React.MutableRefObject<React.Dispatch<React.SetStateAction<boolean>>[]>
>(
  {} as React.MutableRefObject<React.Dispatch<React.SetStateAction<boolean>>[]>
);

export function useFileContext() {
  return useContext(FileContext);
}

export function useAddFileListener(
  store: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>>[]
  >,
  setter: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    store.current.push(setter);
  }, []);
}

export function updateFileState(
  value: boolean,
  listenerRef: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>>[]
  >
): void {
  listenerRef.current.forEach((setter) => setter(value));
}

export const FileContextProvider: React.FC<FileContextProps> = ({
  children,
}) => {
  const listenerRef = useRef<React.Dispatch<React.SetStateAction<boolean>>[]>(
    []
  );

  return (
    <FileContext.Provider value={listenerRef}>{children}</FileContext.Provider>
  );
};
