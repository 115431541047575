import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { RestartAlert } from "./WorldPopups";
import { updateJem, useJem, useJemListener } from "../../../utils/JemStore";
import { usePopupContext } from "./PopupContextProvider";
import { UploadPopup } from "../../Popup";

interface WorldPropertiesProps {
  worldProperties: { hasModdedDimensions: boolean };
  serverPropertiesData: React.MutableRefObject<{
    [property: string]: string;
  } | null>;
  modifyServerProperties: (
    propertiesToModify: { [property: string]: string },
    renameWorldFolderToo?: boolean
  ) => void;
  downloadWorldQuery: (worldName: string) => void;
}

const buttonStyle = {
  width: "100%",
  height: "100%",
};

const WorldProperties: FC<WorldPropertiesProps> = ({
  worldProperties,
  serverPropertiesData,
  modifyServerProperties,
  downloadWorldQuery,
}) => {
  const statusJem = useJemListener<string>("status");
  const popupJem = useJem<boolean>("world-popup");
  const popupData = usePopupContext();

  const serverPropertiesWorldName: string | null = serverPropertiesData.current
    ? serverPropertiesData.current["level-name"]
    : null;
  const [worldName, setWorldName] = useState<string | null>(
    serverPropertiesWorldName
  );
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);

  const [downloadCooldown, setDownloadCooldown] = useState(false);

  const handleDownloadWorld = () => {
    if (
      !serverPropertiesData.current ||
      serverPropertiesData.current["level-name"] == null
    ) {
      console.error(
        "Cannot download world if server.properties doesn't point to a world."
      );
      return;
    }

    if (downloadCooldown) {
      console.error("Download is on cooldown.");
      return;
    }

    setDownloadCooldown(true); // Start the cooldown

    downloadWorldQuery(serverPropertiesData.current["level-name"]);

    setTimeout(() => {
      setDownloadCooldown(false); // Re-enable the button after the cooldown
    }, 10000); // 10 seconds (10000 milliseconds)
  };

  if (worldName == null) {
    // TODO: Loading animation (Although if a connection has failed this may mean the VPS is down)
    return (
      <>
        <Typography sx={{ width: "100%", textAlign: "center", fontSize: 24 }}>
          {"OP Servers is currently undergoing maintenance."}
        </Typography>
        <Typography sx={{ width: "100%", textAlign: "center", fontSize: 24 }}>
          {"We appreciate your patience!"}
        </Typography>
        <div style={{ height: "20%" }}></div>
        <Typography sx={{ width: "100%", textAlign: "center", fontSize: 16 }}>
          {"If this is unexpected, reach out at: OPServerPals@op-servers.com"}
        </Typography>
      </>
    );
  }

  const handleResetDimension = (dimension: string) => {
    alert("Dem you need to make a real function for this, lot's of love, Jack");
  };

  const worldNameHasChanged: boolean = worldName !== serverPropertiesWorldName;

  const handleSaveWorldName = () => {
    // TODO: Jack - Need to show a different snackbar here if server is not offline:
    if (statusJem.getValue() !== "offline") {
      console.error(
        "Cannot change the world name unless the server is stopped!"
      );
      return;
    }
    if (!worldNameHasChanged) {
      console.error("How did you call this?");
      return;
    }
    if (worldName == null || worldName.length < 1 || worldName.length > 1000) {
      console.error("Invalid world name");
      return;
    }
    modifyServerProperties({ "level-name": worldName }, true);
    // If you remove this snackbar then a "dummy" use state must be set btw to FORCE a re-render!!
    setShowSnackbar(true);
  };

  const handleResetButtonClick = (dimensionName: string) => {
    popupData.current = {
      title: `reset ${dimensionName}`,
      description: `This will wipe  the ${dimensionName} allowing it to regenerate`,
      func: () => handleResetDimension("poo"),
      buttonText: "reset",
      buttonColour: "error",
    };

    updateJem(popupJem, true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <TextField
        sx={{ mb: "8px" }}
        fullWidth
        value={worldName}
        onChange={(e) => setWorldName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSaveWorldName();
          }
        }}
        InputProps={{
          endAdornment: !worldNameHasChanged ? (
            <InputAdornment position="end">
              <EditIcon />
            </InputAdornment>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveWorldName}
            >
              Save
            </Button>
          ),
        }}
      />
      <RestartAlert open={showSnackbar} onClose={handleCloseSnackbar} />
      <Box display="flex" flexDirection="column" flex={1}>
        <Box flex={1}>
          <Box mb="8px" height="50%" display="flex">
            <Button
              disabled={downloadCooldown}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: "100%", flex: 1, mr: "4px" }}
              startIcon={
                <DownloadIcon fontSize="large" sx={{ color: "white" }} />
              }
              onClick={handleDownloadWorld}
            >
              <Typography color="white">Download World</Typography>
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: "100%", flex: 1, ml: "4px" }}
              onClick={() => setShowUploadPopup(true)}
              startIcon={
                <UploadIcon fontSize="large" sx={{ color: "white" }} />
              }
            >
              <Typography color="white">Upload World</Typography>
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          justifyContent="flex-end"
        >
          <Box display="flex" flex={1}>
            <Box flex={1} mr="8px">
              <Button
                variant="contained"
                color="warning"
                sx={buttonStyle}
                onClick={() => handleResetButtonClick("Over World")}
              >
                Reset Over World
              </Button>
            </Box>
            <Box flex={1} mr="8px">
              <Button
                variant="contained"
                color="warning"
                sx={buttonStyle}
                onClick={() => handleResetButtonClick("Nether")}
              >
                Reset nether
              </Button>
            </Box>
            <Box
              flex={1}
              mr={worldProperties.hasModdedDimensions ? "8px" : "0px"}
            >
              <Button
                variant="contained"
                color="warning"
                sx={buttonStyle}
                onClick={() => handleResetButtonClick("End")}
              >
                Reset end
              </Button>
            </Box>
            <Box
              display={worldProperties.hasModdedDimensions ? "block" : "none"}
              flex={1}
            >
              <Button variant="contained" color="warning" sx={buttonStyle}>
                Reset Other
              </Button>
            </Box>
          </Box>
          <Box mt="8px" height="50%">
            <Button
              variant="contained"
              color="error"
              fullWidth
              sx={{ height: "100%" }}
            >
              Reset World
            </Button>
          </Box>
        </Box>
      </Box>
      <UploadPopup
        open={showUploadPopup}
        setOpen={setShowUploadPopup}
        handleUpload={() => alert("ADD THE UPLOAD FUNCTION")}
      />
    </Box>
  );
};

export default WorldProperties;
