import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

type DataInstance = {
  playerCount: number;
  time: number;
};
const PlayerGraph = () => {
  const [data, setData] = useState<DataInstance[]>([
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
    {
      playerCount: 70,

      time: 2210,
    },
  ]);

  const updateData = (playerCount: number) => {
    const time = 2210;

    setData((prevState) => {
      prevState.shift();
      return [...prevState, { playerCount: playerCount, time: time }];
    });

    // setData((prevState) => {
    //   prevState.shift();
    //   return [...prevState]; // Creating a new array to trigger a re-render
    // });
  };

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const tick = () => {
      updateData(Math.random() * 100);
      timer.current = setTimeout(tick, 1000);
    };

    // Start the timer when the component mounts
    timer.current = setTimeout(tick, 1000);

    // Cleanup the timer when the component unmounts
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <Box flex={1}>
      <ResponsiveContainer width="99%">
        <AreaChart // Use AreaChart instead of LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" /> {/* Update dataKey to "time" */}
          <YAxis />
          <Tooltip />
          <Legend />
          <Area // Use Area instead of Line
            isAnimationActive={false}
            type="monotone"
            dataKey="playerCount"
            stroke="#8884d8"
            fill="#8884d8" // Specify the area fill color
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PlayerGraph;
