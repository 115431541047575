import {
  Alert,
  Box,
  Button,
  Dialog,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UploadIcon from "@mui/icons-material/Upload";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC, useEffect, useRef, useState } from "react";
import GoodPaper from "./GoodPaper";
import { fallBackUUID, getUsernameUUID } from "../firebase";
import {
  useFileContext,
  updateFileState,
} from "./dashboard-detail/file-components/FileContext";
import { updateJem, useJem, useJemListener } from "../utils/JemStore";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UploadPopup = (props: any) => {
  const open = props.open;
  const setOpen = props.setOpen;

  const handleUpload = props.handleUpload; // Dear Dem: Add me to save

  const [files, setFiles] = useState<FileList | null>(null);
  const inputRef: any = useRef(null);

  const handleClose = () => {
    setFiles(null);
    setOpen(false);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const handleSendFiles = () => {
    const fileArray: File[] = Array.from(files ?? []);
    if (fileArray.length <= 0) {
      console.error("No files selected to upload!");
      return;
    }
    console.log(fileArray[0]);
    handleUpload(fileArray[0]);
    handleClose();
  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <GoodPaper elevation={3} styles={{ padding: "4px" }}>
        <GoodPaper
          elevation={4}
          styles={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography ml="4px">Upload File</Typography>
          <IconButton color="error" onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </GoodPaper>
        <Box
          sx={{
            backgroundColor: "background.elevation1",
            borderStyle: "dashed",
            borderRadius: "4px",
            borderWidth: "2px",
            width: "500px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {files ? (
            <div>
              {Array.from(files).map((file, idx) => (
                <Typography key={idx}>{file.name}</Typography>
              ))}
            </div>
          ) : null}
          {!files && (
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <input
                hidden
                type="file"
                onChange={(event) => setFiles(event.target.files)}
                ref={inputRef}
              />
              <Icon fontSize="large">
                <UploadIcon fontSize="large" />
              </Icon>
              <Box>
                <Typography display="inline" mr="6px">
                  Drag and drop to upload or
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => inputRef.current.click()}
                >
                  <Typography>Browse Files</Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        {files ? (
          <Box display="flex" justifyContent="center" mt="8px">
            <Button variant="contained" onClick={handleSendFiles}>
              <Typography color="white">Upload</Typography>
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setFiles(null)}
            >
              <Typography color="white">Remove</Typography>
            </Button>
          </Box>
        ) : null}
      </GoodPaper>
    </Dialog>
  );
};

export const DiscardPopup = (props: any) => {
  const discardFunction = props.discardFunction;
  const open = props.open;
  const setOpen = props.setOpen;
  const unsavedChanges: { [key: string]: { old: any; new: any } } =
    props.unsavedChanges;

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscard = () => {
    discardFunction();
    handleClose();
  };

  console.log(JSON.stringify(unsavedChanges));
  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <GoodPaper elevation={1} styles={{ padding: "4px" }}>
        <Box sx={{ marginBottom: "8px" }}>
          <GoodPaper elevation={4}>
            <Box display="flex" alignItems="center">
              <Typography sx={{ padding: "2px 2px 2px 4px" }}>
                Discard changes?
              </Typography>
              <Box sx={{ right: 0, position: "absolute" }}>
                <IconButton color="primary" onClick={handleClose}>
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
          </GoodPaper>
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: "4px",
              backgroundColor: "#3f3f3f",
            }}
          >
            {Object.entries(unsavedChanges).map(([k, v], index, array) => {
              const isOddRow = index % 2 === 1;
              const isLastRow = index === array.length - 1;
              const backgroundColor = isOddRow ? "#3f3f3f" : "#2f2f2f";
              return (
                <Grid container key={k} spacing={0}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      borderBottom: "solid",
                      borderWidth: isLastRow ? "0px" : "1px",
                      backgroundColor,
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ textAlign: "left", ml: "2px" }}>
                      {k}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      backgroundColor: backgroundColor,
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "solid",
                      justifyContent: "center",
                      borderWidth: isLastRow ? "0px" : "1px",
                    }}
                  >
                    <Typography sx={{ marginRight: "8px" }}>
                      {v.old.toString()}
                    </Typography>
                  </Grid>
                  <Grid
                    display="inline-block"
                    item
                    xs={3}
                    sx={{
                      backgroundColor: backgroundColor,
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "solid",
                      borderWidth: isLastRow ? "0px" : "1px",
                    }}
                  >
                    <ArrowForwardIosIcon
                      fontSize="small"
                      sx={{ transform: "translate(-8px, 0px)" }}
                    />
                    <Typography
                      sx={{
                        transform: "translate(-8px, 0px)",
                        textAlign: "center",
                        width: "100%",
                        justifySelf: "center",
                      }}
                    >
                      {v.new.toString()}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              display: "flex",
              marginBottom: "4px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{ width: "49.5%" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleClose}
            >
              <Typography color="white">Continue editting</Typography>
            </Button>
            <Button
              sx={{ width: "49.5%" }}
              variant="contained"
              color="error"
              size="small"
              onClick={handleDiscard}
            >
              <Typography color="white">Discard Changes</Typography>
            </Button>
          </Box>
        </Grid>
      </GoodPaper>
    </Dialog>
  );
};

export const ErrorPopup = (props: any) => {
  const open: boolean = props.open;
  const setOpen = props.setOpen;
  const fieldErrors: { [key: string]: boolean } = props.fieldErrors;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <GoodPaper elevation={3} styles={{ padding: "4px" }}>
        <Box>
          <GoodPaper elevation={1}>
            <Box display="flex" alignItems="center">
              <Typography sx={{ padding: "2px 2px 2px 4px", mr: "4px" }}>
                These properties have invalid inputs
              </Typography>
              <Box sx={{ right: 0 }}>
                <IconButton color="error" onClick={handleClose}>
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
            <Box>
              <List>
                {Object.entries(fieldErrors)
                  .filter(([key, value]) => value == true)
                  .map((k) => {
                    return (
                      <ListItem key={k[0]} sx={{ ml: "4px" }}>
                        <Typography>• {k}</Typography>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </GoodPaper>
        </Box>
      </GoodPaper>
    </Dialog>
  );
};

export const PlayerPopup = (props: any) => {
  //Standard Close and Open Props
  const open: boolean = props.open;
  const handleClose = props.handleClose;

  //User Specific Props

  const totalConnects = props.totalConnects;
  const status = props.status;
  const timeOnline = props.timeOnline;
  const lastQuit = props.lastQuit;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name);

  if (props.name != name) {
    setName(props.name);
  }

  const [uuid, setUuid] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      setUuid((await getUsernameUUID(name)) ?? fallBackUUID);
      setIsLoading(false);
    };

    if (name == "") {
      return;
    }
    setIsLoading(true);
    fetchData();
  }, [name]);

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <GoodPaper elevation={3} styles={{ padding: "4px" }}>
        <Box>
          <GoodPaper elevation={1}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              marginBottom="4px"
            >
              <Typography sx={{ padding: "2px 2px 2px 4px", mr: "4px" }}>
                {name}
              </Typography>
              <Box sx={{ position: "absolute", right: 0 }}>
                <IconButton color="error" onClick={handleClose}>
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
          </GoodPaper>
        </Box>
        <Box display="flex">
          {isLoading ? (
            <Skeleton variant="rectangular" width={120} height={270} />
          ) : (
            <img src={`https://crafatar.com/renders/body/${uuid}`}></img>
          )}

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{ pl: "8px", pr: "2px" }}
          >
            <Box>
              <Box>
                <Typography display="inline">Total Connects:ㅤ</Typography>
                <Typography display="inline" sx={{ float: "right" }}>
                  {totalConnects}
                </Typography>
              </Box>
              <Box>
                <Typography display="inline">Status:ㅤ</Typography>
                <Typography display="inline" sx={{ float: "right" }}>
                  {status}
                </Typography>
              </Box>
              <Box>
                <Typography display="inline">Time Online:ㅤ</Typography>
                <Typography display="inline" sx={{ float: "right" }}>
                  {timeOnline}
                </Typography>
              </Box>
              <Box>
                <Typography display="inline">Last Quit:ㅤ</Typography>
                <Typography display="inline" sx={{ float: "right" }}>
                  {lastQuit}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>Operator</Typography>
              <Switch />
            </Box>
            <Box>
              <Button
                color="warning"
                variant="contained"
                fullWidth
                sx={{ mb: "4px" }}
              >
                <Typography color="white">KICK</Typography>
              </Button>
              <Button color="error" variant="contained" fullWidth>
                <Typography>BAN</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </GoodPaper>
    </Dialog>
  );
};

export default PlayerPopup;

interface UnsavedChangesPopupProps {
  open: boolean;
  handleClose: () => void;
  defaultFunction: () => void;
}

export const UnsavedChangesPopup: FC<UnsavedChangesPopupProps> = ({
  open,
  handleClose,
  defaultFunction,
}) => {
  const fileRef = useFileContext();

  function handleDiscard() {
    updateFileState(false, fileRef);
    defaultFunction();
    handleClose();
  }

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <Paper
        sx={{
          padding: "8px",
        }}
      >
        <Stack spacing="8px">
          <Paper
            sx={{
              padding: "8px",
              backgroundColor: "background.elevation4",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>YOU HAVE UNSAVED CHANGES</Typography>
            <Box>
              <IconButton onClick={handleClose} color="warning">
                <CancelIcon />
              </IconButton>
            </Box>
          </Paper>
          <Button variant="contained" color="warning" onClick={handleClose}>
            STAY
          </Button>
          <Button variant="contained" color="error" onClick={handleDiscard}>
            DISCARD & LEAVE
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
};

interface FileDeletePopupProps {
  open: boolean;
  handleClose: () => void;
  removeFromFileSystem: (value: string) => void;
  actingFileFolderName: string;
  viewingDirectory: React.MutableRefObject<string>;
}

export const FileDeletePopup: FC<FileDeletePopupProps> = ({
  open,
  handleClose,
  removeFromFileSystem,
  actingFileFolderName,
  viewingDirectory,
}) => {
  const statusJem = useJem<string>("status");
  const dialogJem = useJem<boolean>("file-dialog");

  function handleDelete() {
    removeFromFileSystem(
      viewingDirectory.current.trim() === ""
        ? actingFileFolderName
        : viewingDirectory.current + actingFileFolderName
    );
    if (statusJem.getValue() == "online" || "starting") {
      updateJem(dialogJem, true);
    }
    handleClose();
  }

  const prohibitedFromDeletion: string[] = ["eula.txt"];

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      {!prohibitedFromDeletion.includes(
        viewingDirectory.current.trim() === ""
          ? actingFileFolderName
          : viewingDirectory.current + actingFileFolderName
      ) ? (
        <Paper
          sx={{
            padding: "8px",
          }}
        >
          <Stack spacing="8px">
            <Paper
              sx={{
                padding: "8px",
                backgroundColor: "background.elevation4",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>DELETE THIS FILE?</Typography>
              <Box>
                <IconButton onClick={handleClose} color="warning">
                  <CancelIcon />
                </IconButton>
              </Box>
            </Paper>
            <Typography>{actingFileFolderName}</Typography>
            <Button variant="contained" color="warning" onClick={handleClose}>
              NEVER MIND
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete}>
              DELETE
            </Button>
          </Stack>
        </Paper>
      ) : (
        <Paper
          sx={{
            padding: "8px",
          }}
        >
          <Paper
            sx={{
              padding: "8px",
              backgroundColor: "background.elevation4",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>YOU CANNOT DELETE THIS FILE</Typography>
            <Box>
              <IconButton onClick={handleClose} color="warning">
                <CancelIcon />
              </IconButton>
            </Box>
          </Paper>
        </Paper>
      )}
    </Dialog>
  );
};

export const ChangesReflectedSnackbar: FC = () => {
  const showDialogJem = useJemListener<boolean>("file-dialog");

  return (
    <Snackbar
      open={showDialogJem.getValue()}
      autoHideDuration={6000}
      onClose={() => updateJem(showDialogJem, false)}
    >
      <Alert
        onClose={() => updateJem(showDialogJem, false)}
        severity="warning"
        sx={{ width: "100%" }}
      >
        Your changes may not be reflected until the server restarts!
      </Alert>
    </Snackbar>
  );
};
