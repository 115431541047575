import React from "react";

import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LogIn from "./pages/LogIn";
import Register from "./pages/Register";
import Reset from "./pages/Reset";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Pricing from "./pages/Pricing";
import DashBoard from "./pages/Dashboard";
import DashboardDetail from "./pages/DashboardDetail";
import AccountDetails from "./pages/Account";
import { CssBaseline, PaletteMode } from "@mui/material";
import { grey } from "@mui/material/colors";
import Features from "./pages/Features";

import PlanWithDynamicRam from "./components/pricing/PlanWithDynamicRam";
import Support from "./pages/Support";
import Blog from "./pages/Blog";
import BlogPage from "./components/blog/BlogPage";
import { FileContextProvider } from "./components/dashboard-detail/file-components/FileContext";
import { JemContextProvider } from "./utils/JemStore";
import { PlayerTableRow } from "./components/dashboard-detail/players-components/PlayerTable";
import BlogPreview from "./pages/BlogPreview";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import BlogPreviewPreview from "./pages/BlogPreviewPreview";

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#4BB543", // Customize the primary color
          },
          background: {
            elevation4: "#fff",
            elevation3: "#fff",
            elevation2: "#fff",
            elevation1: "#fff",
            default: "#D3D3D3 ",
            paper: "#fff",
          },
          divider: "#000",
          text: {
            primary: "#000",
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#4BB543", // Customize the primary color
          },
          divider: "#fff",
          background: {
            elevation4: "#181919",
            elevation3: "#272727",
            elevation2: "#323232",
            elevation1: "#363636",
            default: "#272727",
            paper: "#272727",
          },
          text: {
            primary: "#fff",
            secondary: grey[500],
          },
        }),
  },
});

function App() {
  const [mode /*, setMode*/] = React.useState<PaletteMode>("dark");
  // const colorMode = React.useMemo(
  //   () => ({
  //     // The dark mode switch would invoke this method
  //     toggleColorMode: () => {
  //       setMode((prevMode: PaletteMode) =>
  //         prevMode === "light" ? "dark" : "light"
  //       );
  //     },
  //   }),
  //   []
  // );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/login" element={<LogIn />} />
        </Routes>
        <Routes>
          <Route path="/register" element={<Register />} />
        </Routes>
        <Routes>
          <Route path="/reset" element={<Reset />} />
        </Routes>
        <Routes>
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/plan/:ram" element={<PlanWithDynamicRam />} />
        </Routes>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <JemContextProvider contextKey="popup" defaultValue={false}>
                <DashBoard />
              </JemContextProvider>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/dashboard/:id"
            element={
              <JemContextProvider
                contextKey="time-online"
                defaultValue={"UNKNOWN"}
              >
                <JemContextProvider
                  contextKey="players-table"
                  defaultValue={[] as PlayerTableRow[]}
                >
                  <JemContextProvider
                    contextKey="status"
                    defaultValue={"unknown"}
                  >
                    <JemContextProvider
                      contextKey="cpu-percent"
                      defaultValue={0}
                    >
                      <JemContextProvider
                        contextKey="ram-percent"
                        defaultValue={0}
                      >
                        <JemContextProvider
                          contextKey="console"
                          defaultValue={"Welcome to the Server Console!"}
                        >
                          <JemContextProvider
                            contextKey="server-data"
                            defaultValue={null}
                          >
                            <JemContextProvider
                              contextKey="versions-data"
                              defaultValue={null}
                            >
                              <FileContextProvider>
                                <DashboardDetail />
                              </FileContextProvider>
                            </JemContextProvider>
                          </JemContextProvider>
                        </JemContextProvider>
                      </JemContextProvider>
                    </JemContextProvider>
                  </JemContextProvider>
                </JemContextProvider>
              </JemContextProvider>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/account-details"
            element={
              <JemContextProvider contextKey="account" defaultValue={0}>
                <AccountDetails />
              </JemContextProvider>
            }
          />
        </Routes>
        <Routes>
          <Route path="/features" element={<Features />} />
        </Routes>
        <Routes>
          <Route path="/support" element={<Support />}></Route>
        </Routes>
        <Routes>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog/:link" element={<BlogPage />}></Route>
        </Routes>
        <Routes>
          <Route path="/blog-preview" element={<BlogPreview />}></Route>
        </Routes>
        <Routes>
          <Route
            path="/blog-preview-preview"
            element={<BlogPreviewPreview />}
          ></Route>
        </Routes>
        <Routes>
          <Route
            path="/terms-and-conditions"
            element={<TermsOfService />}
          ></Route>
        </Routes>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
