import {
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

interface SubDomainProps {
  userNameListeners: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>[]
  >;
}

const SubDomain: FC<SubDomainProps> = ({ userNameListeners }) => {
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    userNameListeners.current.push(setUserName);
  }, []);

  return (
    <FormControl fullWidth>
      <FormLabel>Custom sub domain</FormLabel>
      <TextField
        disabled
        value={userName}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">.op-servers.com</InputAdornment>
          ),
        }}
      ></TextField>
    </FormControl>
  );
};

export default SubDomain;
