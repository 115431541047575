import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Paper } from "@mui/material";

import backgroundImage from "../assets/LoginBackground.png";
import GoogleIcon from "@mui/icons-material/Google";

function Login(): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/");
  }, [user, navigate]);

  const handleLogin = () => {
    logInWithEmailAndPassword(email, password);
  };

  if (loading) {
    return <Typography variant="h4">Loading</Typography>;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Grid container spacing={0} sx={{ justifyContent: "center" }}>
        <Grid item xs={11} sm={6} md={4} lg={3} xl={2}>
          <Paper elevation={0}>
            <Grid
              container
              spacing={0}
              sx={{
                paddingTop: "15px",
                paddingBottom: "15px",
                flex: 1,
                columnGap: 3,
                rowGap: 1,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid item xs={10}>
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="E-mail Address"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ width: "100%" }}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={10} sx={{ marginTop: "2px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                  sx={{ width: "100%" }}
                >
                  <Typography color="#fff">Login</Typography>
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Button
                  variant="contained"
                  color="info"
                  onClick={signInWithGoogle}
                  startIcon={<GoogleIcon sx={{ color: "white" }} />}
                  sx={{ width: "100%" }}
                >
                  <Typography color="#fff">Login with Google</Typography>
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography>
                  <Link to="/reset">
                    <Typography style={{ textDecoration: "underline" }}>
                      Forgot Password
                    </Typography>
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography>
                  Don't have an account?{" "}
                  <Link to="/register">
                    <Typography style={{ textDecoration: "underline" }}>
                      Register now.
                    </Typography>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
