export const TileColours: {[key:string]: string} = {
  "Paper.Green": "#7FD37A",
  "Icon.Green": "#96DF92",

  "Paper.Main": "background.elevation2"
}

export const megaGrid = {
  display: 'grid',
  gridTemplateColumns: `repeat(36, 1fr)`, // Create 36 columns
  gridTemplateRows: 'repeat(3, 1fr)',
  gap: '16px', // Adjust the gap as needed
};