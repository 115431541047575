import { Box, Switch, Typography } from "@mui/material";
import { FC, useState } from "react";

interface EnableWhiteProps {
  serverPropertiesData: React.MutableRefObject<{
    [property: string]: string;
  } | null>
  sendServerProperties: (properties: {
    [property: string]: string;
  }) => void,
}

const EnableWhitelist: FC<EnableWhiteProps> = ({ serverPropertiesData, sendServerProperties }) => {
  const isLoading: boolean = serverPropertiesData?.current == null;
  const enableWhitelist: boolean = !isLoading ? JSON.parse(serverPropertiesData.current!["white-list"].toLowerCase()) : false;
  const [forceRefresh, setForceRefresh] = useState<number>(0)

  const handleChangeWhiteList = (newValue: boolean) => {
    if (!serverPropertiesData.current) {
      console.error("Huge error, serverProperties data is null somehow")
      return
    }
    serverPropertiesData.current["white-list"] = newValue.toString()
    sendServerProperties(serverPropertiesData.current)
    setForceRefresh((forceRefresh + 1) % 2)
  }

  return (
    <Box display="flex" alignItems="center">
      <Switch checked={enableWhitelist} disabled={isLoading} onChange={(event) => { handleChangeWhiteList(event.target.checked) }} />
      <Typography ml="8px">Enable Whitelist</Typography>
    </Box>
  );
};

export default EnableWhitelist;
