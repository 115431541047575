import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import GoodPaper from "../../GoodPaper";
import DownloadIcon from "@mui/icons-material/Download";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface ModTileProps {
  name: string;
  description: string;
  imageUrl: string;
  clientSide: string;
  serverSide: string;
  author: string;
}

const ModTile: React.FC<ModTileProps> = ({
  name,
  description,
  imageUrl,
  author,
  clientSide,
  serverSide,
}): JSX.Element => {
  return (
    <Box key={name} display="flex">
      <GoodPaper
        styles={{
          width: "100%",
          display: "flex",
          padding: "5px",
          backgroundColor: "background.elevation1",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex">
          <img
            src={imageUrl}
            style={{
              width: "96px",
              height: "96px",
              backgroundColor: "#808080",
              borderRadius: "16px",
            }}
          ></img>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "4px",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box display="flex" alignItems="center">
                <a href={`https://modrinth.com/mod/${name.replace(/ /g, "-")}`}>
                  <Typography
                    variant="h6"
                    mr="4px"
                    fontWeight="bold"
                    sx={{
                      textDecoration: "underline",
                    }}
                  >
                    {name}
                  </Typography>
                </a>
                <Tooltip title={description}>
                  <HelpOutlineIcon />
                </Tooltip>
              </Box>
              <Typography>by {author}</Typography>
            </Box>
            <Box display="flex">
              {serverSide == "required" || serverSide == "optional" ? (
                <Box display="flex" alignItems="center">
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: "lightblue",
                      borderRadius: "4px",
                    }}
                  ></div>
                  <Typography ml="2px" mr="2px">
                    Server
                  </Typography>

                  {clientSide == "required" ? (
                    <Typography ml="2px" mr="4px">
                      and
                    </Typography>
                  ) : null}
                  {clientSide == "optional" ? (
                    <Typography ml="2px" mr="5px">
                      or
                    </Typography>
                  ) : null}
                </Box>
              ) : null}
              {clientSide == "required" || clientSide == "optional" ? (
                <Box display="flex" alignItems="center">
                  <Tooltip
                    title={
                      clientSide == "required"
                        ? "You must install this mod in your game too!"
                        : ""
                    }
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        backgroundColor: "orange",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </Tooltip>
                  <Typography ml="2px">Client</Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {serverSide ? (
            <Tooltip title="Download to Server">
              <IconButton>
                <DownloadIcon color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
      </GoodPaper>
    </Box>
  );
};

export default ModTile;
