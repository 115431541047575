import { Box, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";

import BackupTile from "./BackupTile";
import BuyBackupTile from "./BuyBackupTile";
import EmptyBackupTile from "./EmptyBackupTile";

interface BackUpsProps {
  setBackupListData: React.MutableRefObject<
    (data: { [backupName: string]: number }) => void
  >;
  queryBackupList: () => void;
  restoreSpecificBackup: (backupName: string) => void;
  deleteSpecificBackup: (backupName: string) => void;
}

const BackUps: FC<BackUpsProps> = ({
  setBackupListData,
  queryBackupList,
  restoreSpecificBackup,
  deleteSpecificBackup,
}) => {
  const backupSlots = [1, 2, 3, 4, 5, 6];

  const [backUpNamesAndFileSize, setBackupNamesAndFileSize] = useState<{
    [backupName: string]: number;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryBackupListLocal = () => {
    if (queryBackupList) {
      setIsLoading(true);
      queryBackupList();
    } else {
      console.error("No queryBackupList given, cannot make the call.");
      return;
    }
  };
  const setBackupListDataLocal = (data: { [backupName: string]: number }) => {
    if (setBackupListData) {
      setIsLoading(false);
      setBackupNamesAndFileSize(data);
    } else {
      console.error("No setBackupListData given, cannot make the call.");
      return;
    }
  };
  const deleteSpecificBackupLocal = (backupName: string) => {
    setBackupNamesAndFileSize((prevBackups) => {
      const updatedBackups = { ...prevBackups };
      delete updatedBackups[backupName];
      return updatedBackups;
    });
    deleteSpecificBackup(backupName);
  };
  useEffect(() => {
    setBackupListData.current = setBackupListDataLocal;
    queryBackupListLocal();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Typography textAlign="center" fontWeight="bold" variant="h6">
          BACKUPS
        </Typography>
      </Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "background.elevation4",
          p: "8px",
          flex: 1,
          overflowY: "auto",
        }}
      >
        <Box
          height="100%"
          display="grid"
          gridTemplateColumns="repeat(3, 33%)"
          gap="8px"
          pr="8px"
          gridAutoRows="50%"
        >
          {Object.keys(backUpNamesAndFileSize).map((name) => {
            return (
              <BackupTile
                restoreSpecificBackup={restoreSpecificBackup}
                backupName={name}
                key={name}
                handleDelete={deleteSpecificBackupLocal}
              />
            );
          })}
          {backupSlots.map((slot) => {
            return <EmptyBackupTile slot={slot} />;
          })}
          <BuyBackupTile />
          <Box
            height="0.1px"
            gridColumn="1 / span 3"
            gridRow="[last-line]"
          ></Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default BackUps;
