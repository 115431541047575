import {
  Box,
  Typography,
  IconButton,
  TextField,
  Dialog,
  Paper,
  Stack,
  Button,
} from "@mui/material";
import { FC, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import { message } from "./Tickets";
import { useUserContext } from "./UserContextProvider";

interface TicketThreadProps {
  threadDetails: { name: string; ticketType: string };
  messages: message[];
}

const TicketThread: FC<TicketThreadProps> = ({ threadDetails, messages }) => {
  const { name } = useUserContext();

  const [showDeleteTicketModal, setShowDeleteTicketModal] =
    useState<boolean>(false);

  const [localMessages, setLocalMessages] = useState(messages);

  const closeDeleteTicketModal = () => {
    setShowDeleteTicketModal(false);
  };

  const [textFieldEntry, setTextFieldEntry] = useState<string>("");

  const handleNewMessage = (message: message) => {
    setLocalMessages((prevstate) => prevstate.concat(message));
  };

  return (
    <Paper
      sx={{
        borderWidth: "1px",
        borderColor: "white",
        borderRadius: "8px",
        borderStyle: "solid",
      }}
    >
      <Box p="8px" display="flex" justifyContent="space-between">
        <Box>
          <Typography fontWeight="bold">{"TICKET: "}</Typography>
          <Typography ml="8px">{`[${threadDetails.ticketType.toLocaleUpperCase()}] ${
            threadDetails.name
          }`}</Typography>
        </Box>
        <Box>
          <IconButton
            color="error"
            onClick={() => setShowDeleteTicketModal(true)}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      </Box>
      {localMessages.map((message, index) => {
        return (
          <Box key={index} borderTop="1px white solid" p="8px">
            <Typography
              display="inline"
              fontWeight="bold"
              color={message.name == "OP Servers" ? "primary" : "orange"}
            >
              {message.name + ": "}
            </Typography>
            <Typography ml="8px">{message.message}</Typography>
          </Box>
        );
      })}
      <Box
        p="8px"
        borderTop="1px white solid"
        display="flex"
        alignItems="center"
      >
        <Box flex={1}>
          <TextField
            fullWidth
            placeholder="Send Message..."
            value={textFieldEntry}
            onChange={(e) => setTextFieldEntry(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleNewMessage({ name: name, message: textFieldEntry });
              }
            }}
          />
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={() =>
              handleNewMessage({ name: name, message: textFieldEntry })
            }
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
      {showDeleteTicketModal && (
        <Dialog open={showDeleteTicketModal} onClose={closeDeleteTicketModal}>
          <Paper sx={{ padding: "8px" }}>
            <Paper
              sx={{
                padding: "8px",
                backgroundColor: "background.elevation4",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">CONFIRM TICKET DELETION</Typography>
              <IconButton onClick={closeDeleteTicketModal}>
                <CancelIcon />
              </IconButton>
            </Paper>
            <Stack spacing="8px" mt="8px">
              <Typography>
                Are you sure you want to delete this ticket?
              </Typography>
              <Button
                variant="contained"
                onClick={closeDeleteTicketModal}
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => {}}
                fullWidth
              >
                Delete
              </Button>
            </Stack>
          </Paper>
        </Dialog>
      )}
    </Paper>
  );
};

export default TicketThread;
