import { Box, TextField } from "@mui/material";
import { updateJem, useJemListener } from "../../../../utils/JemStore";

const Name = () => {
  const nameJem = useJemListener<string>("qb-name");

  return (
    <Box
      sx={{
        backgroundColor: "background.elevation2",
        paddingX: 1,
        flex: 1,
        marginBottom: "8px",
      }}
    >
      <TextField
        fullWidth
        inputProps={{ maxLength: 30 }}
        variant="standard"
        value={nameJem.getValue()}
        placeholder="Name"
        sx={{ paddingX: 1, flex: 1, marginBottom: "8px" }}
        onChange={(event) => {
          updateJem(nameJem, event.target.value?.toLowerCase());
        }}
      />
    </Box>
  );
};

export default Name;
