import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";

const GeneratorDefault = () => {
  const [dimensionSeed, setDimensionSeed] = useState("-78693585");
  const [bedrockRoofPosition, setBedrockRoofPosition] = useState("-10");
  const [bedrockFloorPosition, setBedrockFloorPosition] = useState("0");
  const [seaLevel, setSeaLevel] = useState("63");
  const [disableMobGeneration, setDisableMobGeneration] = useState(false);
  const [defaultBlock, setDefaultBlock] = useState("minecraft:stone");

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridTemplateRows: "1",
        gap: "8px",
        alignItems: "center",
      }}
    >
      <Box gridRow="1" gridColumn="1 / span 3">
        <FormControl fullWidth>
          <FormLabel>Dimension Seed</FormLabel>
          <TextField />
        </FormControl>
      </Box>
      <Box gridRow="1" gridColumn="4 / span 3">
        <FormControl fullWidth>
          <FormLabel>Name</FormLabel>
          <TextField disabled value="minecraft: overworld" />
        </FormControl>
      </Box>
      <Box gridRow="1" gridColumn="7 / span 3">
        {/*validate me to be a number*/}
        <FormControl fullWidth>
          <FormLabel>Bedrock roof position</FormLabel>
          <TextField value={bedrockRoofPosition} />
        </FormControl>
      </Box>
      <Box gridRow="1" gridColumn="10 / span 3">
        {/*validate me to be a number*/}
        <FormControl fullWidth>
          <FormLabel>Bedrock floor position</FormLabel>
          <TextField value={bedrockFloorPosition} />
        </FormControl>
      </Box>
      <Box gridRow="2" gridColumn="1 / span 3">
        {/*validate me to be a number*/}
        <FormControl fullWidth>
          <FormLabel>Sea level</FormLabel>
          <TextField value={seaLevel} />
        </FormControl>
      </Box>
      <Box gridRow="2" gridColumn="4 / span 3">
        <FormControlLabel
          control={
            <Checkbox
              checked={disableMobGeneration}
              onChange={(e) => setDisableMobGeneration(e.target.checked)}
            />
          }
          label="Disable mob generation"
        />
      </Box>
      <Box>
        <FormControl fullWidth>
          <FormLabel>Dimension</FormLabel>
          <Select
            value={defaultBlock}
            onChange={(e) => setDefaultBlock(e.target.value as string)}
          >
            <MenuItem value="minecraft:blackstone">blackstone</MenuItem>
            <MenuItem value="minecraft:blackstone_slab">
              blackstone_slab
            </MenuItem>
            <MenuItem value="minecraft:blackstone_stairs">
              blackstone_stairs
            </MenuItem>
            <MenuItem value="minecraft:chiseled_polished_blackstone">
              blackstone_polished_blackstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_red_sandstone">
              chiseled_red_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_sandstone">
              chiseled_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_stone_bricks">
              chiseled_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cobblestone">cobblestone</MenuItem>
            <MenuItem value="minecraft:cobblestone_slab">
              cobblestone_slab
            </MenuItem>
            <MenuItem value="minecraft:blackstone_slab">
              blackstone_slab
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_stairs">
              cobblestone_stairs
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_wall">
              cobblestone_wall
            </MenuItem>
            <MenuItem value="minecraft:cracked_polished_blackstone_bricks">
              cracked_polished_blackstone_bricks
            </MenuItem>
            <MenuItem value="minecraft:chiseled_sandstone">
              chiseled_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_stone_bricks">
              chiseled_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cobblestone">cobblestone</MenuItem>
            <MenuItem value="minecraft:cobblestone_slab">
              cobblestone_slab
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_stairs">
              cobblestone_stairs
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_wall">
              cobblestone_wall
            </MenuItem>
            <MenuItem value="minecraft:cracked_polished_blackstone_bricks">
              cracked_polished_bricks
            </MenuItem>
            <MenuItem value="minecraft:blackstone">blackstone</MenuItem>
            <MenuItem value="minecraft:blackstone_slab">
              blackstone_slab
            </MenuItem>
            <MenuItem value="minecraft:blackstone_stairs">
              blackstone_stairs
            </MenuItem>
            <MenuItem value="minecraft:chiseled_polished_blackstone">
              blackstone_polished_blackstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_red_sandstone">
              chiseled_red_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_sandstone">
              chiseled_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_stone_bricks">
              chiseled_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cobblestone">cobblestone</MenuItem>
            <MenuItem value="minecraft:cobblestone_slab">
              cobblestone_slab
            </MenuItem>
            <MenuItem value="minecraft:blackstone_slab">
              blackstone_slab
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_stairs">
              cobblestone_stairs
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_wall">
              cobblestone_wall
            </MenuItem>
            <MenuItem value="minecraft:cracked_polished_blackstone_bricks">
              cracked_polished_blackstone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cracked_stone_bricks">
              cracked_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cut_red_sandstone">
              cut_red_sandstone
            </MenuItem>
            <MenuItem value="minecraft:cut_red_sandstone_slab">
              cut_red_sandstone_slab
            </MenuItem>
            <MenuItem value="minecraft:end_stone">end_stone</MenuItem>
            <MenuItem value="minecraft:end_stone_brick_slab">
              end_stone_brick_slab
            </MenuItem>
            <MenuItem value="minecraft:end_stone_brick_stairs">
              end_stone_brick_stairs
            </MenuItem>
            <MenuItem value="minecraft:end_stone_brick_wall">
              end_stone_brick_wall
            </MenuItem>
            <MenuItem value="minecraft:end_stone_bricks">
              end_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:gilded_blackstone">
              gilded_blackstone
            </MenuItem>
            <MenuItem value="minecraft:glowstone">glowstone</MenuItem>
            <MenuItem value="minecraft:grindstone">grindstone</MenuItem>
            <MenuItem value="minecraft:infested_chiseled_stone_bricks">
              infested_chiseled_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:chiseled_sandstone">
              chiseled_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_stone_bricks">
              chiseled_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cobblestone">cobblestone</MenuItem>
            <MenuItem value="minecraft:cobblestone_slab">
              cobblestone_slab
            </MenuItem>
            <MenuItem value="minecraft:blackstone_slab">
              blackstone_slab
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_stairs">
              cobblestone_stairs
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_wall">
              cobblestone_wall
            </MenuItem>
            <MenuItem value="minecraft:cracked_polished_blackstone_bricks">
              cracked_polished_blackstone_bricks
            </MenuItem>
            <MenuItem value="minecraft:chiseled_sandstone">
              chiseled_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_stone_bricks">
              chiseled_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cobblestone">cobblestone</MenuItem>
            <MenuItem value="minecraft:cobblestone_slab">
              cobblestone_slab
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_stairs">
              cobblestone_stairs
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_wall">
              cobblestone_wall
            </MenuItem>
            <MenuItem value="minecraft:cracked_polished_bricks">
              cracked_polished_bricks
            </MenuItem>
            <MenuItem value="minecraft:blackstone">blackstone</MenuItem>
            <MenuItem value="minecraft:blackstone_slab">
              blackstone_slab
            </MenuItem>
            <MenuItem value="minecraft:blackstone_stairs">
              blackstone_stairs
            </MenuItem>
            <MenuItem value="minecraft:chiseled_polished_blackstone">
              blackstone_polished_blackstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_red_sandstone">
              chiseled_red_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_sandstone">
              chiseled_sandstone
            </MenuItem>
            <MenuItem value="minecraft:chiseled_stone_bricks">
              chiseled_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cobblestone">cobblestone</MenuItem>
            <MenuItem value="minecraft:cobblestone_slab">
              cobblestone_slab
            </MenuItem>
            <MenuItem value="minecraft:blackstone_slab">
              blackstone_slab
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_stairs">
              cobblestone_stairs
            </MenuItem>
            <MenuItem value="minecraft:cobblestone_wall">
              cobblestone_wall
            </MenuItem>
            <MenuItem value="minecraft:cracked_polished_blackstone_bricks">
              cracked_polished_blackstone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cracked_stone_bricks">
              cracked_stone_bricks
            </MenuItem>
            <MenuItem value="minecraft:cut_red_sandstone">
              cut_red_sandstone
            </MenuItem>
            <MenuItem value="minecraft:cut_red_sandstone_slab">
              cut_red_sandstone_slab
            </MenuItem>
            <MenuItem value="minecraft:end_stone">end_stone</MenuItem>
            <MenuItem value="minecraft:end_stone_brick_slab">
              end_stone_brick_slab
            </MenuItem>
            <MenuItem value="minecraft:end_stone_brick_stairs">
              end_stone_brick_stairs
            </MenuItem>
            <MenuItem value="minecraft:end_stone_brick_wall">
              end_stone_brick_wall
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default GeneratorDefault;
