import { Box, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useAddFileListener, useFileContext } from "./FileContext";
import { UnsavedChangesPopup } from "../../Popup";

export type linkFunctionPairs = [string, () => void][];

// There is technically no reason why it has to be a void function
// Rather than just having a (x: string) => void as this would match
// QueryFileSystem and therefore it could be passed directly without
// the need for an extra anonymous function calling it HOWEVER
// This is a more reusable solution.

interface BreadCrumbProps {
  linkFunctionPairs: linkFunctionPairs;
}

type HandleClickProps = {
  isClickable: boolean;
  onClick: () => void;
};

const BreadCrumb: React.FC<BreadCrumbProps> = ({ linkFunctionPairs }) => {
  const [fileChangesMade, setFileChangesMade] = useState(false);
  const fileRef = useFileContext();
  useAddFileListener(fileRef, setFileChangesMade);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleClickRef = useRef<() => void>(() => {});

  const handleClick = ({ isClickable, onClick }: HandleClickProps) => {
    handleClickRef.current = onClick;

    if (isClickable && !fileChangesMade) {
      onClick();
      return;
    }
    if (fileChangesMade) {
      setPopupOpen(true);
      return;
    }
    return;
  };

  return (
    <Box display="flex" ml="2px">
      {linkFunctionPairs.map(([label, func], index, array) => (
        <>
          <Typography
            onClick={() =>
              handleClick({
                isClickable: index !== array.length - 1,
                onClick: func,
              })
            }
            key={label}
            sx={{
              textDecoration: "underline",
              cursor: index !== array.length - 1 ? "pointer" : "cursor",
              zIndex: 1,
            }}
          >
            {label}
          </Typography>
          {index !== array.length - 1 && (
            <Typography ml="4px" mr="4px">
              /
            </Typography>
          )}
        </>
      ))}
      <UnsavedChangesPopup
        open={popupOpen}
        handleClose={() => {
          setPopupOpen(false);
        }}
        defaultFunction={handleClickRef.current}
      />
    </Box>
  );
};

export default BreadCrumb;
