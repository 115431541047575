import { useParams } from "react-router-dom";
import Plan from "./Plan";

const PlanWithDynamicRam = () => {
  const { ram } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const nearestCity = urlParams.get("nearestCity");

  const ramAsNumber = Number(ram);

  if (isNaN(ramAsNumber)) {
    return <div>Invalid RAM value</div>;
  }

  return <Plan ram={ramAsNumber} defaultLocation={nearestCity} />;
};

export default PlanWithDynamicRam;
