import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../firebase";
import backgroundImage from "../assets/LoginBackground.png";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
  }, [user, loading]);
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Grid container spacing={0} sx={{ justifyContent: "center" }}>
        <Grid item xs={11} sm={6} md={4} lg={3} xl={2}>
          <Paper elevation={0}>
            <Grid
              container
              spacing={0}
              sx={{
                paddingTop: "15px",
                paddingBottom: "15px",
                flex: 1,
                columnGap: 3,
                rowGap: 1,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid item xs={10}>
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full Name"
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ width: "100%" }}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail Address"
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  sx={{ width: "100%" }}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Grid>
              <Grid item xs={10}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={register}
                >
                  <Typography color="textPrimary">Register</Typography>
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Button
                  variant="contained"
                  color="info"
                  sx={{ width: "100%" }}
                  onClick={signInWithGoogle}
                  startIcon={<GoogleIcon sx={{ color: "white" }} />}
                >
                  <Typography color="textPrimary">
                    Register with Google
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography>
                  Already have an account?
                  <Link to="/login">
                    <Typography style={{ textDecoration: "underline" }}>
                      Login now.
                    </Typography>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
export default Register;
