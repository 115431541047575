import {
  Button,
  Typography,
  Modal,
  Box,
  Snackbar,
  Paper,
  Icon,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import "./animation.css";
import InfoIcon from "@mui/icons-material/Info";

import PlayerTable from "./players-components/PlayerTable";
import { useState } from "react";
import ServerProperties from "./ServerProperties";
import { RamUsage } from "./server-manager-components/Graphs";
import StartButton from "./server-manager-components/StartButton";
import RestartButton from "./server-manager-components/RestartButton";
import PlayerPreview from "./server-manager-components/PlayerPreview";
import Tile_Version from "./server-manager-components/Tile_Version";
import Tile_PlayerCount from "./server-manager-components/Tile_PlayerCount";
import Tile_Ram from "./server-manager-components/Tile_Ram";
import Tile_Address from "./server-manager-components/Tile_Address";
import { TileColours, megaGrid } from "./server-manager-components/Styles";
import TimeOnline from "./server-manager-components/TimeOnline";
import Tile_ServerProperties from "./server-manager-components/Tile_ServerProperties";
import PlayerFeed from "./server-manager-components/PlayerFeed";
import PlayerTableBanner from "./server-manager-components/PlayerTableBanner";
import PlayerBanner from "./server-manager-components/PlayerBanner";
import PercentageBox from "./server-manager-components/PercentageBox";
import { useJem, useJemListener } from "../../utils/JemStore";
import { CreateAndUploadBlog } from "../../utils/UploadBlog";
import { ServerDataStruct } from "../../backend/ServerData";
//import { query_SimpleListOfPlayers } from "../../backend/ServerData";

const ServerManager = (props: any) => {
  const statusJem = useJemListener<string>("status");
  const serverDataJem = useJem<ServerDataStruct>("server-data");
  const [snackOpen, setSnackOpen] = useState(false);
  const handleSnackOpen = () => setSnackOpen(true);
  const handleSnackClose = () => setSnackOpen(false);

  const [isPropertiesOpen, setIsPropertiesOpen] = useState(false);
  const handlePropertiesOpen = () => {
    console.log("Opening properties popup");
    setIsPropertiesOpen(true);
  };
  const handlePropertiesClose = () => {
    console.log("Closing properties popup");
    setIsPropertiesOpen(false);
  };

  const serverPropertiesData: React.MutableRefObject<{
    [property: string]: string;
  } | null> = props.serverPropertiesData;
  const sendServerProperties: (properties: {
    [property: string]: string;
  }) => void = props.sendServerProperties;

  //const id: number = props.id;
  const isLoadingData = props.isLoadingData;
  const isLoadingStatus = props.isLoadingStatus;
  const startStopServerFunction = props.startStopServerFunction;
  const restartServerFunction = props.restartServerFunction;

  const onClickStartStop = () => {
    if (!startStopServerFunction) {
      console.error("NO startStopServerFunction() PASSED TO ServerManager");
      return;
    }
    startStopServerFunction();
  };
  const onClickRestart = () => {
    if (!restartServerFunction) {
      console.error("NO restartServerFunction() PASSED TO ServerManager");
      return;
    }
    handleSnackClose();
    restartServerFunction();
  };

  if (isLoadingData) {
    //TODO: Improve
    return null;
  }

  return (
    <Grid container xs={12} spacing={2} sx={{}}>
      <Grid xs={12} sm={6} display="flex">
        <Paper
          elevation={1}
          sx={{
            padding: "10px",
            backgroundColor: TileColours["Paper.Main"],
            flex: 1,
          }}
        >
          <StartButton
            isLoadingStatus={isLoadingStatus}
            handleClick={onClickStartStop}
          />
        </Paper>
      </Grid>
      <Grid xs={12} sm={6} display="flex">
        <Paper
          elevation={1}
          sx={{
            flex: 1,
            padding: "10px",
            backgroundColor: TileColours["Paper.Main"],
          }}
        >
          <RestartButton
            isLoadingStatus={isLoadingStatus}
            handleClick={onClickRestart}
          />
        </Paper>
      </Grid>
      <Grid xl={3} lg={3} md={3} sm={6} xs={12} display="flex">
        <Tile_Address />
      </Grid>
      <Grid xl={3} lg={3} md={3} sm={6} xs={12} display="flex">
        <Tile_Version />
      </Grid>
      <Grid xl={2} lg={2} md={3} sm={6} xs={12} display="flex">
        <PercentageBox title="RAM USAGE" jemKey="ram-percent" />
      </Grid>
      <Grid
        xl={2}
        lg={2}
        display={{ xs: "none", sm: "none", md: "none", lg: "flex", xl: "flex" }}
      >
        <TimeOnline />
      </Grid>
      <Grid xl={2} lg={2} md={3} sm={6} xs={12} display="flex">
        <PercentageBox title="CPU USAGE" jemKey="cpu-percent" />
      </Grid>
      <Grid
        container
        xs={12}
        spacing={2}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        {/*remove this child grid at your peril, the spacing will refuse to
          propogate and the negative margin will still apply, allowing it to
          violate the padding!*/}
        <Grid xs={12} display={megaGrid}>
          {/*The Mega grid is set up with 36 columns, spacing of 16px,
           the height of the rows is dynamic, when you put in one element
           say a paper with a height of 100px, it will create 100px an
           extra 200px (+ megagrid spacing) of white space*/}
          {/*Duplicates seem to render fine and behind one another,
           but deleting one might cause react to freak out and you will 
           have to refresh*/}
          {/*IDK when to use this but it might be very useful when making
          everything dynamic, if you start something on the same column
          with a paper, MUI will round them correctly into one element
          this probably doesn't work with columns*/}

          <Box
            style={{ gridColumn: "1 / span 7", gridRow: "1", display: "flex" }}
          >
            <Tile_Ram />
          </Box>
          <Box
            style={{ gridColumn: "1 / span 7", gridRow: "2", display: "flex" }}
          >
            <Tile_PlayerCount
              maxPlayers={
                serverPropertiesData.current
                  ? serverPropertiesData.current["max-players"] ?? "10"
                  : "10"
              }
            />
          </Box>
          <Box
            style={{ gridColumn: "1 / span 7", gridRow: "3", display: "flex" }}
          >
            <Tile_ServerProperties handleOpen={handlePropertiesOpen} />
          </Box>
          <Box
            style={{
              gridColumn: "8 / span 11",
              gridRow: "1 / span 3",
              display: "flex",
            }}
          >
            {/* <LaunchOptions
              sendServerProperties={sendServerProperties}
              serverPropertiesData={serverPropertiesData}
            /> */}
          </Box>
          <Box
            style={{
              gridColumn: "19 / span 18",
              gridRow: "1 / span 3",
              display: "flex",
            }}
          >
            <PlayerFeed />
          </Box>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={4}
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Tile_Ram />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={4}
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Tile_PlayerCount
          maxPlayers={
            serverPropertiesData.current
              ? serverPropertiesData.current["max-players"] ?? "10"
              : "10"
          }
        />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={4}
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Tile_ServerProperties handleOpen={handlePropertiesOpen} />
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={6}
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          },
        }}
      >
        {/* <LaunchOptions
          sendServerProperties={sendServerProperties}
          serverPropertiesData={serverPropertiesData}
        /> */}
      </Grid>
      <Grid
        xs={12}
        sm={6}
        md={6}
        sx={{
          display: {
            xs: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <PlayerFeed />
      </Grid>
      <Grid xl={9} lg={8} md={12} sm={12} xs={12} display="flex">
        <PlayerTableBanner />
      </Grid>
      <Grid
        xl={3}
        lg={4}
        md={12}
        display={{ xl: "flex", lg: "flex", md: "none", sm: "none", xs: "none" }}
      >
        <PlayerBanner />
      </Grid>
      <Grid
        xl={9}
        lg={8}
        md={12}
        sm={12}
        xs={12}
        display={{ xl: "flex", lg: "flex", md: "flex", sm: "flex", xs: "none" }}
      >
        <Paper sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box width="100%">
            <PlayerTable />
          </Box>
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5">NO PLAYERS ONLINE</Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid
        md={7}
        sm={8}
        xs={12}
        display={{ xl: "none", lg: "none", md: "none", sm: "none", xs: "none" }}
      >
        <PlayerBanner />
      </Grid>
      <Grid xl={3} lg={4} md={7} sm={8} xs={12}>
        <PlayerPreview
          playerName="0llie2302"
          lastQuit="10 mins ago"
          timeOnline="42 hours"
          totalConnects={20}
          status="online"
          isLoading={false}
          uuid="fd8e02f4-20d5-4122-b818-a724aadbd60b"
        />
      </Grid>
      <Grid
        md={5}
        sm={4}
        xs={12}
        display={{ xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "none" }}
      >
        <TimeOnline />
      </Grid>
      <Grid xs={12} height="300px" display="flex">
        <Paper sx={{ flex: 1, display: "flex", pt: "5px" }}>
          <RamUsage />
        </Paper>
      </Grid>

      {serverDataJem.getValue()?.ownerUID === "YZkcQpyfI2eKwqqb2M8Yb5CJuYk1" && (
        <Grid xs={2}>
          <Paper sx={{ flex: 1, display: "flex", p: "8px" }}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => CreateAndUploadBlog()}
            >
              UPLOAD BLOG
            </Button>
          </Paper>
        </Grid>
      )}

      {/* THESE SHOULD BE EXTRACTED */}
      <Modal
        open={isPropertiesOpen}
        onClose={handlePropertiesClose}
        disableEscapeKeyDown
        hideBackdrop
      >
        <div>
          <ServerProperties
            handleClose={handlePropertiesClose}
            handleSnackOpen={handleSnackOpen}
            sendServerProperties={sendServerProperties}
            serverPropertiesData={serverPropertiesData}
          //serverData={serverData}
          />
        </div>
      </Modal>
      <Snackbar open={snackOpen} autoHideDuration={60000}>
        <Paper
          sx={{ backgroundColor: "background.elevation4", padding: "8px" }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            {statusJem.getValue() === "online" ? (
              <>
                <Icon>
                  <InfoIcon />
                </Icon>
                <Typography>
                  Server Properties Updated! Would you like to
                </Typography>
                <Typography sx={{ textDecoration: "underline", ml: "4px" }}>
                  Restart
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ ml: "8px", mr: "8px" }}
                  onClick={onClickRestart}
                >
                  <Typography color="text.primary">Now</Typography>
                </Button>
                <Typography>or</Typography>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  sx={{ ml: "8px" }}
                  onClick={handleSnackClose}
                >
                  <Typography color="text.primary">Later</Typography>
                </Button>
              </>
            ) : statusJem.getValue() === "offline" ||
              statusJem.getValue() === "stopping" ? (
              <>
                <Icon sx={{ mr: "12px" }}>
                  <InfoIcon />
                </Icon>
                <Typography>Changes applied</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ ml: "8px" }}
                  onClick={handleSnackClose}
                >
                  <Typography color="text.primary">Okay</Typography>
                </Button>
              </>
            ) : (
              <>
                <Icon sx={{ mr: "12px" }}>
                  <InfoIcon />
                </Icon>
                <Typography>Changes will apply on next restart</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ ml: "12px" }}
                  onClick={handleSnackClose}
                >
                  <Typography color="text.primary">Okay</Typography>
                </Button>
              </>
            )}
          </Box>
        </Paper>
      </Snackbar>
    </Grid>
  );
};

export default ServerManager;
