import {
  Typography,
  Divider,
  Box,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

interface CartProps {
  initialRam: number;
  userNameListeners: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>[]
  >;
  subDomainCheckedListeners: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>>[]
  >;
  ramListeners: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<number>>[]
  >;
}

const Cart: FC<CartProps> = ({
  initialRam,
  userNameListeners,
  subDomainCheckedListeners,
  ramListeners,
}) => {
  const discountCode = useRef<string>("");

  const [userName, setUserName] = useState<string>("");
  const [subDomainChecked, setSubDomainChecked] = useState<boolean>(false);
  const [ram, setRam] = useState<number>(initialRam);

  useEffect(() => {
    userNameListeners.current.push(setUserName);
    subDomainCheckedListeners.current.push(setSubDomainChecked);
    ramListeners.current.push(setRam);
  }, []);

  return (
    <Stack spacing="16px">
      <Typography>Ram: {ram}GB</Typography>
      <Divider />
      <Box display="flex" justifyContent="space-between">
        <ShoppingCartIcon />
        <Typography>£{(ram * 1.5).toFixed(2).slice(0, 5)}</Typography>
      </Box>
      <TextField
        placeholder="Enter dicount code..."
        onChange={(e) => (discountCode.current = e.target.value)}
      />
      <Button
        variant="contained"
        disabled={Number.isNaN(ram)}
        fullWidth
        color="primary"
      >
        <Typography color="white">Checkout</Typography>
      </Button>
    </Stack>
  );
};

export default Cart;
