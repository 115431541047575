import { Paper, Typography } from "@mui/material";

const PlayerBanner = () => {
  return (
    <Paper
      sx={{ flex: 1, padding: "5px", display: "flex", alignItems: "center" }}
    >
      <Typography color="primary" mr="5px">
        PLAYER:
      </Typography>
      <Typography>Ollie2302</Typography>
    </Paper>
  );
};

export default PlayerBanner;
