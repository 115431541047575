import {
  Box,
  ButtonBase,
  Container,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { FC } from "react";

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import TerminalIcon from "@mui/icons-material/Terminal";

type card = {
  title: string;
  description: string;
  icon: JSX.Element;
  function: () => void;
};

const SupportHome: FC = () => {
  const cards: card[] = [
    {
      title: "getting started",
      description: "Click here to read articles on setting up your server",
      icon: <RocketLaunchIcon fontSize="large" color="primary" />,
      function: () => {},
    },
    {
      title: "Account ",
      description:
        "Access information and answers to questions related to your account.",
      icon: <AccountCircleIcon fontSize="large" color="primary" />,
      function: () => {},
    },
    {
      title: "Billing ",
      description:
        "Learn about billing-related topics and manage your payment details.",
      icon: <PointOfSaleIcon fontSize="large" color="primary" />,
      function: () => {},
    },
    {
      title: "Control Panel",
      description:
        "Access tools and resources for configuring and managing your server.",
      icon: <TerminalIcon fontSize="large" color="primary" />,
      function: () => {},
    },
  ];

  return (
    <Box width="100%" px="16px">
      <Box
        display={{ xs: "grid", md: "inline-grid" }}
        gridTemplateColumns="1fr 1fr"
        width={{ xs: "100%", md: "67%" }}
        pr={{ md: "8px", xs: "0px" }}
        gap="16px"
        mb={{ md: "0px", xs: "16px" }}
      >
        <Box gridColumn="1 / span 2" gridRow="1">
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Sort by Topic
          </Typography>
          <Divider />
        </Box>
        {cards.map((card, index) => {
          return (
            <Box
              key={index}
              gridRow={{ xs: 2 + index, sm: Math.floor(0.5 * index + 2) }}
              gridColumn={{ xs: "1 / span 2", sm: (index % 2) + 1 }}
            >
              <ButtonBase
                sx={{ height: "100%", width: "100%" }}
                onClick={card.function}
              >
                <Paper
                  sx={{
                    backgroundColor: "background.elevation2",
                    p: "16px",
                    display: "flex",
                    alignItems: "center",

                    height: "100%",
                    width: "100%",
                  }}
                >
                  {card.icon}
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    ml="16px"
                    justifyContent="space-between"
                  >
                    <Typography fontWeight="bold" textAlign="left">
                      {card.title.toLocaleUpperCase()}
                    </Typography>
                    <Typography textAlign="left">{card.description}</Typography>
                  </Box>
                </Paper>
              </ButtonBase>
            </Box>
          );
        })}
      </Box>

      <Box
        height="100%"
        width={{ sx: "100%", md: "33%" }}
        display={{ md: "inline-flex", sx: "block" }}
        pl={{ md: "8px", sx: "0px" }}
      >
        <Paper
          sx={{
            backgroundColor: "background.elevation4",
            p: "16px",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="center"
            gutterBottom
          >
            Popular Articles
          </Typography>
          <Typography>Popular Articles</Typography>
          <Typography>Popular Articles</Typography>
          <Typography>Popular Articles</Typography>
          <Typography>Popular Articles</Typography>
          <Typography>Popular Articles</Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default SupportHome;
