import { Box, Button, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { VersionDisplay } from "./Version";
import { useJem } from "../../../utils/JemStore";

interface VersionTileProps extends VersionDisplay {
  displaySetter: React.Dispatch<React.SetStateAction<number>>;
  versionNameRef: React.MutableRefObject<string>;
  imageRef: React.MutableRefObject<JSX.Element>;
}

const VersionTile: FC<VersionTileProps> = ({
  name,
  latestVersion,
  image,
  isInstalled,
  backgroundColour,
  displaySetter,
  versionNameRef,
  imageRef,
  changeFunction,
}) => {
  const shouldResetServerFiles = true;
  const serverStatusJem = useJem<string>("status");
  return (
    <>
      <Box
        height="160px"
        sx={{
          padding: "8px",
          backgroundColor: backgroundColour,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
        position="relative"
      >
        <Box
          position="absolute"
          height="95%"
          top="50%"
          left="50%"
          sx={{ transform: "translate(-50%, -50%)" }}
        >
          {image}
        </Box>
      </Box>
      <Box
        height="160px"
        sx={{
          padding: "8px",
          backgroundColor: "background.elevation3",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography textAlign="center" fontWeight="bold" variant="h6">
          {name}
        </Typography>
        {isInstalled ? (
          <Button size="large" variant="outlined" color="primary" disabled>
            {latestVersion} installed
          </Button>
        ) : (
          <Button
            disabled={serverStatusJem.getValue() !== "offline"}
            onClick={
              changeFunction
                ? () => {
                    changeFunction(shouldResetServerFiles, latestVersion);
                  }
                : () => {
                    console.error("No change version function provided...");
                  }
            }
            size="large"
            variant="outlined"
            color="primary"
          >
            install {latestVersion}
          </Button>
        )}

        <Button
          size="large"
          variant="outlined"
          sx={{
            borderColor: "white",
            color: "white",
            "&:hover": {
              borderColor: "white",
              backgroundColor:
                "rgba(255, 255, 255, 0.1)" /* A slightly darker white */,
            },
          }}
          onClick={() => {
            displaySetter(1);
            versionNameRef.current = name;
            imageRef.current = image;
          }}
        >
          versions
        </Button>
      </Box>
    </>
  );
};

export default VersionTile;
