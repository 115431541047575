import { CSSProperties, ReactNode } from "react";
import { UploadBlog } from "../firebase";

export type ElementObject = {
  Tag: string;
  style?: CSSProperties;
  url?: string;
  child?: ReactNode | ElementObject;
  variant? : string
};

export type Blog = {
  link: string;
  title: string;
  description: string;
  date: number;
  raw: string;
};

const CreateBlog = (link: string, title: string, description: string, content: ElementObject[]) => {
  const blog: Blog = { link, title, description, raw: JSON.stringify(content), date: Date.now() }

  UploadBlog(blog)
}

const elementArray: ElementObject[] = [
  {
    Tag: "Container",
    style: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "16px",
      paddingBottom: "16px"
    },
    child: {
      Tag: "Typography",
      child: "A History of Minecraft Modpacks",
      variant: "h3"
    },
  },
  
  
];

export const GetDevBlog = () => {
  return elementArray
}

const link = "welcome-to-op-servers"
const title = "OP Servers is here!"
const description = "A new revolutionary platform to change Minecraft hosting forever"

export const CreateAndUploadBlog = () => {
  CreateBlog(link, title, description, elementArray)
}