import { Box } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PricingHeroSection from "../components/pricing/PricingHeroSection";
import PricingOptions from "../components/pricing/PricingOptions";
import Locations from "../components/homepage/Locations";
import ConstructionWarning from "../components/ConstructionWarming";

const Pricing = () => {
  return (
    <Box>
      <NavBar />
      <ConstructionWarning />
      <PricingHeroSection />
      <PricingOptions />
      <Locations />
      <Footer />
    </Box>
  );
};

export default Pricing;
