import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Modal,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useRef, FC } from "react";
import GoodPaper from "../../GoodPaper";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CustomButtonOverlay from "./quick-button-overlay-components/CustomButtonOverlay";
import { QuickButtonData, ServerDataStruct } from "../../../backend/ServerData";
import { iconDictionary } from "../Icons";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import ConsoleLine from "./ConsoleLine";
import DownloadIcon from "@mui/icons-material/Download";
import PlayersButtons from "./PlayersButtons";
import { updateJem, useJem } from "../../../utils/JemStore";
import ConsoleInput from "./ConsoleInput";
import QuickButtonJemWrapper from "./quick-button-overlay-components/QuickButtonJemWrapper";

interface ConsoleProps {
  getLatestLogForConsole: () => void;
  sendMessageToConsole: (message: string) => void;
}

const Console: FC<ConsoleProps> = ({
  getLatestLogForConsole,
  sendMessageToConsole,
}) => {
  const serverDataJem = useJem<ServerDataStruct | null>("server-data");
  const localServerDataClientSide: ServerDataStruct | null =
    serverDataJem.getValue();
  // if (!props.setConsole) {
  //   console.error("No setConsole passed in!");
  // }
  // if (!props.sendMessageToConsole) {
  //   console.error("No sendMessageToConsole passed in!");
  // }
  const [quickButtonData, setQuickButtonData] = useState<QuickButtonData[]>(
    serverDataJem.getValue()?.quick_buttons ?? []
  );
  const [editMode, setEditMode] = useState(false);
  const handleEditModeChange = () => {
    setEditMode(!editMode);
  };

  const [clickedButtonLabel, setClickedButtonLabel] = useState<string | null>(
    null
  );

  const players: string[] = [
    "OpposedBubble1",
    "Demofmeister",
    "Ollie2302",
    "ToothTiger",
    "ViViD",
    "PKiller9000",
  ];
  const clickedPlayers = useRef<string[]>([]);

  const handleSubmissionWithClickedPlayers = (command: string): string => {
    console.log(clickedPlayers.current);
    if (clickedPlayers.current == null) {
      return command;
    }
    var modifiedCommand = command;

    for (let index = 0; index < clickedPlayers.current.length; index++) {
      modifiedCommand = modifiedCommand.replace(
        `(player${index + 1})`,
        clickedPlayers.current[index]
      );
    }

    return modifiedCommand;
  };

  const [buttonNameForSnack, setButtonNameForSnack] = useState("");

  const inputtedCommands = useRef<string[]>([""]);
  const arrayPosition = useRef<number>(0);

  const showOverlayJem = useJem<boolean>("show-overlay");

  const [snackOpen, setSnackOpen] = useState(false);

  const handleOpen = () => updateJem(showOverlayJem, true);
  const handleClose = () => updateJem(showOverlayJem, false);

  const handleSnackOpen = (buttonName: string) => {
    setButtonNameForSnack(buttonName);
    setSnackOpen(true);
  };
  const handleSnackClose = () => setSnackOpen(false);

  const getButton = (qbd: QuickButtonData, keyIndex: number): JSX.Element => {
    if (!qbd) {
      return <></>;
    }
    const handleClick = editMode
      ? () => {
          setClickedButtonLabel(`${qbd.label}`);
          handleOpen();
        }
      : qbd.directEntry
      ? () => {
          handleSubmit(`${qbd.commands}`);
        }
      : () => {
          updateJem(inputJem, handleSubmissionWithClickedPlayers(qbd.commands));
          console.log(handleSubmissionWithClickedPlayers(qbd.commands));
        };

    function backgroundColourOnHover(hex: string) {
      hex = hex.replace("#", "");

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      const darkeningConstant = 0.7;

      const rDarkened = r * darkeningConstant;
      const gDarkened = g * darkeningConstant;
      const bDarkened = b * darkeningConstant;

      return rDarkened + "," + gDarkened + "," + bDarkened;
    }

    const textColour = qbd.isDarkText ? "black" : "white";

    return (
      <Button
        startIcon={
          editMode ? (
            <EditIcon sx={{ color: qbd.isDarkText ? "black" : "white" }} />
          ) : qbd.iconKey !== "none" ? (
            React.createElement(iconDictionary[qbd.iconKey], {
              sx: { color: qbd.isDarkText ? "black" : "white" },
            })
          ) : null
        }
        key={keyIndex}
        onClick={handleClick}
        variant="contained"
        sx={{
          bgcolor: `${qbd.colour}`,
          ml: "4px",
          mr: "4px",
          mb: "8px",
          "&:hover": {
            backgroundColor: `rgb(${backgroundColourOnHover(qbd.colour)})`,
          },
        }}
      >
        <Typography color={textColour}>{qbd.label}</Typography>
      </Button>
    );
  };

  const inputJem = useJem<string>("input-value");

  const consoleRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (inputValue: string) => {
    sendMessageToConsole(inputValue);
    updateJem(inputJem, "");
    inputtedCommands.current = inputtedCommands.current.concat(inputValue);
    arrayPosition.current = inputtedCommands.current.length;
  };
  const handleCreation = (qbd: QuickButtonData): boolean => {
    if (quickButtonData.some((nqbd) => nqbd.label === qbd.label)) {
      return false;
    }
    setQuickButtonData((prevData) => [...prevData, qbd]);
    //Added to the state such that other screens can receive the update in realtime
    localServerDataClientSide!.quick_buttons.push(qbd);
    return true;
  };
  const handleDeletion = (label: string) => {
    const indexForRemoval = localServerDataClientSide!.quick_buttons.findIndex(
      (qbd) => qbd.label === label
    );
    localServerDataClientSide!.quick_buttons.splice(indexForRemoval, 1);
    setQuickButtonData((prevData) =>
      prevData.filter((qbd) => qbd.label !== label)
    );
  };
  const handleEdit = (qbd: QuickButtonData, oldLabel: string) => {
    const newData: QuickButtonData[] = [...quickButtonData];
    newData[quickButtonData.findIndex((nqbd) => nqbd.label === oldLabel)] = qbd;
    localServerDataClientSide!.quick_buttons[
      quickButtonData.findIndex((nqbd) => nqbd.label === oldLabel)
    ] = qbd;
    setQuickButtonData(newData);
  };

  const handleConsoleUpdate = () => {
    //Praise be GPT
    // TODO: Fix this using 1-frame-delayed consoleRef
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  };

  if (localServerDataClientSide == null) {
    return <Typography>Major Error, please contact support</Typography>;
  }

  //const testButton = getButton("label", "#FF0000", false, "/custom", true);
  return (
    <Box
      mr="16px"
      display="flex"
      flexDirection="column"
      sx={{ height: "100%" }}
    >
      <Box
        ref={consoleRef}
        sx={{
          width: "100%",
          backgroundColor: "background.elevation2",
          display: "inline-block",
        }}
        position="relative"
      >
        <Box position="absolute" style={{ right: "36px" }} zIndex={1}>
          <IconButton>
            <DownloadIcon />
          </IconButton>
        </Box>
        <ConsoleLine
          getLatestLogForConsole={getLatestLogForConsole}
          handleConsoleUpdate={handleConsoleUpdate}
        />
      </Box>
      <Box sx={{ display: "flex", backgroundColor: "#d3d3d3" }}>
        <ConsoleInput
          handleSubmit={handleSubmit}
          inputtedCommands={inputtedCommands}
          arrayPosition={arrayPosition}
        />
        <Divider orientation="vertical" color="green" flexItem />
        <Button
          size="small"
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<SendIcon sx={{ color: "white" }} />}
          sx={{
            // Adjust the padding to your desired size
            borderRadius: 0, // Set the borderRadius to 0 to remove rounded edges
          }}
          onClick={() => handleSubmit(inputJem.getValue())}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        justifyContent="space-between"
      >
        <Box>
          <Grid container spacing={1} sx={{ marginTop: "0px" }}>
            <Grid item xs={12}>
              <GoodPaper elevation={2} styles={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      onChange={handleEditModeChange}
                      checked={editMode}
                    />
                    <Icon>
                      <EditIcon />
                    </Icon>
                  </Box>
                  <Box
                    flex="1"
                    display="flex"
                    width="100%"
                    justifyContent="center"
                  >
                    <Typography display="inline" variant="h5" fontWeight="bold">
                      QUICK COMMANDS
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => {
                      setClickedButtonLabel(null);
                      handleOpen();
                    }}
                    sx={{
                      position: "absolute",
                      right: 0,
                      fontSize: "0rem",
                    }}
                  >
                    <AddIcon color="primary" />
                  </IconButton>

                  <QuickButtonJemWrapper
                    handleSnackOpen={handleSnackOpen}
                    handleCreation={handleCreation}
                    handleEdit={handleEdit}
                    handleDeletion={handleDeletion}
                    qbd={
                      editMode
                        ? quickButtonData.find((nqbd) => {
                            return nqbd.label === clickedButtonLabel;
                          })
                        : null
                    }
                  />
                </Box>
              </GoodPaper>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {quickButtonData &&
                quickButtonData.map((qbd, keyIndex) => {
                  return getButton(qbd, keyIndex);
                })}
            </Grid>
          </Grid>
        </Box>
        <Box>
          <PlayersButtons players={players} clickedPlayers={clickedPlayers} />
        </Box>
      </Box>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Success! created button: {buttonNameForSnack}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Console;
