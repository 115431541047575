import { Container, Divider } from "@mui/material";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ContactInformation from "../components/support/ContactInformation";
import KnowledgeBase from "../components/support/KnowledegBase";
import SupportHeroSection from "../components/support/SupportHeroSection";
import { JemContextProvider } from "../utils/JemStore";

const Support = () => {
  return (
    <>
      <NavBar />
      <JemContextProvider contextKey="test" defaultValue={0}>
        <SupportHeroSection />
      </JemContextProvider>
      <ContactInformation />

      <KnowledgeBase />
      <Footer />
    </>
  );
};

export default Support;
