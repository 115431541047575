import {
  Box,
  Typography,
  IconButton,
  Skeleton,
  Switch,
  Button,
  Paper,
} from "@mui/material";
import GoodPaper from "../../GoodPaper";
import { FC } from "react";
import { TileColours } from "./Styles";

interface PlayerPreviewProps {
  playerName: string;
  isLoading: boolean;
  uuid: string;
  status: string;
  totalConnects: number;
  timeOnline: string;
  lastQuit: string;
}

const PlayerPreview: FC<PlayerPreviewProps> = ({
  playerName,
  isLoading,
  uuid,
  status,
  totalConnects,
  timeOnline,
  lastQuit,
}) => {
  return (
    <Paper
      elevation={1}
      sx={{
        padding: "4px",
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: TileColours["Paper.Main"],
      }}
    >
      <Box display="flex">
        {isLoading ? (
          <Skeleton variant="rectangular" width={120} height={270} />
        ) : (
          <img src={`https://crafatar.com/renders/body/${uuid}`}></img>
        )}
      </Box>

      <Box
        flex={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ pl: "8px", pr: "2px" }}
      >
        <Box>
          <Box>
            <Typography display="inline">Name:ㅤ</Typography>
            <Typography display="inline" sx={{ float: "right" }}>
              {playerName}
            </Typography>
          </Box>
          <Box>
            <Typography display="inline">Total Connects:ㅤ</Typography>
            <Typography display="inline" sx={{ float: "right" }}>
              {totalConnects}
            </Typography>
          </Box>
          <Box>
            <Typography display="inline">Status:ㅤ</Typography>
            <Typography display="inline" sx={{ float: "right" }}>
              {status}
            </Typography>
          </Box>
          <Box>
            <Typography display="inline">Time Online:ㅤ</Typography>
            <Typography display="inline" sx={{ float: "right" }}>
              {timeOnline}
            </Typography>
          </Box>
          <Box>
            <Typography display="inline">Last Quit:ㅤ</Typography>
            <Typography display="inline" sx={{ float: "right" }}>
              {lastQuit}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Operator</Typography>
          <Switch />
        </Box>
        <Box>
          <Button
            color="warning"
            variant="contained"
            fullWidth
            sx={{ mb: "4px" }}
          >
            <Typography color="white">KICK</Typography>
          </Button>
          <Button color="error" variant="contained" fullWidth>
            <Typography>BAN</Typography>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default PlayerPreview;
