import {
  Box,
  Paper,
  Stack,
  Typography,
  Divider,
  Checkbox,
} from "@mui/material";
import { FC, useState } from "react";

type addon = {
  title: string;
  description: string;
  price: number;
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

interface AddonsProps {
  subDomainCheckedListeners: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>>[]
  >;
}

const Addons: FC<AddonsProps> = ({ subDomainCheckedListeners }) => {
  const addons: addon[] = [
    {
      title: "plugin setup",
      description:
        "We will set up 10 plugins granting you permissions, ranks and shops right out the box",
      price: 2.99,
      state: useState<boolean>(false),
    },
    {
      title: "custom sub domain",
      description: "have a subdomain different to your username ",
      price: 0.99,
      state: useState<boolean>(false),
    },
    {
      title: "access our custom mod packs",
      description:
        "plug and play our bespoke modpacks with integrated plugins (this is against the minecraft ethos for sure)",
      price: 0.99,
      state: useState<boolean>(false),
    },
  ];

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap="8px">
      {addons.map((addon) => {
        return (
          <Paper>
            <Stack p="8px" spacing="8px">
              <Typography variant="h6" fontWeight="bold" textAlign="center">
                {addon.title.toLocaleUpperCase()}
              </Typography>
              <Divider />
              <Typography textAlign="center">{addon.description}</Typography>
              <Box
                height="100%"
                display="flex"
                justifyContent="flex-end"
                flexDirection="column"
                alignItems="center"
              >
                <Checkbox />
                <Typography>£{addon.price}</Typography>
              </Box>
            </Stack>
          </Paper>
        );
      })}
    </Box>
  );
};

export default Addons;
