import React, { useState, useEffect, FC } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

interface UserNameProps {
  userNameListeners: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<string>>[]
  >;
}

const UserName: FC<UserNameProps> = ({ userNameListeners }) => {
  const [userName, setUserName] = useState<string>("");

  const [avatarURL, setAvatarURL] = useState("");

  const userNameSetter = (name: string) => {
    setUserName(name);
    userNameListeners.current.forEach((l) => {
      l(name);
    });
  };

  useEffect(() => {
    if (userName) {
      const timeoutId = setTimeout(() => {
        // Fetch the avatar URL from the provided link
        fetch(`https://mc-heads.net/avatar/${userName}`)
          .then((response) => {
            if (response.ok) {
              setAvatarURL(`https://mc-heads.net/avatar/${userName}`);
            }
          })
          .catch((error) => {
            setAvatarURL(`https://mc-heads.net/avatar/Demofmeister3`);
            console.error("Error fetching avatar:", error);
          });
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [userName]);

  return (
    <FormControl fullWidth>
      <FormLabel>
        User Name
        <Tooltip
          placement="right"
          title="Add your Minecraft username so we can make you an operator by default :)"
          arrow
        >
          <HelpOutlineIcon
            style={{ marginLeft: "4px", verticalAlign: "middle" }}
          />
        </Tooltip>
      </FormLabel>
      <TextField
        value={userName}
        onChange={(e) => userNameSetter(e.target.value)}
        placeholder="User Name"
        InputProps={{
          startAdornment: avatarURL && (
            <img
              src={avatarURL}
              alt="Avatar"
              style={{ marginRight: "8px", height: "24px", width: "24px" }}
            />
          ),
        }}
      />
    </FormControl>
  );
};

export default UserName;
