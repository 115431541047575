import { Box, Container, Typography } from "@mui/material";
import { FC, useState } from "react";

import SupportHome from "./SupportHome";
import { jsx } from "@emotion/react";

type display = { name: string; element: JSX.Element };

const KnowledgeBase = () => {
  const displays: display[] = [{ name: "home", element: <SupportHome /> }];

  const [display, setDisplay] = useState<JSX.Element>(displays[0].element);

  return (
    <Box>
      <Container maxWidth="lg" disableGutters sx={{ py: "32px" }}>
        {display}
      </Container>
    </Box>
  );
};

export default KnowledgeBase;
