import { Box, Container, Typography } from "@mui/material";

const ConstructionWarning = () => {
  return (
    <Box sx={{ backgroundColor: "darkorange" }}>
      <Container maxWidth="lg">
        <Typography>
          THIS WEBSITE IS UNDER CONSTRUCTION, WE ARE NOT CURRENTLY ACTIVE, SOME
          FEATURES AREN'T COMPLETE PRICING IS NOT REPRESENTITIVE OF OUR TRADING
          PRICES, IF YOU WISH TO PURCHASE A SERVER THROUGH US PLEASE CONTACT US
          VIA DISCORD @ https://discord.gg/SpUS8k9CPE
        </Typography>
      </Container>
    </Box>
  );
};

export default ConstructionWarning;
