import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import GoodPaper from "../../../GoodPaper";

import { FC, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { HexColorPicker } from "react-colorful";
import {
  QuickButtonData,
  ServerDataStruct,
} from "../../../../backend/ServerData";
import {
  deleteQuickButton,
  editQuickButton,
  uploadQuickButton,
} from "../../../../firebase";
import React from "react";
import { iconDictionary } from "../../Icons";
import { updateJem, useJem, useJemListener } from "../../../../utils/JemStore";
import { QuickButtonJemWrapperProps } from "./QuickButtonJemWrapper";
import Name from "./Name";
import InputType from "./InputType";
import CommandInput from "./CommandInput";
import AddCommands from "./AddCommands";

export interface CommandInputWithKey {
  key: number;
  command: string;
  jsx: JSX.Element;
}

const CustomButtonPage: FC<QuickButtonJemWrapperProps> = ({
  handleSnackOpen,
  handleCreation,
  handleDeletion,
  handleEdit,
  qbd,
}) => {
  const isOpenJem = useJemListener<boolean>("show-overlay");

  function backgroundColourOnHover(hex: string) {
    hex = hex.replace("#", "");

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const darkeningConstant = 0.7;

    const rDarkened = r * darkeningConstant;
    const gDarkened = g * darkeningConstant;
    const bDarkened = b * darkeningConstant;

    return rDarkened + "," + gDarkened + "," + bDarkened;
  }
  const serverDataJem = useJem<ServerDataStruct | null>("server-data");

  const iconKeys = Object.keys(iconDictionary);
  const [currentIconKey, setCurrentIconKey] = useState("none");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const handleErrorOpen = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setErrorOpen(true);
  };
  const handleSnackClose = () => setErrorOpen(false);

  const [isDarkText, setIsDarkText] = useState(true);

  var hexToHsl = require("hex-to-hsl");

  const nameJem = useJem<string>("qb-name");
  const isBlockedJem = useJem<boolean>("qb-is-blocked");

  const [colour, setColour] = useState("#b32aa9");

  const [forceRefreshOnRemoval, setForceRefreshOnRemoval] = useState<number>(0);

  const handleSubmit = () => {
    if (nameJem.getValue() === "") {
      handleErrorOpen("Error, no name provided!");
      return;
    } else if (!inputsJem.getValue()) {
      handleErrorOpen("No inputs!");
      return;
    } else if (inputsJem.getValue().length <= 0) {
      handleErrorOpen("No inputs!");
      return;
    } else if (
      inputsJem.getValue()[0].command === "" ||
      inputsJem.getValue()[0].command == null
    ) {
      handleErrorOpen("Error, you need atleast one command!");
      return;
    } else {
      const commandsProcessed: string[] = isBlockedJem.getValue()
        ? [inputsJem.getValue()[0].command]
        : inputsJem.getValue().map((commandInput) => {
            return commandInput.command;
          });
      if (
        commandsProcessed.some((c) => {
          return c.trim() === "";
        })
      ) {
        handleErrorOpen(
          "Error, no command input on line: " +
            (commandsProcessed.findIndex((c) => {
              return c === "";
            }) +
              1)
        );
        return;
      }
      const nqbd: QuickButtonData = {
        label: nameJem.getValue().toLowerCase(),
        colour: colour,
        iconKey: currentIconKey,
        isDarkText: isDarkText,
        commands: commandsProcessed.join("\n"),
        directEntry: !isBlockedJem.getValue(),
      };

      if (!qbd) {
        if (handleCreation(nqbd)) {
          uploadQuickButton(serverDataJem.getValue()!.id, nqbd);
          handleSnackOpen(nqbd.label);
          updateJem(isOpenJem, false);
        } else {
          handleErrorOpen("Cannot set name to a duplicate name");
        }
      } else {
        handleEdit(nqbd, qbd.label);
        editQuickButton(serverDataJem.getValue()!.id, qbd.label, nqbd);
        updateJem(isOpenJem, false);
      }
    }
  };

  const handleDelete = () => {
    const nqbd: QuickButtonData = {
      label: nameJem.getValue().toLowerCase(),
      colour: "",
      iconKey: "",
      isDarkText: false,
      commands: "",
      directEntry: false,
    };
    handleDeletion(nqbd.label);
    deleteQuickButton(serverDataJem.getValue()!.id, nqbd);
    updateJem(isOpenJem, false);
  };

  useEffect(() => {
    if (hexToHsl(colour)[2] <= 50) {
      setIsDarkText(false);
    } else setIsDarkText(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colour]);
  //
  useEffect(() => {
    const prevInputs: CommandInputWithKey[] = inputsJem.getValue();
    const newInputs: CommandInputWithKey[] = [];

    for (let i of prevInputs) {
      const n = i.key;
      newInputs.push({
        key: n,
        command: i.command,
        jsx: <CommandInput key={n} id={n} command={i.command} />,
      });
    }
    updateJem(inputsJem, newInputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockedJem.getValue(), forceRefreshOnRemoval]);

  const inputsJem = useJem<CommandInputWithKey[]>("qb-inputs");

  useEffect(() => {
    if (qbd) {
      //setting edit mode props
      updateJem(nameJem, qbd.label.toUpperCase());
      setColour(qbd.colour);
      updateJem(isBlockedJem, !qbd.directEntry);
      setCurrentIconKey(qbd.iconKey);
      console.log(qbd.commands);
      updateJem(
        inputsJem,
        qbd.commands.split("\n").map((c, index) => {
          return {
            key: index,
            command: c,
            jsx: <CommandInput key={index} id={index} command={c} />,
          };
        })
      );
      setForceRefreshOnRemoval((forceRefreshOnRemoval + 1) % 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (serverDataJem.getValue() == null) {
    console.error("No serverData somehow in CustomButtonOverlay");
    return <Typography>Serious Error, please contact support</Typography>;
  }

  return (
    <Modal
      open={isOpenJem.getValue()}
      onClose={() => updateJem(isOpenJem, false)}
    >
      <GoodPaper
        elevation={3}
        styles={{
          padding: "8px",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          height: "90%",
          display: "inline-block",
          width: 400,
          transform: "translate(-50%, -50%)",
          overflowY: "auto",
        }}
      >
        <GoodPaper styles={{ marginBottom: "8px" }} elevation={4}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography textAlign="center" fontWeight="bold" variant="h6">
              CUSTOM BUTTON BUILDER
            </Typography>
            <IconButton
              onClick={() => updateJem(isOpenJem, false)}
              disableRipple
              sx={{
                position: "absolute",
                right: "0px",
                top: "0px",
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Box>
        </GoodPaper>
        <Divider sx={{ marginBottom: "8px" }}>
          <Typography variant="h6">Name Your Button</Typography>
        </Divider>
        <Name />
        <Divider sx={{ marginBottom: "8px" }}>
          <Typography variant="h6">Input Type</Typography>
        </Divider>
        <InputType />
        <Divider sx={{ marginBottom: "8px" }}>
          <Typography variant="h6">Add Commands</Typography>
        </Divider>
        <AddCommands />
        <Divider sx={{ marginBottom: "8px" }}>
          <Typography variant="h6">Style Your Button</Typography>
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            >
              <HexColorPicker color={colour} onChange={setColour} />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                backgroundColor: "background.elevation2",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                marginBottom: "8px",
                height: "200px",
                overflowY: "auto",
              }}
            >
              <Button
                onClick={() => {
                  setCurrentIconKey("none");
                }}
                color={currentIconKey === "none" ? "success" : "success"}
                sx={{ width: "50vw" }}
              >
                <Typography
                  color={currentIconKey === "none" ? "success" : "white"}
                >
                  none
                </Typography>
              </Button>
              {iconKeys.map((key, index) => (
                <IconButton
                  onClick={() => {
                    setCurrentIconKey(key);
                  }}
                  key={index}
                  style={{ padding: "8px" }}
                  color={currentIconKey === key ? "success" : "default"}
                >
                  {/* Render the icon */}
                  {React.createElement(iconDictionary[key])}
                </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: "8px" }}>
          <Typography variant="h6">Preview</Typography>
        </Divider>
        <Box
          sx={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            startIcon={
              currentIconKey !== "none"
                ? React.createElement(iconDictionary[currentIconKey], {
                    sx: { color: isDarkText ? "black" : "white" },
                  })
                : null
            }
            sx={{
              backgroundColor: colour,
              "&:hover": {
                backgroundColor: `rgb(${backgroundColourOnHover(colour)})`,
              },
            }}
            variant="contained"
          >
            <Typography color={isDarkText ? "black" : "white"}>
              {nameJem.getValue() === "" ? "name" : nameJem.getValue()}
            </Typography>
          </Button>
        </Box>
        <Divider sx={{ marginBottom: "8px" }}>
          <Typography variant="h6">Save And Exit</Typography>
        </Divider>
        <Grid container xs={12} spacing={1} sx={{ ml: "0px" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                marginBottom: "8px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button variant="contained" onClick={handleSubmit}>
                <Typography color="#ffffff">Save my Quick Button</Typography>
              </Button>
            </Box>
          </Grid>
          {qbd ? (
            <Grid item xs={12}>
              <Box
                sx={{
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  <Typography color="#ffffff">delete</Typography>
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Snackbar
          sx={{ position: "absolute" }}
          open={errorOpen}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </GoodPaper>
    </Modal>
  );
};

export default CustomButtonPage;
