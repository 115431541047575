import { Box, Paper, Stack } from "@mui/material";
import PlayerTable from "./PlayerTable";
import BanList, { BanData } from "./BanList";
import { FC } from "react";
import EnableWhitelist from "./EnableWhitelist";
import WhiteListTable from "./WhiteList";
import OpsListTable from "./OpsList";
import PlayerGraph from "./PlayerGraph";

interface PlayersProps {
  serverPropertiesData: React.MutableRefObject<{
    [property: string]: string;
  } | null>;
  sendServerProperties: (properties: { [property: string]: string }) => void;
  whiteListData: React.MutableRefObject<
    | {
        uuid: string;
        name: string;
      }[]
    | null
  >;
  opsListData: React.MutableRefObject<
    | {
        uuid: string;
        name: string;
        level: number;
        bypassesPlayerLimit: boolean;
      }[]
    | null
  >;
  banListData: React.MutableRefObject<
    | {
        uuid: string;
        name: string;
        created: string;
        source: string;
        expires: string;
        reason: string;
      }[]
    | null
  >;
  onAddWhiteList: (add: { uuid: string; name: string }) => void;
  onRemoveWhiteList: (name: string) => void;
  onAddOpsList: (toAddEntry: {
    uuid: string;
    name: string;
    level: number;
    bypassesPlayerLimit: boolean;
  }) => void;
  onRemoveOpsList: (name: string) => void;
  onAddBanList: (toAddEntry: {
    uuid: string;
    name: string;
    created: string;
    source: string;
    expires: string;
    reason: string;
  }) => void;
  onRemoveBanList: (name: string) => void;
}

const Players: FC<PlayersProps> = ({
  serverPropertiesData,
  sendServerProperties,
  whiteListData,
  opsListData,
  banListData,
  onAddWhiteList,
  onRemoveWhiteList,
  onAddOpsList,
  onRemoveOpsList,
  onAddBanList,
  onRemoveBanList,
}) => {
  //These need to be passed in as props at some point
  //or come in directly through firebase

  // const whiteList = [
  //   "OpposedBubble1",
  //   "Demofmeister",
  //   "ToothTiger",
  //   "Pkiller9000",
  // ];

  // const banList: BanData = {
  //   names: ["ViViD", "CthulhuAndHisSon", "Ollie2302"],
  //   reasons: [
  //     "reason 1",
  //     "reason 2",
  //     "really massively long reasons that's just sooooo long so i can test how the wrapping would be handled, you know what, I'm going to make it even longer",
  //   ],
  // };
  //Whenever you decide how to assign this ban list you MUST make sure a reason of atleast "" is given!

  //const operatorsList = ["OpposedBubble1", "Demofmeister"];

  /*const whiteList = whiteListData.current
    ? whiteListData.current.map((w) => w.name)
    : [];
  const operatorsList = opsListData.current
    ? opsListData.current.map((o) => o.name)
    : [];
  const banList = banListData.current
    ? banListData.current.map((b) => {
      return { name: b.name, reason: b.reason };
    })
    : [];*/

  const whiteList = whiteListData.current ?? [];
  const operatorsList = opsListData.current ?? [];
  const banList: BanData = banListData.current ?? [];

  return (
    <Paper sx={{ p: "16px", backgroundColor: "background.elevation2" }}>
      <EnableWhitelist
        serverPropertiesData={serverPropertiesData}
        sendServerProperties={sendServerProperties}
      />
      <Stack spacing="16px">
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing="16px"
          width="100%"
        >
          <Box flex={1}>
            <WhiteListTable
              name="White List"
              data={whiteList}
              onAdd={onAddWhiteList}
              onRemove={onRemoveWhiteList}
            />
          </Box>
          <Box flex={1}>
            <OpsListTable
              name="Operators List"
              data={operatorsList}
              onAdd={onAddOpsList}
              onRemove={onRemoveOpsList}
            />
          </Box>
        </Stack>
        <BanList
          name="Ban List"
          data={banList}
          onAdd={onAddBanList}
          onRemove={onRemoveBanList}
        />
        <PlayerTable />
        <Box display="flex" width="100%" height="300px">
          <PlayerGraph />
        </Box>
      </Stack>
    </Paper>
  );
};

export default Players;
