// UserContextProvider.tsx

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { query, collection, where, getDocs } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";

interface UserContextProviderProps {
  children: React.ReactNode;
}

const EmailContext = createContext<{
  email: string;
  setEmail: (newEmail: string) => void;
}>({ email: "", setEmail: () => {} });

export const useEmailContext = () => useContext(EmailContext);

const UserContext = createContext<{
  name: string;
  setName: (newName: string) => void;
}>({
  name: "",
  setName: () => {},
});

export const useUserContext = () => useContext(UserContext);

const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const [email, setEmailInternal] = useState<string>("");
  const [name, setNameInternal] = useState<string>("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("uid", "==", user?.uid ?? "guest")
      );
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setNameInternal(data.name);
      setEmailInternal(user?.email ?? "");
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    fetchUserName();
  }, [user, loading, navigate]);

  const contextValue = useMemo(
    () => ({
      name,
      setName: setNameInternal,
    }),
    [name]
  );

  return (
    <EmailContext.Provider value={{ email: email, setEmail: setEmailInternal }}>
      <UserContext.Provider value={contextValue}>
        {children}
      </UserContext.Provider>
    </EmailContext.Provider>
  );
};

export default UserContextProvider;
