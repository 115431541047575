import { Box, Container, Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const BlogPreviewPreview = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <NavBar />
      <Container sx={{ py: "16px" }}>
        <Typography textAlign="center" variant="h4">
          A History of Minecraft Modpacks
        </Typography>
      </Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        flex={1}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default BlogPreviewPreview;
