import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";
import { ReactNode } from "react";

interface IHateTypeScript {
  colour?: string;
  elevation?: number;
  children: ReactNode;
  styles?: React.CSSProperties;
}

const GoodPaper: React.FC<IHateTypeScript> = ({
  children,
  colour,
  styles,
  elevation,
}) => {
  const mergedStyles: React.CSSProperties = {
    backgroundColor: colour ?? `background.elevation${elevation}`,
    borderRadius: "4px",

    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);",
    ...styles,
  };
  return <Box sx={mergedStyles}>{children}</Box>;
};

export default GoodPaper;
