import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { updateJem, useJem } from "../../../../utils/JemStore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CommandInputWithKey } from "./CustomButtonOverlay";
import { useState } from "react";

const CommandInput = (props: any) => {
  const id = props.id;
  const command = props.command ?? "";

  const isBlockedJem = useJem<boolean>("qb-is-blocked");
  const inputsJem = useJem<CommandInputWithKey[]>("qb-inputs");

  const [forceRefreshOnRemoval, setForceRefreshOnRemoval] = useState<number>(0);

  const removeButton = (key: number): void => {
    //TODO: HANDLE CASE WHERE ISFIRST
    const isFirstField = isFirst(key);

    updateJem(inputsJem, [
      ...inputsJem.getValue().filter((kvp) => kvp.key !== key),
    ]);
    if (isFirstField) {
      setForceRefreshOnRemoval((forceRefreshOnRemoval + 1) % 2);
    }
  };

  const updateText = (key: number, text: string): void => {
    const index = inputsJem.getValue().findIndex((kvp) => kvp.key === key);
    const newInputs: CommandInputWithKey[] = inputsJem.getValue();

    for (let kvp of inputsJem.getValue()) {
      if (kvp.key === key) {
        newInputs[index].command = text;
      }
    }

    updateJem(inputsJem, [...newInputs]);
  };

  const isFirst = (key: number): boolean => {
    if (inputsJem.getValue().length === 0) {
      return false;
    }
    return inputsJem.getValue()[0].key === key;
  };

  return (
    <Box display="flex" alignItems="center" sx={{ overflowY: "auto" }}>
      <TextField
        disabled={isBlockedJem.getValue() && !isFirst(id)}
        defaultValue={command}
        onChange={(event) => updateText(id, event.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start">/</InputAdornment>,
        }}
        fullWidth
        variant="standard"
        placeholder="next command"
        sx={{ flex: 1, ml: 1 }}
      />
      <IconButton
        onClick={() => {
          removeButton(id);
        }}
        sx={{ height: "1em", width: "1em" }}
      >
        <RemoveCircleOutlineIcon color="error" />
      </IconButton>
    </Box>
  );
};

export default CommandInput;
