import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import Grid from "@mui/material/Unstable_Grid2";

export type InvoiceProps = {
  paid: boolean;
  invoiceNumber: number;
  date: string;
  serverInvoiceDetails: { name: string; ram: number; cost: number }[];
};

const Invoice: FC<InvoiceProps> = ({
  invoiceNumber,
  paid,
  date,
  serverInvoiceDetails,
}) => {
  const total = serverInvoiceDetails.reduce((accumulator, element) => {
    return accumulator + element.cost;
  }, 0);

  return (
    <Grid
      container
      component={Paper}
      sx={{
        borderWidth: "1px",
        borderColor: "white",
        borderRadius: "8px",
        borderStyle: "solid",
      }}
    >
      <Grid xs={6} sx={{ borderRight: "1px solid white", p: "8px" }}>
        <Stack>
          <Typography textAlign="center" fontWeight="bold">
            INVOICE NUMBER
          </Typography>
          <Typography textAlign="center">{invoiceNumber}</Typography>
        </Stack>
      </Grid>
      <Grid xs={6} sx={{ p: "8px" }}>
        <Typography textAlign="center" fontWeight="bold">
          DATE {paid ? "" : " DUE"}
        </Typography>
        <Typography textAlign="center">{date}</Typography>
      </Grid>
      <Grid container xs={12} sx={{ borderTop: "1px solid white", p: "8px" }}>
        <Grid xs={4}>
          <Typography textAlign="left" fontWeight="bold">
            NAME
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography textAlign="right" fontWeight="bold">
            RAM
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography textAlign="right" fontWeight="bold">
            COST
          </Typography>
        </Grid>
        <Grid container xs={12}>
          {serverInvoiceDetails.map((server) => {
            return (
              <>
                <Grid xs={4}>
                  <Typography textAlign="left">{server.name}</Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography textAlign="right">{server.ram}GB</Typography>
                </Grid>
                <Grid xs={4}>
                  <Typography textAlign="right">
                    £{server.cost.toFixed(2)}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ borderTop: "1px solid white", p: "8px" }}>
        <Grid xs={8}></Grid>
        <Grid xs={4}>
          <Stack>
            <Typography fontWeight="bold" textAlign="right">
              TOTAL
            </Typography>
            <Typography textAlign="right">£{total.toFixed(2)}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Invoice;
