import {
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { URLSearchParams } from "url";

import { Link } from "react-router-dom";

import dirt from "../../assets/dirt.webp";
import cobbleStone from "../../assets/cobbleStone.webp";
import stone from "../../assets/stone.webp";
import iron from "../../assets/iron.webp";
import gold from "../../assets/gold.webp";
import diamond from "../../assets/diamond.webp";
import netherStar from "../../assets/Nether_Star.webp";
import { useEffect, useState } from "react";

const PricingOptions = () => {
  const cityCoordinates: Record<
    string,
    { city: string; latitude: number; longitude: number }
  > = {
    London: { city: "London", latitude: 51.5074, longitude: -0.1278 },
    Paris: { city: "Paris", latitude: 48.8566, longitude: 2.3522 },
    Logrono: { city: "Logrono", latitude: 42.4624, longitude: -2.4454 },
    "Frankfurt am Main": {
      city: "Frankfurt am Main",
      latitude: 50.1109,
      longitude: 8.6821,
    },
    Berlin: { city: "Berlin", latitude: 52.52, longitude: 13.405 },
    Newark: { city: "Newark", latitude: 40.7357, longitude: -74.1724 },
    "Las Vegas": { city: "Las Vegas", latitude: 36.1699, longitude: -115.1398 },
  };

  const [userCountryCode, setUserCountryCode] = useState("EU");
  const [userLocation, setUserLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [nearestCity, setNearestCity] = useState("");

  useEffect(() => {
    const fetchUserCountryCode = () => {
      const userLanguage = navigator.language;

      const languageToCountry: { [key: string]: string } = {
        "en-US": "US",
        "en-GB": "GB",
      };

      const countryCode = languageToCountry[userLanguage] || "EU";

      setUserCountryCode(countryCode);
    };

    const fetchUserLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Get user's latitude and longitude
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Set the userLocation state with the detected coordinates
            setUserLocation({ latitude, longitude });

            // Find the nearest city
            let nearestCity = "";
            let nearestDistance = Number.MAX_VALUE;

            for (const cityName in cityCoordinates) {
              if (cityCoordinates.hasOwnProperty(cityName)) {
                const cityData = cityCoordinates[cityName];
                const distance = calculateDistance(
                  { latitude, longitude },
                  { latitude: cityData.latitude, longitude: cityData.longitude }
                );

                if (distance < nearestDistance) {
                  nearestCity = cityData.city;
                  nearestDistance = distance;
                }
              }
            }

            setNearestCity(nearestCity);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by your browser");
      }
    };

    // Call both fetch functions to initiate country and location detection
    fetchUserCountryCode();
    fetchUserLocation();
  }, []);

  const plans: {
    ramAmount: string;
    ram: number;
    description: string;
    storage: string;
    price: string;
    image: string;
  }[] = [
    {
      ramAmount: "1GB",
      ram: 1,
      description: "Basic server",
      storage: "10GB SSD",
      price: "£2.99 per month",
      image: dirt,
    },
    {
      ramAmount: "2GB",
      ram: 2,
      description: "Basic server",
      storage: "10GB SSD",
      price: "£2.99 per month",
      image: cobbleStone,
    },
    {
      ramAmount: "4GB",
      ram: 4,
      description: "Basic server",
      storage: "10GB SSD",
      price: "£2.99 per month",
      image: stone,
    },
    {
      ramAmount: "6GB",
      ram: 6,
      description: "Basic server",
      storage: "10GB SSD",
      price: "£2.99 per month",
      image: iron,
    },
    {
      ramAmount: "8GB",
      ram: 8,
      description: "Basic server",
      storage: "10GB SSD",
      price: "£2.99 per month",
      image: gold,
    },
    {
      ramAmount: "16GB",
      ram: 16,
      description: "Basic server",
      storage: "10GB SSD",
      price: "£2.99 per month",
      image: diamond,
    },
    {
      ramAmount: "Custom",
      ram: 8,
      description: "Get what's right for you",
      storage: "10-240GB SSD",
      price: "£1.99-£49.99/m",
      image: netherStar,
    },
  ];

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        paddingBottom: "40px",
      }}
    >
      <Grid container spacing={2} width="100%" pl="16px">
        {plans.map((plan, index) => {
          return (
            <Grid xs={4} key={index}>
              <Paper
                sx={{
                  backgroundColor: "background.elevation2",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    {plan.ramAmount}
                  </Typography>
                  <Typography>{plan.description}</Typography>
                  <Divider>
                    <img src={plan.image} width="128px" />
                  </Divider>
                </Box>
                <Box my="16px">
                  <Typography>{plan.storage}</Typography>
                  <Typography>£xx.xx</Typography>
                </Box>
                <Box>
                  <Link
                    to={`/plan/${plan.ram}?nearestCity=${encodeURIComponent(
                      nearestCity
                    )}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button color="primary" fullWidth variant="contained">
                      Buy Now
                    </Button>
                  </Link>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PricingOptions;

function calculateDistance(
  point1: { latitude: number; longitude: number },
  point2: { latitude: number; longitude: number }
): number {
  const R = 6371; // Radius of the Earth in kilometers
  const lat1 = (Math.PI / 180) * point1.latitude;
  const lat2 = (Math.PI / 180) * point2.latitude;
  const lon1 = (Math.PI / 180) * point1.longitude;
  const lon2 = (Math.PI / 180) * point2.longitude;

  const dlat = lat2 - lat1;
  const dlon = lon2 - lon1;

  const a =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) * Math.sin(dlon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}
