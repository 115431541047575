import { Box, Container, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import ShieldIcon from "@mui/icons-material/Shield";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import forgeIcon from "../../assets/forgeIcon.svg";
import spigotIcon from "../../assets/faucet-7-svgrepo-com.svg";
import bukkitIcon from "../../assets/bucket-svgrepo-com.svg";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NetworkPingIcon from "@mui/icons-material/NetworkPing";
import LanguageIcon from "@mui/icons-material/Language";

const Features = () => {
  return (
    <Box sx={{ backgroundColor: "background.elevation2" }}>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          paddingY: "40px",
        }}
      >
        <Typography
          variant="h2"
          fontSize="32px"
          mb="20px"
          textAlign="center"
          fontWeight="bold"
          px="16px"
        >
          Revolutionary Server Hosting
        </Typography>
        <Typography mb="20px" textAlign="center" px="16px">
          Our mission is to empower gamers, creators and server admins. With
          one-click mods, modpacks, plugins and data packs we have streamlined
          the process so you can get on with the fun. Our intuitive user
          interface and support is designed with you in mind, don't waste any
          time struggling to get the experience you want. Reliability is our
          priority, with amazing internet access and DDOS protection on our high
          performance servers you can be sure of a good time
        </Typography>
        <Grid container spacing={2} width="100%" pl="16px">
          <Grid xs={12} sm={3}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "8px",
              }}
            >
              <ShieldIcon color="primary" fontSize="large" />
              <Typography pt="8px" fontWeight="bold">
                DDOS PROTECTION
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography textAlign="center">
                  DDos traffic filtering with Always-on attack detection, we'll
                  keep you safe
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid xs={12} sm={6}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "8px",
              }}
            >
              <Box
                display="flex"
                width="100%"
                justifyContent="space-around"
                height="35px"
              >
                <img src={forgeIcon} alt="Forge Icon" width="35" height="35" />
                <img
                  src={spigotIcon}
                  alt="Spigot Icon"
                  width="35"
                  height="35"
                />
                <img
                  src={bukkitIcon}
                  alt="Bukkit Icon"
                  width="35"
                  height="35"
                />
              </Box>
              <Typography pt="8px" fontWeight="bold">
                YOUR MINECRAFT, YOUR WAY
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography textAlign="center">
                  Change your mod or plugin loader with the click of a button
                  and install version compatible mods straight from the site, no
                  need to waste time elsewhere!
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid xs={12} sm={3}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",

                alignItems: "center",
                flexDirection: "column",
                padding: "8px",
              }}
            >
              <DriveFileRenameOutlineIcon color="primary" fontSize="large" />
              <Typography pt="8px" fontWeight="bold">
                FREE SUBDOMAIN
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography textAlign="center">
                  Enjoy a free custom subdomain ending in .op-servers.com for
                  your friends to find
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid xs={12} sm={3}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "8px",
              }}
            >
              <AutoGraphIcon color="primary" fontSize="large" />
              <Typography pt="8px" fontWeight="bold">
                99.9% UPTIME
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography textAlign="center">
                  Our servers boast an exceptional track record of uptime with
                  constant monitoring and swift response times
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid xs={12} sm={3}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",

                alignItems: "center",
                flexDirection: "column",
                padding: "8px",
              }}
            >
              <SupportAgentIcon color="primary" fontSize="large" />
              <Typography pt="8px" fontWeight="bold">
                SUPPORT WHEN YOU NEED IT
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography textAlign="center">
                  Experience top-notch support, your Minecraft adventures
                  deserve nothing less.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid xs={12} sm={3}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",

                alignItems: "center",
                flexDirection: "column",
                padding: "8px",
              }}
            >
              <NetworkPingIcon color="primary" fontSize="large" />
              <Typography pt="8px" fontWeight="bold">
                LOW LATENCY CONNECTIONS
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography textAlign="center">
                  Unlock your Minecraft world with lightning-fast, low-latency
                  connections.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid xs={12} sm={3}>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",

                alignItems: "center",
                flexDirection: "column",
                padding: "8px",
              }}
            >
              <LanguageIcon color="primary" fontSize="large" />
              <Typography pt="8px" fontWeight="bold">
                WORLDS MADE EASY
              </Typography>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography textAlign="center">
                  Pick and choose from a variety of in house worlds whenever you
                  want
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
