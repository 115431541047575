import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Icon,
  Switch,
  Typography,
} from "@mui/material";
import GoodPaper from "../../GoodPaper";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import ArticleIcon from "@mui/icons-material/Article";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useEffect, useRef, useState } from "react";
import BreadCrumb, { linkFunctionPairs } from "./BreadCrumb";
import FileDisplays from "./FileDisplays";
import {
  ChangesReflectedSnackbar,
  FileDeletePopup,
  UploadPopup,
} from "../../Popup";
import { setFileExplorerDataOnFirebase } from "../../../firebase";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import jarIcon from "../../../assets/javaicon.svg";
import virusIcon from "../../../assets/among-us-online-game-multiplayer-game-svgrepo-com.svg";
import envIcon from "../../../assets/CorriganSurvived.svg";
import datIcon from "../../../assets/dat.svg";
import batIcon from "../../../assets/batIcon.svg";
import Lock from "@mui/icons-material/Lock";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import chunkIcon from "../../../assets/minecraftchunk.svg";
import { updateFileState, useFileContext } from "./FileContext";
import FileButton from "./FileButton";
import { updateJem, useJem, useJemListener } from "../../../utils/JemStore";
import { ServerDataStruct } from "../../../backend/ServerData";

interface props {
  setFileExplorerData: React.MutableRefObject<
    (data: string, isDirectory: boolean, directory: string) => void
  >;
  queryFileSystem: (fileOrFolder: string) => void;
  removeFromFileSystem: (fileOrFolder: string) => void;
  sendToFileSystemRaw: (data: string, directory: string) => void;
  sendToFileSystemFB: (remoteDirectory: string, directory: string) => void;
}

export enum ViewType {
  null,
  folder,
  txt,
}

const supportedFileTypes: {
  [type: string]: { extension: string; icon: JSX.Element; view: ViewType };
} = {
  folder: {
    extension: "/",
    icon: <FolderIcon fontSize="large" />,
    view: ViewType.folder,
  },
  txt: {
    extension: ".txt",
    icon: <DescriptionIcon fontSize="large" />,
    view: ViewType.txt,
  },
  json: {
    extension: ".json",
    icon: <ArticleIcon fontSize="large" />,
    view: ViewType.txt,
  },
  properties: {
    extension: ".properties",
    icon: <ArticleIcon fontSize="large" />,
    view: ViewType.txt,
  },
  log: {
    extension: ".log",
    icon: <DescriptionIcon fontSize="large" />,
    view: ViewType.txt,
  },
  gz: {
    extension: ".gz",
    icon: <FolderZipIcon fontSize="large" />,
    view: ViewType.null,
  },
  zip: {
    extension: ".zip",
    icon: <FolderZipIcon fontSize="large" />,
    view: ViewType.null,
  },
  rar: {
    extension: ".rar",
    icon: <FolderZipIcon fontSize="large" />,
    view: ViewType.null,
  },
  jar: {
    extension: ".jar",
    icon: <img src={jarIcon} width="35px" alt="java file icon" />,
    view: ViewType.null,
  },
  virus: {
    extension: ".virus",
    icon: (
      <a href="https://www.youtube.com/watch?v=8i8GGVFX4tQ">
        <img src={virusIcon} width="35px" alt="virus file icon" />
      </a>
    ),
    view: ViewType.null,
  },
  dat: {
    extension: ".dat",
    icon: <img src={datIcon} width="35px" alt="dat file icon" />,
    view: ViewType.null,
  },
  dat_old: {
    extension: ".dat_old",
    icon: <img src={datIcon} width="35px" alt="dat file icon" />,
    view: ViewType.null,
  },
  lock: {
    extension: ".lock",
    icon: <Lock fontSize="large" />,
    view: ViewType.null,
  },
  jpg: {
    extension: ".jpg",
    icon: <PhotoSizeSelectActualIcon fontSize="large" />,
    view: ViewType.null,
  },
  png: {
    extension: ".png",
    icon: <PhotoSizeSelectActualIcon fontSize="large" />,
    view: ViewType.null,
  },
  mca: {
    extension: ".mca",
    icon: <img src={chunkIcon} width="35px" alt="dat file icon" />,
    view: ViewType.null,
  },
  env: {
    extension: ".env",
    icon: <img src={envIcon} width="35px" alt="env file icon" />,
    view: ViewType.null,
  },
  bat: {
    extension: ".bat",
    icon: <img src={batIcon} width="35px" alt="bat file icon" />,
    view: ViewType.null,
  },
  sh: {
    extension: ".sh",
    icon: <img src={batIcon} width="35px" alt="bat file icon" />,
    view: ViewType.null,
  },
};

const FileExplorer = ({
  setFileExplorerData,
  queryFileSystem,
  removeFromFileSystem,
  sendToFileSystemRaw,
  sendToFileSystemFB,
}: props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [deleteMode, setDeleteMode] = useState<boolean>(false);

  //hide these in ghost components and do the ref trick to avoid re rendering the files?
  const [uploadPopUpOpen, setUploadPopUpOpen] = useState<boolean>(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);

  const statusJem = useJemListener<string>("status");
  const serverDataJem = useJem<ServerDataStruct | null>("server-data");

  const fileToBeDeletedName = useRef<string>("");

  const [changesMade, setChangesMade] = useState<boolean>(false);
  const ref = useFileContext();

  const loadingScreenDelayTimeout = useRef<NodeJS.Timeout | null>(null);
  const showLoadingScreen = () => {
    if (loadingScreenDelayTimeout.current != null) {
      clearTimeout(loadingScreenDelayTimeout.current);
    }
    loadingScreenDelayTimeout.current = setTimeout(() => {
      !isLoading && setIsLoading(true);
    }, 500);
  };

  useEffect(() => {
    ref.current.push(setChangesMade);
  }, [ref]);

  const waitingForView = useRef<boolean>(false);
  const viewingDirectory = useRef<string>("");
  const [folderContentsOrFileContents, setFolderContentsOrFileContents] =
    useState<string[] | string>([""]);
  const [contentType, setContentType] = useState<ViewType>(ViewType.null);

  //const setForceRefresh = useState<boolean>(false)[1];
  const [, setDisplaySaveButton] = useState<boolean>(false);
  const getFileData = useRef<() => string>(() => "");
  const dialogJem = useJem("file-dialog");

  const DeleteSwitch: React.FC = (): JSX.Element => {
    return (
      <Box display="flex" alignItems="center">
        <Icon>
          <DeleteIcon color={deleteMode ? "error" : "inherit"} />
        </Icon>
        <Switch
          color="error"
          checked={deleteMode}
          onChange={() => setDeleteMode((prevState) => !prevState)}
        />
      </Box>
    );
  };

  const handleSetFileExplorerData = (
    data: string,
    isDirectory: boolean,
    directory: string
  ) => {
    viewingDirectory.current = directory;
    waitingForView.current = false;
    setIsLoading(false);
    if (loadingScreenDelayTimeout.current != null) {
      clearTimeout(loadingScreenDelayTimeout.current);
    }

    if (isDirectory) {
      const parsedData: string[] = JSON.parse(data);

      console.log(parsedData);
      console.log("this is a directory");
      setContentType(ViewType.folder);
      setFolderContentsOrFileContents(parsedData);
    } else {
      console.log("this is a file");
      console.log(data);
      setContentType(ViewType.txt);
      setFolderContentsOrFileContents(data);
    }

    //Received a file / folder to view. data is either: ["k.txt", "d/"] or "sadklhasdlkhasdlkhaldkhaslkdhalk"
  };

  const queryFileSystemLocal = (fileOrFolder: string) => {
    if (waitingForView.current === true) {
      console.log("Waiting on last request, please wait.");
      return;
    }

    if (fileOrFolder.trim() === "") {
      waitingForView.current = true;
      showLoadingScreen();
      queryFileSystem("");
      return;
    }

    for (const data of Object.values(supportedFileTypes)) {
      if (
        data.view !== ViewType.null &&
        fileOrFolder.endsWith(data.extension)
      ) {
        waitingForView.current = true;
        showLoadingScreen();
        queryFileSystem(fileOrFolder);

        return;
      }
    }

    console.warn("Cannot view this unsupported file type");
    return;
  };

  useEffect(() => {
    setFileExplorerData.current = handleSetFileExplorerData;
    queryFileSystemLocal(viewingDirectory.current); //root
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIcon = (fileName: string): JSX.Element => {
    for (const data of Object.values(supportedFileTypes)) {
      if (fileName.endsWith(data.extension)) {
        return data.icon;
      }
    }
    return <QuestionMarkIcon fontSize="large" />;
  };

  const fileStyles: { [key: string]: string } = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "4px",
    paddingBottom: "4px",
    width: "100%",
    height: "100%",
  };

  const explorerDisplay = (): JSX.Element => {
    const handleClick = (fileName: string) => {
      let intoFolderOrFileDirectory = viewingDirectory.current + fileName;
      // if (intoFolderOrFileDirectory.endsWith("/")) {
      //   intoFolderOrFileDirectory = intoFolderOrFileDirectory.slice(0, -1)
      // }
      queryFileSystemLocal(intoFolderOrFileDirectory);
    };

    const handleBack = () => {
      viewingDirectory.current = viewingDirectory.current
        .split("/")
        .slice(0, -2)
        .join("/");
      if (viewingDirectory.current.trim() === "") {
        queryFileSystemLocal("");
      } else {
        queryFileSystemLocal(viewingDirectory.current + "/");
      }
    };

    const handleSendToFileSystemFB = async (file: File) => {
      if (file.size / (1024 * 1024) >= 10) {
        console.warn("Cannot upload files larger than 10MB!");
        return;
      }
      const fileName = file.name;
      const remoteDirectory: string =
        "DataTransfer/" +
        (serverDataJem.getValue()?.id ?? -1).toString() +
        "/" +
        fileName;
      if (await setFileExplorerDataOnFirebase(remoteDirectory, file)) {
        console.log("UPLOADING TO: " + viewingDirectory.current);
        if (
          !viewingDirectory.current.endsWith("/") &&
          viewingDirectory.current.trim() !== ""
        ) {
          console.error(
            "Viewing directory should end in /, why has this changed?"
          );
          viewingDirectory.current += "/";
        }
        sendToFileSystemFB(
          remoteDirectory,
          viewingDirectory.current.trim() === ""
            ? fileName
            : viewingDirectory.current + fileName
        );
        if (
          statusJem.getValue() === "online" ||
          statusJem.getValue() === "starting"
        ) {
          updateJem(dialogJem, true);
        }
        return;
      } else {
        console.log(
          "Did not spend websocket request to VPS since upload failed."
        );
        return;
      }
    };

    //everything below is stupid and could be made
    //far more reusable, make the ".." folder be the
    //conditionally displayed part and make handle
    //click also have a prop for whether or not it's
    //the one that sends you back...
    const root: boolean = viewingDirectory.current === "";
    if (typeof folderContentsOrFileContents == "string") {
      console.warn(
        "You are trying to display the contents of a folder, whatever you have passed in isn't a folder"
      );
      return <></>;
    }

    return root ? (
      <Grid container spacing={2}>
        <FileDeletePopup
          open={deletePopupOpen}
          handleClose={() => setDeletePopupOpen(false)}
          removeFromFileSystem={removeFromFileSystem}
          actingFileFolderName={fileToBeDeletedName.current}
          viewingDirectory={viewingDirectory}
        />
        {folderContentsOrFileContents
          .sort((a, b) => {
            const endsWithSlashA = a.endsWith("/");
            const endsWithSlashB = b.endsWith("/");

            if (endsWithSlashA && !endsWithSlashB) {
              return -1;
            } else if (!endsWithSlashA && endsWithSlashB) {
              return 1;
            } else {
              return a.localeCompare(b);
            }
          })
          .map((fileName) => {
            return (
              <FileButton
                key={fileName}
                fileName={fileName}
                deleteMode={deleteMode}
                handleClick={handleClick}
                viewingDirectory={viewingDirectory}
                fileToBeDeletedName={fileToBeDeletedName}
                getIcon={getIcon}
                showPopUp={setDeletePopupOpen}
              />
            );
          })}
        <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
          <ButtonBase
            sx={{ width: "100%", height: "100%" }}
            onClick={() => {
              setUploadPopUpOpen(true);
            }}
          >
            <GoodPaper elevation={1} styles={fileStyles}>
              <Icon fontSize="large">
                <AddIcon />
              </Icon>
              <Typography>New File</Typography>
            </GoodPaper>
          </ButtonBase>
        </Grid>
        <UploadPopup
          handleUpload={handleSendToFileSystemFB}
          open={uploadPopUpOpen}
          setOpen={setUploadPopUpOpen}
        />
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <FileDeletePopup
          open={deletePopupOpen}
          handleClose={() => setDeletePopupOpen(false)}
          removeFromFileSystem={removeFromFileSystem}
          actingFileFolderName={fileToBeDeletedName.current}
          viewingDirectory={viewingDirectory}
        />
        <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
          <ButtonBase
            sx={{ width: "100%", height: "100%" }}
            onClick={() => {
              handleBack();
            }}
          >
            <GoodPaper elevation={1} styles={fileStyles}>
              <Icon fontSize="large">
                <FolderIcon fontSize="large" />
              </Icon>
              <Typography>..</Typography>
            </GoodPaper>
          </ButtonBase>
        </Grid>
        {folderContentsOrFileContents
          .sort((a, b) => {
            const endsWithSlashA = a.endsWith("/");
            const endsWithSlashB = b.endsWith("/");

            if (endsWithSlashA && !endsWithSlashB) {
              return -1;
            } else if (!endsWithSlashA && endsWithSlashB) {
              return 1;
            } else {
              return a.localeCompare(b);
            }
          })
          .map((fileName) => {
            return (
              <FileButton
                key={fileName}
                fileName={fileName}
                deleteMode={deleteMode}
                handleClick={handleClick}
                viewingDirectory={viewingDirectory}
                fileToBeDeletedName={fileToBeDeletedName}
                getIcon={getIcon}
                showPopUp={setDeletePopupOpen}
              />
            );
          })}
        <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
          <ButtonBase
            sx={{ width: "100%" }}
            onClick={() => {
              setUploadPopUpOpen(true);
            }}
          >
            <GoodPaper elevation={1} styles={fileStyles}>
              <Icon fontSize="large">
                <AddIcon />
              </Icon>
              <Typography>New File</Typography>
            </GoodPaper>
          </ButtonBase>
        </Grid>
        <UploadPopup
          open={uploadPopUpOpen}
          handleUpload={handleSendToFileSystemFB}
          setOpen={setUploadPopUpOpen}
        />
      </Grid>
    );
  };

  console.log("viewingDirectory.current: " + viewingDirectory.current);
  let directoryAsArray = viewingDirectory.current.slice(0, -1).split("/");
  if (!directoryAsArray.includes("")) {
    directoryAsArray = [""].concat(viewingDirectory.current.split("/"));
  }
  // this gets the viewing directory as an array
  // for each of these i need to create a string, along side a function that queries that string
  // that string will be equal to the length of the array, subract the index and make it into a string separated by /s

  const getFunctionPairs = (directory: string[]): linkFunctionPairs => {
    if (directory.length === 1) {
      return [
        [
          "root",
          () => {
            queryFileSystemLocal("");
          },
        ],
      ];
    }

    const query = directory.join("/") + "/"; // No longer "/"

    const target: linkFunctionPairs = [
      [
        directory[directory.length - 1],
        () => {
          queryFileSystemLocal(query.substring(1));
        },
      ],
    ];

    return getFunctionPairs(directory.slice(0, -1)).concat(target);
  };

  console.log("directoryAsArray: " + directoryAsArray);
  const thisLinkFunctionPairs = getFunctionPairs(directoryAsArray);

  const displayForFileType = new Map<ViewType, JSX.Element>();
  displayForFileType.set(ViewType.null, <></>);
  displayForFileType.set(ViewType.folder, explorerDisplay());
  displayForFileType.set(
    ViewType.txt,
    <FileDisplays
      getFileData={getFileData}
      showSaveButton={setDisplaySaveButton}
      name={directoryAsArray[directoryAsArray.length - 1]}
      fileType={ViewType.txt}
      data={folderContentsOrFileContents as string}
      directory={viewingDirectory.current}
      sendToFileSystemRaw={sendToFileSystemRaw}
      setFolderContentsOrFileContents={setFolderContentsOrFileContents}
    />
  );
  //there's definitely a way to do this in the definition but im too stupid to work it out lol

  return (
    <Box position="relative" mr="16px">
      {isLoading && (
        <Box
          position="absolute"
          zIndex={2}
          sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <AutorenewIcon
              fontSize="large"
              style={{
                animation: "spin 2s linear infinite",
              }}
            />
            <Typography
              variant="h6"
              fontWeight="bold"
              display="inline"
              ml="4px"
            >
              Loading
            </Typography>
          </Box>
        </Box>
      )}
      <GoodPaper
        elevation={2}
        styles={{ padding: "10px", position: "relative" }}
      >
        <GoodPaper
          elevation={3}
          styles={{
            marginBottom: "5px",
            padding: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ChangesReflectedSnackbar />
          <BreadCrumb linkFunctionPairs={thisLinkFunctionPairs} />

          {contentType === ViewType.folder ? (
            <DeleteSwitch />
          ) : (
            <Box>
              <FileDeletePopup
                open={deletePopupOpen}
                handleClose={() => setDeletePopupOpen(false)}
                removeFromFileSystem={removeFromFileSystem}
                actingFileFolderName={fileToBeDeletedName.current}
                viewingDirectory={viewingDirectory}
              />
              <Button
                variant="contained"
                color="error"
                onClick={() => setDeletePopupOpen(true)}
              >
                delete
              </Button>
              {changesMade && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: "8px" }}
                  onClick={() => {
                    const d = getFileData.current();
                    sendToFileSystemRaw(d, viewingDirectory.current);
                    setFolderContentsOrFileContents(d);

                    updateFileState(false, ref);
                  }}
                >
                  <Typography color="white">save</Typography>
                </Button>
              )}
            </Box>
          )}
        </GoodPaper>
        {displayForFileType.get(contentType)}
      </GoodPaper>
    </Box>
  );
};

export default FileExplorer;
