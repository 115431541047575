import { Box, Typography } from "@mui/material";
import ModSearch from "../mods-components/ModSearch";
import { searchTypes } from "../mods-components/ModSearchVariables";

const DataPacks = () => {
  return (
    <Box>
      <Typography>
        Data packs are forward compatible in theory, and they were introduced in
        1.13, however modrinth lists some prior to this?
      </Typography>
      <ModSearch searchType={searchTypes.datapack} />
    </Box>
  );
};

export default DataPacks;
