import { Button, Typography } from "@mui/material";
import { FC, Fragment } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useJemListener } from "../../../utils/JemStore";

interface RestartButtonProps {
  isLoadingStatus: boolean;
  handleClick: () => void;
}

const RestartButton: FC<RestartButtonProps> = ({
  isLoadingStatus,
  handleClick,
}): JSX.Element => {
  const statusJem = useJemListener<string>("status");
  const status = statusJem.getValue()
  return (
    <Fragment>
      {isLoadingStatus ? (
        <Button
          sx={{ borderRadius: 0 }}
          variant="contained"
          color="warning"
          startIcon={<RestartAltIcon />}
          fullWidth
          disabled={true}
        >
          <Typography>{"LOADING"}</Typography>
        </Button>
      ) : (
        <Button
          sx={{ borderRadius: 0 }}
          variant="contained"
          color="warning"
          startIcon={<RestartAltIcon />}
          fullWidth
          disabled={!(status === "online")}
          onClick={handleClick}
        >
          <Typography>{"RESTART"}</Typography>
        </Button>
      )}
    </Fragment>
  );
};

export default RestartButton;
