const buttonTextMap = <T>(param: T | string): T | string=> {
  if (typeof param === 'string') {
    switch (param) {
      case "White List":
        return "remove";
      case "Operators List":
        return "de-op";
      default:
        return param;
    }
  }
  return param

};

export default buttonTextMap