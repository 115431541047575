import vanillaIcon from "../../../assets/vanillaIcon.svg";
import forgeIcon from "../../../assets/forge-white.svg";
import spigotIcon from "../../../assets/spigot.svg";
import VersionTile from "./VersionTile";
import Grid from "@mui/material/Unstable_Grid2";
import { FC } from "react";
import { useJem, useJemListener } from "../../../utils/JemStore";
import { VersionsDataStruct } from "../../../backend/WorldData";
import { Typography } from "@mui/material";
import { VersionDisplay } from "./Version";
import { ServerDataStruct } from "../../../backend/ServerData";

interface VersionHomeProps {
  setDisplay: React.Dispatch<React.SetStateAction<number>>;
  versionNameRef: React.MutableRefObject<string>;
  imageRef: React.MutableRefObject<JSX.Element>;
  changeVanillaServerVersion: (
    shouldReset: boolean,
    versionDict?: string
  ) => void;
}

const VersionHome: FC<VersionHomeProps> = ({ setDisplay, versionNameRef, changeVanillaServerVersion, imageRef }) => {
  const versionsDataJem = useJem<VersionsDataStruct | null>("versions-data")
  const serverDataJem = useJemListener<ServerDataStruct | null>("server-data")
  if (versionsDataJem.getValue() == null) {
    return <Typography>Loading...</Typography>;
  }
  const tempVersions: VersionDisplay[] = [
    // Had to move this in so I could add the changeFunctions in runtime
    {
      name: "Vanilla",
      latestVersion: versionsDataJem.getValue()!.latest.release,
      image: <img src={vanillaIcon} width="100%" height="100%" />,
      isInstalled: serverDataJem.getValue()!.environment_variables["TYPE"].toUpperCase() === "VANILLA" && serverDataJem.getValue()!.environment_variables["VERSION"].toUpperCase() === versionsDataJem.getValue()!.latest.release,
      backgroundColour: "green",
      changeFunction: changeVanillaServerVersion,
    },
    {
      name: "Forge",
      latestVersion: "1.20.1",
      image: <img src={forgeIcon} width="100%" height="100%" />,
      isInstalled: false,
      backgroundColour: "orange",
      changeFunction: undefined,
    },
    {
      name: "Spigot",
      latestVersion: "1.19.1",
      image: <img src={spigotIcon} width="100%" height="100%" />,
      isInstalled: true,
      backgroundColour: "red",
      changeFunction: undefined,
    },
  ];

  return (
    <Grid container spacing={2} width="100%">
      {tempVersions.map((version) => (
        <Grid xs={6} sm={4} md={3} lg={2}>
          <VersionTile
            key={version.name}
            name={version.name}
            latestVersion={version.latestVersion}
            image={version.image}
            isInstalled={version.isInstalled}
            backgroundColour={version.backgroundColour}
            displaySetter={setDisplay}
            versionNameRef={versionNameRef}
            imageRef={imageRef}
            changeFunction={version.changeFunction}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default VersionHome;
