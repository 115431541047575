import { Paper, ButtonBase, Box } from "@mui/material";
import { updateJem, useJem } from "../../../utils/JemStore";
import { PopupData, usePopupContext } from "./PopupContextProvider";

interface EmptyBackupTileProps {
  slot: number;
}

const EmptyBackupTile: React.FC<EmptyBackupTileProps> = ({ slot }) => {
  const popupJem = useJem("world-popup");
  const popupData = usePopupContext();

  const updatePopup = () => {
    popupData.current = {
      title: "create backup",
      description:
        "Fill this backup slot with your current world?NEWLINEThis will preserve all your settings too!",
      func: () => {
        alert("FUNCTION PLEASE DEM!");
      }, // CREATE BACKUP FUNCTION NEEDS TO END UP HERE!
      buttonText: "create",
      buttonColour: "primary",
    };
  };

  return (
    <Paper
      sx={{
        height: "100%",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <ButtonBase
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={() => {
          updatePopup();
          updateJem(popupJem, true);
        }}
      >
        <Box
          flex={1}
          sx={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            background: "linear-gradient(#e66465, #9198e5)",
            width: "100%",
          }}
        ></Box>
        <p
          style={{
            fontSize: "1rem",
            marginTop: "2px",
            marginBottom: "2px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
          }}
        >
          Slot {slot}
        </p>
      </ButtonBase>
    </Paper>
  );
};

export default EmptyBackupTile;
