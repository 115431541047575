import { FC } from "react";
import { JemContextProvider } from "../../../utils/JemStore";
import Console from "./Console";

interface ConsoleJemWrapperProps {
  sendMessageToConsole: (message: string) => void;
  getLatestLogForConsole: () => void;
}

const ConsoleJemWrapper: FC<ConsoleJemWrapperProps> = ({
  sendMessageToConsole,
  getLatestLogForConsole,
}) => {
  return (
    <JemContextProvider contextKey="input-value" defaultValue="">
      <JemContextProvider contextKey="show-overlay" defaultValue={false}>
        <Console
          sendMessageToConsole={sendMessageToConsole}
          getLatestLogForConsole={getLatestLogForConsole}
        />
      </JemContextProvider>
    </JemContextProvider>
  );
};

export default ConsoleJemWrapper;
