import { Alert, Dialog, Snackbar } from "@mui/material";
import { updateJem, useJemListener } from "../../utils/JemStore";

export const DashboardSnackbar: React.FC = () => {
  const popupJem = useJemListener<boolean>("popup");

  return (
    <Snackbar
      open={popupJem.getValue()}
      onClose={() => updateJem(popupJem, false)}
      autoHideDuration={2000}
    >
      <Alert severity="success" onClose={() => updateJem(popupJem, false)}>
        Successfully copied to clip board!
      </Alert>
    </Snackbar>
  );
};
