export interface Dictionary {
  [key: string]: { description: string, accessible: boolean, type: ServerPropertyType, regex?: RegExp, placeholder?: string }
}

export enum ServerPropertyType { String, Boolean, Integer }

export const serverPropertyFields: Dictionary = {
  "allow-flight": { description: "Allows users to use flight on the server while in Survival mode, if they have a mod that provides flight installed. With allow-flight enabled, griefers may become more common, because it makes their work easier. In Creative mode, this has no effect.", accessible: true, type: ServerPropertyType.Boolean },
  "allow-nether": { description: "Allows players to travel to the Nether.", accessible: true, type: ServerPropertyType.Boolean },
  "broadcast-console-to-ops": { description: "Send console command outputs to all online operators.", accessible: true, type: ServerPropertyType.Boolean },
  "broadcast-rcon-to-ops": { description: "Send rcon console command outputs to all online operators.", accessible: false, type: ServerPropertyType.Boolean },
  "difficulty": { description: "Defines the difficulty (such as damage dealt by mobs and the way hunger and poison affects players) of the server.", accessible: true, type: ServerPropertyType.String },
  "enable-command-block": { description: "Enables command blocks", accessible: true, type: ServerPropertyType.Boolean },
  "enable-jmx-monitoring": { description: "Exposes an MBean with the Object name net.minecraft.server:type=Server and two attributes averageTickTime and tickTimes exposing the tick times in milliseconds. In order for enabling JMX on the Java runtime you also need to add a couple of JVM flags to the startup as documented here.", accessible: false, type: ServerPropertyType.Boolean },
  "enable-rcon": { description: "Enables remote access to the server console.", accessible: false, type: ServerPropertyType.Boolean },
  "enable-status": { description: "Makes the server appear as 'online' on the server list. If set to false, it will suppress replies from clients. This means it will appear as offline, but will still accept connections.", accessible: true, type: ServerPropertyType.Boolean },
  "enable-query": { description: "Enables GameSpy4 protocol server listener. Used to get information about server.", accessible: false, type: ServerPropertyType.Boolean },
  "enforce-secure-profile": { description: "If set to true, players without a Mojang-signed public key will not be able to connect to the server.", accessible: true, type: ServerPropertyType.Boolean },
  "enforce-whitelist": { description: "Enforces the whitelist on the server. When this option is enabled, users who are not present on the whitelist (if it's enabled) get kicked from the server after the server reloads the whitelist file.", accessible: true, type: ServerPropertyType.Boolean },
  "entity-broadcast-range-percentage": { description: "Controls how close entities need to be before being sent to clients. Higher values means they'll be rendered from farther away, potentially causing more lag. This is expressed the percentage of the default value. For example, setting to 50 will make it half as usual. This mimics the function on the client video settings (not unlike Render Distance, which the client can customize so long as it's under the server's setting).", accessible: true, type: ServerPropertyType.Integer, regex: /^([1-9][0-9][0-9]?|1000)$/, placeholder: "A number between 10 and 1000" },
  "force-gamemode": { description: "Force players to join in the default game mode.", accessible: true, type: ServerPropertyType.Boolean },
  "function-permission-level": { description: "Sets the default permission level for functions.", accessible: true, type: ServerPropertyType.Integer, regex: /^([1-4])$/, placeholder: "A number between 1 and 4" },
  "gamemode": { description: "Defines the mode of gameplay. If a legacy gamemode number is specified, it is silently converted to a gamemode name.", accessible: true, type: ServerPropertyType.String },
  "generate-structures": { description: "Defines whether structures (such as villages) can be generated.", accessible: true, type: ServerPropertyType.Boolean },
  "generator-settings": { description: "The settings used to customize world generation. Follow its format and write the corresponding JSON string. Remember to escape all : with \:.", accessible: true, type: ServerPropertyType.String },
  "hardcore": { description: "If set to true, server difficulty is ignored and set to hard and players are set to spectator mode if they die.", accessible: true, type: ServerPropertyType.Boolean },
  "hide-online-players": { description: "If set to true, a player list is not sent on status requests.", accessible: true, type: ServerPropertyType.Boolean },
  "initial-disabled-packs": { description: "Comma-separated list of datapacks to not be auto-enabled on world creation.", accessible: true, type: ServerPropertyType.String },
  "initial-enabled-packs": { description: "Comma-separated list of datapacks to be enabled during world creation. Feature packs need to be explicitly enabled.", accessible: true, type: ServerPropertyType.String },
  "level-name": { description: "The 'level-name' value is used as the world name and its folder name. The player may also copy their saved game folder here, and change the name to the same as that folder's to load it instead.", accessible: true, type: ServerPropertyType.String },
  "level-seed": { description: "Sets a world seed for the player's world, as in Singleplayer. The world generates with a random seed if left blank.", accessible: true, type: ServerPropertyType.String },
  "level-type": { description: "Determines the world preset that is generated. Escaping ':' is required when using a world preset ID, and the vanilla world preset ID's namespace (minecraft:) can be omitted. For example: minecraft:flat or minecraft:normal", accessible: true, type: ServerPropertyType.String },
  "log-ips": { description: "Determines whether IPs are logged", accessible: false, type: ServerPropertyType.Boolean },
  "max-chained-neighbor-updates": { description: "	Limiting the amount of consecutive neighbor updates before skipping additional ones. Negative values remove the limit.", accessible: false, type: ServerPropertyType.Integer, regex: /^[-+]?\d+$/, placeholder: "A number" },
  "max-players": { description: "The maximum number of players that can play on the server at the same time. Note that more players on the server consume more resources. Note also, op player connections are not supposed to count against the max players, but ops currently cannot join a full server. However, this can be changed by going to the file called ops.json in the player's server directory, opening it, finding the op that the player wants to change, and changing the setting called bypassesPlayerLimit to true (the default is false). This means that that op does not have to wait for a player to leave in order to join. Extremely large values for this field result in the client-side user list being broken.", accessible: true, type: ServerPropertyType.Integer, regex: /^([1-9][0-9]?[0-9]?[0-9]?|10000|0)$/, placeholder: "A number between 0 and 10000" },
  "max-tick-time": { description: "The maximum number of milliseconds a single tick may take before the server watchdog stops the server with the message, A single server tick took 60.00 seconds (should be max 0.05); Considering it to be crashed, server will forcibly shutdown. Once this criterion is met, it calls System.exit(1).", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:300000|[1-2]?[0-9]{1,5})$/, placeholder: "A number between 0 and 300,000" },
  "max-world-size": { description: "This sets the maximum possible size in blocks, expressed as a radius, that the world border can obtain. Setting the world border bigger causes the commands to complete successfully but the actual border does not move past this block limit. Setting the max-world-size higher than the default doesn't appear to do anything.", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:[1-9]|[1-9][0-9]{1,6}|1[0-9]{7}|2[0-8][0-9]{6}|29[0-8][0-9]{5}|299[0-8][0-9]{4}|2999[0-8][0-9]{3}|29999[0-8][0-9]{2}|299999[0-7][0-9]|2999998[0-4])$/, placeholder: "A number between 1 and 29999984" },
  "motd": { description: "This is the message that is displayed in the server list of the client, below the name.", accessible: true, type: ServerPropertyType.String },
  "network-compression-threshold": { description: "By default it allows packets that are n-1 bytes big to go normally, but a packet of n bytes or more gets compressed down. So, a lower number means more compression but compressing small amounts of bytes might actually end up with a larger result than what went in.", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:[0-9]|[1-9][0-9]{1,2}|1[0-4][0-9]{2}|1500)$/, placeholder: "A number between 0 and 1500" },
  "online-mode": { description: "Server checks connecting players against Minecraft account database. Set this to false only if the player's server is not connected to the Internet. Hackers with fake accounts can connect if this is set to false! If minecraft.net is down or inaccessible, no players can connect if this is set to true. Setting this variable to off purposely is called 'cracking' a server, and servers that are present with online mode off are called 'cracked' servers, allowing players with unlicensed copies of Minecraft to join.", accessible: true, type: ServerPropertyType.Boolean },
  "op-permission-level": { description: "Sets the default permission level for ops when using /op.", accessible: true, type: ServerPropertyType.Integer, regex: /^[0-4]$/, placeholder: "A number between 0 and 4" },
  "player-idle-timeout": { description: "If non-zero, players are kicked from the server if they are idle for more than that many minutes.", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:[0-9]|[1-9][0-9]{1,4}|[1-4][0-9]{5}|5[01][0-9]{4}|52[0-4][0-9]{3}|525[0-8][0-9]{2}|5259[0-5][0-9]|525960)$/, placeholder: "A number between 0 and 525960" },
  "prevent-proxy-connections": { description: "If the ISP/AS sent from the server is different from the one from Mojang Studios' authentication server, the player is kicked.", accessible: true, type: ServerPropertyType.Boolean },
  "previews-chat": { description: "If set to true, chat preview will be enabled.", accessible: true, type: ServerPropertyType.Boolean },
  "pvp": { description: "Enable PvP on the server. Players shooting themselves with arrows receive damage only if PvP is enabled.", accessible: true, type: ServerPropertyType.Boolean },
  "query.port": { description: "Sets the port for the query server (see enable-query).", accessible: false, type: ServerPropertyType.Integer, regex: /^(?:[1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-4])$/, placeholder: "A number between 1 and (2^16)-2" },
  "rate-limit": { description: "Sets the maximum amount of packets a user can send before getting kicked. Setting to 0 disables this feature.", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:[0-9]|[1-9][0-9]{1,4}|100000)$/, placeholder: "A number between 0 and 100,000" },
  "rcon.password": { description: "Sets the password for RCON: a remote console protocol that can allow other applications to connect and interact with a Minecraft server over the internet.", accessible: false, type: ServerPropertyType.String },
  "rcon.port": { description: "Sets the RCON network port.", accessible: false, type: ServerPropertyType.Integer, regex: /^(?:[1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-4])$/, placeholder: "A number between 1 and (2^16)-2" },
  "resource-pack": { description: "Optional URI to a resource pack. The player may choose to use it. Note that (in some versions before 1.15.2), the ': and '=' characters need to be escaped with a backslash (\), e.g. http\://somedomain.com/somepack.zip?someparam\=somevalue", accessible: true, type: ServerPropertyType.String },
  "resource-pack-prompt": { description: "Optional, adds a custom message to be shown on resource pack prompt when require-resource-pack is used.", accessible: true, type: ServerPropertyType.String },
  "resource-pack-sha1": { description: "Optional SHA-1 digest of the resource pack, in lowercase hexadecimal. It is recommended to specify this, because it is used to verify the integrity of the resource pack.", accessible: true, type: ServerPropertyType.String },
  "require-resource-pack": { description: "When this option is enabled (set to true), players will be prompted for a response and will be disconnected if they decline the required pack.", accessible: true, type: ServerPropertyType.Boolean },
  "server-ip": { description: "The player should set this if they want the server to bind to a particular IP. It is strongly recommended that the player leaves server-ip blank.", accessible: false, type: ServerPropertyType.String },
  "server-port": { description: "Changes the port the server is hosting (listening) on. This port must be forwarded if the server is hosted in a network using NAT (if the player has a home router/firewall).", accessible: false, type: ServerPropertyType.Integer, regex: /^(?:[1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-4])$/, placeholder: "A number between 1 and (2^16)-2" },
  "simulation-distance": { description: "Sets the maximum distance from players that living entities may be located in order to be updated by the server, measured in chunks in each direction of the player (radius, not diameter). If entities are outside of this radius, then they will not be ticked by the server nor will they be visible to players.", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:[3-9]|[12][0-9]|3[0-2])$/, placeholder: "A number between 3 and 32" },
  "snooper-enabled": { description: "Sets whether the server sends snoop data regularly to http://snoop.minecraft.net.", accessible: true, type: ServerPropertyType.Boolean },
  "spawn-animals": { description: "Determines if animals can spawn.", accessible: true, type: ServerPropertyType.Boolean },
  "spawn-monsters": { description: "Determines if monsters can spawn.", accessible: true, type: ServerPropertyType.Boolean },
  "spawn-npcs": { description: "Determines whether villagers can spawn.", accessible: true, type: ServerPropertyType.Boolean },
  "spawn-protection": { description: "Determines the side length of the square spawn protection area as 2x+1. Setting this to 0 disables the spawn protection. A value of 1 protects a 3×3 square centered on the spawn point. 2 protects 5×5, 3 protects 7×7, etc. This option is not generated on the first server start and appears when the first player joins. If there are no ops set on the server, the spawn protection is disabled automatically as well", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:[0-9]|[1-9][0-9]{1,5}|1[0-7][0-9]{5}|18[0-6][0-9]{4}|187[0-4][0-9]{3}|1875000)$/, placeholder: "A number between 0 and 1875000" },
  "sync-chunk-writes": { description: "Enables synchronous chunk writes.", accessible: true, type: ServerPropertyType.Boolean },
  "text-filtering-config": { description: "Allows the server to filter text written by players in chat and in written books (Unfinished feature!)", accessible: true, type: ServerPropertyType.String },
  "use-native-transport": { description: "Linux server performance improvements: optimized packet sending/receiving on Linux", accessible: false, type: ServerPropertyType.Boolean },
  "view-distance": { description: "Sets the amount of world data the server sends the client, measured in chunks in each direction of the player (radius, not diameter). It determines the server-side viewing distance.", accessible: true, type: ServerPropertyType.Integer, regex: /^(?:[3-9]|[12][0-9]|3[0-2])$/, placeholder: "A number between 3 and 32" },
  "white-list": { description: "Enables a whitelist on the server. With a whitelist enabled, users not on the whitelist cannot connect. Intended for private servers, such as those for real-life friends or strangers carefully selected via an application process, for example.", accessible: true, type: ServerPropertyType.Boolean },
};