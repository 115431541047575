import { Box, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { TileColours } from "./Styles";
import { useJemListener } from "../../../utils/JemStore";

interface PercentageBoxProps {
  title: string;
  jemKey: string;
}

const PercentageBox: FC<PercentageBoxProps> = ({ title, jemKey }) => {
  const jem = useJemListener<number>(jemKey)

  const percentRounded = Math.round(jem.getValue());
  return (
    <Paper
      sx={{
        flex: 1,
        display: "flex",
        backgroundColor: TileColours["Paper.Main"],
        justifyContent: "center",
      }}
    >
      <Box
        flex={1}
        display={{ xs: "flex", sm: "flex", md: "flex", lg: "none", xl: "flex" }}
      >
        <Box width="100%" minHeight="115px" position="relative">
          <Box
            width="100%"
            height="100%"
            position="absolute"
            zIndex={2}
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
            <Typography
              variant="h4"
              fontWeight="bold"
            >{`${percentRounded}%`}</Typography>
          </Box>
          <Box
            width="100%"
            height={`${percentRounded}%`}
            bottom={0}
            sx={{
              position: "absolute",
              background:
                percentRounded < 60
                  ? "repeating-linear-gradient( -45deg,#60BF63,#60BF63 10px,#469A48 10px,#469A48 20px)"
                  : percentRounded < 85
                    ? "repeating-linear-gradient( -45deg,#BABF60,#BABF60 10px,#969A46 10px,#969A46 20px)"
                    : "repeating-linear-gradient( -45deg,#BF6060,#BF6060 10px,#9A4646 10px,#9A4646 20px)",
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        flex={1}
        display={{ xs: "none", sm: "none", md: "none", lg: "flex", xl: "none" }}
      >
        <Box width="100%" minHeight="100px" position="relative">
          <Box
            width="100%"
            height="100%"
            position="absolute"
            zIndex={2}
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
            <Typography
              variant="h4"
              fontWeight="bold"
            >{`${percentRounded}%`}</Typography>
          </Box>
          <Box
            width="100%"
            height={`${percentRounded}%`}
            bottom={0}
            sx={{
              position: "absolute",
              background:
                percentRounded < 60
                  ? "repeating-linear-gradient( -45deg,#60BF63,#60BF63 10px,#469A48 10px,#469A48 20px)"
                  : percentRounded < 85
                    ? "repeating-linear-gradient( -45deg,#BABF60,#BABF60 10px,#969A46 10px,#969A46 20px)"
                    : "repeating-linear-gradient( -45deg,#BF6060,#BF6060 10px,#9A4646 10px,#9A4646 20px)",
            }}
          ></Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default PercentageBox;
