import {
  Snackbar,
  Alert,
  Dialog,
  Slide,
  Paper,
  Typography,
  IconButton,
  Stack,
  Button,
  Box,
} from "@mui/material";
import { FC, forwardRef } from "react";
import { PopupData, usePopupContext } from "./PopupContextProvider";
import { updateJem, useJemListener } from "../../../utils/JemStore";
import { TransitionProps } from "@mui/material/transitions";
import { Cancel } from "@mui/icons-material";
import { update } from "lodash";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface RestartAlertProps {
  open: boolean;
  onClose: () => void;
}

export const RestartAlert: FC<RestartAlertProps> = ({ open, onClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert onClose={onClose} severity="warning" sx={{ width: "100%" }}>
        Restart the server to see these changes!
      </Alert>
    </Snackbar>
  );
};

export const WorldPopup = () => {
  const values: PopupData = usePopupContext().current!;

  const popupJem = useJemListener<boolean>("world-popup");

  const descriptionLines = values.description.split("NEWLINE");

  function handleClose() {
    updateJem(popupJem, false);
  }

  return (
    <Dialog
      open={popupJem.getValue()}
      TransitionComponent={Transition}
      fullWidth
    >
      <Paper sx={{ p: "8px" }}>
        <Paper
          sx={{
            p: "8px",
            position: "relative",
            backgroundColor: "background.elevation4",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
            color="warning"
            onClick={() => handleClose()}
          >
            <Cancel />
          </IconButton>
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            {values.title.toLocaleUpperCase()}
          </Typography>
        </Paper>
        <Stack pt="8px" spacing="8px">
          {descriptionLines.map((line) => (
            <Typography key={line} textAlign="center">
              {line}
            </Typography>
          ))}
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleClose()}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color={values.buttonColour}
            onClick={() => {
              values.func();
              handleClose();
            }}
          >
            {values.buttonText}
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
};
