import { Container, Skeleton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Blog } from "../../utils/UploadBlog";
import { useEffect, useState } from "react";
import { fetchBlogs } from "../../firebase";
import BlogPreview from "./BlogPreview";

const BlogHome = () => {
  const [blogs, setBlogs] = useState<Blog[]>([] as Blog[]);

  useEffect(() => {
    const GetBlogs = async () => {
      const fetchedBlogs = await fetchBlogs();
      console.log("BLOGS: ", fetchedBlogs); // Log the fetched data
      setBlogs(fetchedBlogs);
    };
    GetBlogs();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: "64px" }}>
      <Grid container spacing={2}>
        {blogs.length == 0
          ? [1, 2, 3, 4, 5, 6].map((num) => (
              <Grid xs={12} sm={6} md={6} lg={4} key={num} height="480px">
                <Skeleton
                  variant="rounded"
                  key={num}
                  width="100%"
                  height="100%"
                />
              </Grid>
            ))
          : blogs.map((blog) => (
              <Grid xs={12} sm={6} md={6} lg={4} key={blog.link}>
                <BlogPreview
                  title={blog.title}
                  description={blog.description}
                  link={blog.link}
                />
              </Grid>
            ))}
      </Grid>
    </Container>
  );
};

export default BlogHome;
