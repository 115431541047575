import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, useMemo, useState } from "react";
import { useJem } from "../../../utils/JemStore";
import { VersionsDataStruct } from "../../../backend/WorldData";

interface VerisonPageProps {
  setDisplay: React.Dispatch<React.SetStateAction<number>>;
  versionNameRef: React.MutableRefObject<string>;
  imageRef: React.MutableRefObject<JSX.Element>;
}

const paginationOptions = [10, 20, 50, 100];

const VerisonPage: FC<VerisonPageProps> = ({
  setDisplay,
  versionNameRef,
  imageRef,
}) => {
  const versionJem = useJem<VersionsDataStruct>("versions-data");
  const [search, setSearch] = useState("");
  const [showSnapShots, setShowSnapShots] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const MatchCases = (version: {
    id: string;
    type: string;
    url: string;
    time: string;
    releaseTime: string;
  }): boolean => {
    return !(
      (!showSnapShots && version.type === "snapshot") ||
      version.type === "old_alpha" ||
      version.type === "old_beta" ||
      !version.id.includes(search)
    );
  };

  const offset = (currentPage - 1) * pageSize;

  const filteredVersions = useMemo(() => {
    return versionJem.getValue().versions.filter(MatchCases);
  }, [search, showSnapShots, versionJem, pageSize, currentPage]);

  const pageCount = Math.ceil(filteredVersions.length / pageSize);

  const currentPageData = filteredVersions.slice(offset, offset + pageSize);

  return (
    <Box pr="16px" pb="16px">
      <Paper sx={{ p: "8px", mb: "16px" }}>
        <Box display="flex" justifyContent="space-between">
          <Box flex={1} display="flex" alignItems="center">
            <IconButton onClick={() => setDisplay(0)}>
              <ArrowBack />
            </IconButton>
            <Typography mx="8px">Show</Typography>
            <Select
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value as number)}
              sx={{ width: "100px" }}
            >
              {paginationOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box flex={1} display={{ xs: "none", sm: "flex" }}>
            <TextField
              autoComplete="off"
              fullWidth
              placeholder="Search..."
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentPage(1);
              }}
            />
          </Box>
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography display={{ xs: "none", sm: "flex" }}>
              Show snapshots
            </Typography>
            <Typography display={{ xs: "flex", sm: "none" }}>
              Snapshots
            </Typography>
            <Checkbox
              checked={showSnapShots}
              onChange={() => setShowSnapShots((prevState) => !prevState)}
            />
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ display: { xs: "flex", sm: "none" }, p: "8px", mb: "16px" }}>
        <TextField
          autoComplete="off"
          fullWidth
          placeholder="Search..."
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(1);
          }}
        />
      </Paper>
      <Box>
        <Paper sx={{ p: "8px" }}>
          <Box display="flex">
            <Box flex={1}>
              <Typography
                textAlign="left"
                variant="h6"
                ml={{ xs: 0, sm: "52px" }}
              >
                VERSION
              </Typography>
            </Box>
            <Typography flex={1} textAlign="center" variant="h6">
              RELEASE
            </Typography>
            <Typography
              flex={1}
              textAlign="center"
              display={{ xs: "none", sm: "block" }}
              variant="h6"
            >
              TYPE
            </Typography>
            <Box flex={1}></Box>
          </Box>
          {currentPageData.map((version, index) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              key={version.id}
              sx={{
                p: "4px",
                backgroundColor: index % 2 == 0 ? "background.elevation2" : "",
              }}
            >
              <Box display="flex" alignItems="center" flex={1}>
                <Box width="48px" mr="4px" display="flex" alignItems="center">
                  {imageRef.current}
                </Box>
                <Typography>
                  {versionNameRef.current + " " + version.id}
                </Typography>
              </Box>
              <Box flex={1} display="flex" justifyContent="center">
                <Typography>
                  {new Date(version.releaseTime).toLocaleDateString()}
                </Typography>
              </Box>
              <Box
                flex={1}
                display={{ xs: "none", sm: "flex" }}
                justifyContent="center"
              >
                <Typography>{version.type}</Typography>
              </Box>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <Button variant="contained">install</Button>
              </Box>
            </Box>
          ))}
        </Paper>
      </Box>
      <Paper
        sx={{
          mt: "16px",
          p: "8px",
          display: { xs: "none", sm: "flex" },
          justifyContent: "center",
        }}
      >
        <Pagination
          count={pageCount}
          page={currentPage}
          color="primary"
          size="large"
          onChange={(event, page) => setCurrentPage(page)}
        />
      </Paper>
      <Paper
        sx={{
          mt: "16px",
          p: "8px",
          display: { xs: "flex", sm: "none" },
          justifyContent: "center",
        }}
      >
        <Pagination
          count={pageCount}
          page={currentPage}
          color="primary"
          size="small"
          onChange={(event, page) => setCurrentPage(page)}
        />
      </Paper>
    </Box>
  );
};

export default VerisonPage;
