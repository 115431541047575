import { Stack } from "@mui/material";
import ServerDescription, { ServerDescriptionProps } from "./ServerDescription";

const ManageMyServers = () => {
  const myServers: ServerDescriptionProps[] = [
    {
      name: "MuscularBathTimeBoys",
      ip: "192.168.1.1",
      datePurchased: "02/10/2023",
      location: "london",
    },
    {
      name: "TruthfulMoment",
      ip: "truthfulmoment.op-servers.com",
      datePurchased: "11/09/2023",
      location: "london",
    },
  ];

  return (
    <Stack spacing="16px">
      {myServers.map((server, index) => {
        return (
          <ServerDescription
            key={index}
            name={server.name}
            ip={server.ip}
            datePurchased={server.datePurchased}
            location={server.location}
          />
        );
      })}
    </Stack>
  );
};

export default ManageMyServers;
