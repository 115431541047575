export const versions: Array<string> = [
  "1.20.1",
  "1.20",
  "1.19.4",
  "1.19.3",
  "1.19.2",
  "1.19.1",
  "1.19",
  "1.18.2",
  "1.18.1",
  "1.18",
  "1.17.1",
  "1.17",
  "1.16.5",
  "1.16.4",
  "1.16.3",
  "1.16.2",
  "1.16.1",
  "1.16",
  "1.15.2",
  "1.15.1",
  "1.15",
  "1.14.4",
  "1.14.3",
  "1.14.2",
  "1.14.1",
  "1.14",
  "1.13.2",
  "1.13.1",
  "1.13",
  "1.12.2",
  "1.12.1",
  "1.12",
  "1.11.2",
  "1.11.1",
  "1.11",
  "1.10.2",
  "1.10.1",
  "1.10",
  "1.9.4",
  "1.9.3",
  "1.9.2",
  "1.9.1",
  "1.9",
  "1.8.9",
  "1.8.8",
  "1.8.7",
  "1.8.6",
  "1.8.5",
  "1.8.4",
  "1.8.3",
  "1.8.2",
  "1.8.1",
  "1.8",
  "1.7.10",
  "1.7.9",
  "1.7.8",
  "1.7.7",
  "1.7.6",
  "1.7.5",
  "1.7.4",
  "1.7.3",
  "1.7.2",
  "1.7.1",
  "1.7",
  "1.6.4", // didn't miss one apparently it doesnt exist
  "1.6.2",
  "1.6.1",
  "1.5.2", // didn't miss one apparently it doesnt exist
  "1.5.1",
  "1.5",
  "1.4.7",
  "1.4.6",
  "1.4.5",
  "1.4.4",
  "1.4.3",
  "1.4.2",
  "1.4.1",
  "1.4",
  "1.3.2",
  "1.3.1", // didn't miss one apparently it doesnt exist
  "1.2.5",
  "1.2.4",
  "1.2.3",
  "1.2.2",
  "1.2.1", // didn't miss one apparently it doesnt exist
  "1.0.1",
  "1.0.0", // SERVER ONLY!
];

export const possibleCategories: { [key: string]: string } = {
  Adventure: "adventure",
  Cursed: "cursed",
  Decoration: "decoration",
  Economy: "economy",
  Equipment: "equipment",
  Food: "food",
  "Game Mechanics": "game-mechanics", // checkme for spacing be required
  Library: "library",
  Magic: "magic",
  Management: "management",
  Minigame: "minigame",
  Mobs: "mobs",
  Optimisation: "optimization",
  Social: "social",
  Storage: "storage",
  Technology: "technology",
  Transportation: "transportation",
  Utility: "utility",
  "World Generation": "worldgen",
};

 export enum searchTypes {
  mod,
  plugin,
  modpack,
  datapack
}
 type searchInfo = {
  placeholder: string,
  modLoaders: string[]
}

export const search = new Map<searchTypes, searchInfo>([
  [searchTypes.mod, {placeholder: "Search mods...", modLoaders: ["Forge", "Fabric", "NeoForge", "Quilt", "Rift"]}],
  [searchTypes.plugin, {placeholder: "Search plugins...", modLoaders: ["Bukkit", "Folia", "Paper", "Purpur", "Spigot", "Sponge"]}],
  [searchTypes.modpack, {placeholder: "string", modLoaders: ["Forge"] }],
  [searchTypes.datapack, {placeholder: "Search datapacks", modLoaders: ["Forge"] }]])
  
  