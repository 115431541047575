import { Box, Icon, IconButton, Paper, Typography } from "@mui/material";
import ramIcon from "../../../assets/ram-svgrepo-com.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import { TileColours } from "./Styles";
import { FC } from "react";
import { ServerDataStruct } from "../../../backend/ServerData";
import { useJemListener } from "../../../utils/JemStore";

interface Tile_RamProps {

}

const Tile_Ram: FC<Tile_RamProps> = () => {
  const serverDataJem = useJemListener<ServerDataStruct | null>("server-data")
  return (
    <Paper
      sx={{
        flex: 1,
        backgroundColor: TileColours["Paper.Main"],
        padding: "10px",
        display: "flex",
      }}
    >
      <Box
        flex={1}
        display={{ xs: "none", sm: "none", md: "flex", lg: "none", xl: "flex" }}
        flexDirection="column"
      >
        <Box flex={1} display="flex">
          <Box flex={1} display="flex" pr="5px">
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "background.elevation1",
                flex: 1,
                display: "flex",
              }}
            >
              <img
                src={ramIcon}
                alt="SVG"
                style={{ width: "100%", maxHeight: "100%" }}
              />
            </Paper>
          </Box>
          <Box
            flex={2}
            pl="5px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="bold">
              RAM
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              {(serverDataJem.getValue()?.ram ?? 0) + "GB"}
            </Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <IconButton>
                <SettingsIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        flex={1}
        display={{ xs: "none", sm: "none", md: "none", lg: "flex", xl: "none" }}
        flexDirection="column"
      >
        <Box flex={1} display="flex">
          <Box flex={1} display="flex" flexDirection="column" pr="5px">
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "background.elevation1",
                flex: 1,
                display: "flex",
              }}
            >
              <img src={ramIcon} alt="SVG" style={{ width: "70px" }} />
            </Paper>
            <Typography variant="h6" fontWeight="bold">
              RAM
            </Typography>
          </Box>
          <Box
            flex={2}
            pl="5px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="bold">
              {(serverDataJem.getValue()?.ram ?? 0) + "GB"}
            </Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <IconButton>
                <SettingsIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        flex={1}
        display={{ xs: "flex", sm: "flex", md: "none", lg: "none", xl: "none" }}
        flexDirection="column"
        maxHeight="125px"
      >
        <Box flex={1} display="flex">
          <Box flex={1} display="flex" pr="5px">
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "background.elevation1",
                flex: 1,
                display: "flex",
              }}
            >
              <img
                src={ramIcon}
                alt="SVG"
                style={{ width: "100%", maxHeight: "125px" }}
              />
            </Paper>
          </Box>
          <Box
            flex={2}
            pl="5px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="bold">
              RAM
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              {(serverDataJem.getValue()?.ram ?? 0) + "GB"}
            </Typography>
          </Box>
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <IconButton>
                <SettingsIcon fontSize="large" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Tile_Ram;
