import { Box, ButtonBase, Paper, Typography } from "@mui/material";
import { WorldCategory, Version } from "../../../backend/WorldData";
import diversityImage from "../../../assets/diversity2.jpg";
import { updateJem, useJem } from "../../../utils/JemStore";
import { usePopupContext } from "./PopupContextProvider";

interface MapTileProps {
  installWorld: (worldName: string) => void;
  mapName: string;
  author: string;
  categories: WorldCategory[];
  reference: string;
  version: Version;
}

const MapTile: React.FC<MapTileProps> = ({
  installWorld,
  mapName,
  author,
  categories,
  reference,
  version,
}) => {
  const showPopup = useJem<boolean>("world-popup");

  const popupData = usePopupContext();

  const handleClick = () => {
    popupData.current = {
      title: "install this map?",
      description: `Install ${mapName} by ${author} for version ${version}`,
      func: () => installWorld(mapName),
      buttonText: "install and restart",
      buttonColour: "primary",
    };
  };

  return (
    <Box
      sx={{
        width: "33.33%",
        height: "33.33%",
        padding: { xs: "4px", md: "8px" },
      }}
    >
      <ButtonBase
        onClick={() => {
          handleClick();
          updateJem(showPopup, true);
        }}
        sx={{ height: "100%", width: "100%" }}
      >
        <Paper
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${diversityImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              flex: 1,
            }}
          ></Box>
          <Box
            sx={{
              padding: "4px",
            }}
          >
            <Typography noWrap>{mapName}</Typography>
          </Box>
        </Paper>
      </ButtonBase>
    </Box>
  );
};

export default MapTile;
