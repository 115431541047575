import { Box, Tab, Tabs } from "@mui/material";
import GoodPaper from "../GoodPaper";
import ModSearch from "./mods-components/ModSearch";
import { searchTypes } from "./mods-components/ModSearchVariables";
import React, { useState } from "react";
import MyMods from "./mods-components/MyMods";

interface ModsProps {

}

const Mods: React.FC<ModsProps> = (): JSX.Element => {
  const tabsMap = new Map<number, JSX.Element>([
    [0, <ModSearch key={0} searchType={searchTypes.mod} />], //Key was the only way to make it bother to refresh the contents in this instance, valuable react lesson!
    [1, <ModSearch key={1} searchType={searchTypes.plugin} />],
    [2, <MyMods key={2} />],
  ]);

  const [value, setValue] = useState<number>(0);

  return (
    <Box pr="16px" pb="16px">
      <GoodPaper elevation={4}>
        <Tabs
          value={value}
          onChange={(e, v) => {
            setValue(v);
          }}
        >
          <Tab label="Get Mods" value={searchTypes.mod} />
          <Tab label="Get Mod Packs" value={searchTypes.plugin} />
          <Tab label="Installed Mods" value={2} />
        </Tabs>
      </GoodPaper>
      <Box>{tabsMap.get(value)}</Box>
    </Box>
  );
};

export default Mods;
