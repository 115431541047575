import { ButtonBase, Stack, Tooltip, Typography } from "@mui/material";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useJemListener } from "../../utils/JemStore";
import CircleIcon from "@mui/icons-material/Circle";

const StatusButton = () => {
  const statusJem = useJemListener<string>("status");

  return (
    <Stack justifyContent="space-between" mr="8px">
      <ButtonBase>
        {statusJem.getValue() === "offline" && (
          <Tooltip title="Start server" placement="left">
            <PlayArrowIcon color="primary" />
          </Tooltip>
        )}
        {statusJem.getValue() === "online" && (
          <Tooltip title="Stop server" placement="left">
            <StopIcon color="error" />
          </Tooltip>
        )}
        {statusJem.getValue() !== "offline" &&
          statusJem.getValue() !== "online" && (
            <Tooltip title={statusJem.getValue()}>
              <CircleIcon color="warning" fontSize="small" />
            </Tooltip>
          )}
      </ButtonBase>
      <ButtonBase
        disabled={statusJem.getValue() !== "online"}
        sx={{
          animation:
            statusJem.getValue() === "restarting"
              ? "spin 2s linear infinite"
              : "",
        }}
      >
        <Tooltip title="Restart" placement="left">
          <RestartAltIcon
            sx={{ color: statusJem.getValue()! == "online" ? "white" : "grey" }}
          />
        </Tooltip>
      </ButtonBase>
    </Stack>
  );
};

export default StatusButton;
