import {
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ViewType } from "./FileExplorer";
import { useEffect, useRef, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import UndoIcon from "@mui/icons-material/Undo";
import { UploadPopup } from "../../Popup";
import {
  updateFileState,
  useAddFileListener,
  useFileContext,
} from "./FileContext";

interface FileDisplaysProps {
  name: string;
  fileType: ViewType;
  data: string;
  directory: string;
  sendToFileSystemRaw: (data: string, directory: string) => void;
  showSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  getFileData: React.MutableRefObject<() => string>;
  setFolderContentsOrFileContents: React.Dispatch<
    React.SetStateAction<string | string[]>
  >;
}

//TODO:
//Upload (with drag)
//Download
//Save (confirmation checks)
//Delete
//restore from versions
//restart prompt

const FileDisplays: React.FC<FileDisplaysProps> = ({
  showSaveButton,
  name,
  fileType,
  getFileData,
  data,
  directory,
  sendToFileSystemRaw,
  setFolderContentsOrFileContents,
}) => {
  const [newData, setNewData] = useState<string>(data);
  const changes = useRef<string[]>([newData]);
  const [open, setOpen] = useState<boolean>(false);

  // (1) It may be appropriate to make sub components react
  // fc and stick in there in future
  // ^I doubt this was true but i'm too scared to change it
  const changesMade: boolean = newData != data;

  const setHaveThereBeenChanges = useState(changesMade)[1];

  const fileRef = useFileContext();
  useAddFileListener(fileRef, setHaveThereBeenChanges);

  useEffect(() => {
    getFileData.current = getData;
    showSaveButton(changesMade);

    updateFileState(changesMade, fileRef);
  }, [newData]);

  function downloadFile(content: Blob, fileName: string, mimeType: string) {
    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");

    downloadLink.href = url;
    downloadLink.download = fileName;

    // Append download link to the DOM and trigger a click to start the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up after the download is complete
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }

  // Usage example
  const fileContent = new Blob([newData]);
  const fileName = name;
  const mimeType = "text/plain";

  const handleChanges = (value: string) => {
    setNewData(value);
    changes.current = [value].concat(changes.current);
  };

  const handleUndo = () => {
    changes.current.shift();
    setNewData(changes.current[0]);
  };

  const handleDownload = () => {
    downloadFile(fileContent, fileName, mimeType);
  };

  const handleSendToFileSystemRaw = () => {
    sendToFileSystemRaw(newData, directory);
    setFolderContentsOrFileContents(newData);
    showSaveButton(false);
    updateFileState(false, fileRef);
  };

  const getData = () => {
    return newData;
  };

  const txtDisplay = (defValue = data /*see (1)*/): JSX.Element => {
    return (
      <Box position="relative">
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Box sx={{ zIndex: 1 }}>
            {changesMade ? (
              <IconButton onClick={handleUndo} size="small">
                <Tooltip title="undo">
                  <UndoIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            ) : null}

            <IconButton size="small">
              <Tooltip title="Download" onClick={handleDownload}>
                <DownloadIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
        <TextField
          fullWidth
          multiline
          value={newData} // nescessar fr fr
          spellCheck={false}
          onChange={(e) => {
            handleChanges(e.target.value);
          }}
        ></TextField>
      </Box>
    );
  };

  const returnMap = new Map<ViewType, JSX.Element>([
    [fileType, txtDisplay(data)],
  ]);

  return (
    <>
      {returnMap.get(fileType)}
      {changesMade ? (
        <Box mt="10px" textAlign="center">
          <Button variant="contained" onClick={handleSendToFileSystemRaw}>
            <Typography color="white">Save Changes</Typography>
          </Button>
        </Box>
      ) : null}
      <UploadPopup open={open} setOpen={setOpen} />
    </>
  );
};

export default FileDisplays;
