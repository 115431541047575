import { Box, Button, Container, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { ping } from "@network-utils/tcp-ping";

import unitedKingdom from "../../assets/unitedKingdom.svg";
import unitedStates from "../../assets/unitedStates.svg";
import germany from "../../assets/germany.svg";
import france from "../../assets/france.svg";
import spain from "../../assets/spain.svg";
import { FC, useState } from "react";
import ButtonOrLatency from "./ButtonOrLatency";

type countryCard = {
  country: string;
  cities: string;
  image: string;
  buttonOrLatency: JSX.Element;
  xs: number;
  sm: number;
};

const Locations = () => {
  const [buttonOrLatency, setButtonOrLatency] = useState<JSX.Element>(<></>);

  const countries: countryCard[] = [
    {
      country: "United Kingdom",
      cities: "London",
      image: unitedKingdom,
      xs: 7.5,
      sm: 5,
      buttonOrLatency: (
        <ButtonOrLatency
          cities={["London"]}
          ips={["https://www.united-internet.de/en/company.html"]}
        />
      ),
    },
    {
      country: "France",
      cities: "Paris",
      image: france,
      buttonOrLatency: (
        <ButtonOrLatency
          ips={["https://www.digitalrealty.com"]}
          cities={["Paris"]}
        />
      ),
      xs: 7.5,
      sm: 5,
    },
    {
      country: "Germany",
      cities: "Frankfurt and Berlin",
      image: germany,
      buttonOrLatency: (
        <ButtonOrLatency
          cities={["Frankfurt", "Berlin"]}
          ips={["https://www.equinix.de", "https://www.united-internet.com"]}
        />
      ),
      xs: 7.5,
      sm: 5,
    },
    {
      country: "Spain",
      cities: "Logroño",
      image: spain,
      buttonOrLatency: (
        <ButtonOrLatency cities={["Logroño"]} ips={["https://www.arsys.net"]} />
      ),
      xs: 7.5,
      sm: 7.5,
    },
    {
      country: "United States",
      cities: "Las Vegas and Newark",
      image: unitedStates,
      buttonOrLatency: (
        <ButtonOrLatency
          cities={["Las Vegas", "Newark"]}
          ips={["https://www.switch.com", "https://www.qtsdatacenters.com"]}
        />
      ),
      xs: 15,
      sm: 7.5,
    },
  ];

  return (
    <Box paddingY="40px" sx={{ backgroundColor: "background.elevation2" }}>
      <Container maxWidth="lg" disableGutters>
        <Grid container spacing={2} columns={15} width="100%" pl="16px">
          <Grid xs={15}>
            <Paper
              sx={{
                padding: "10px",
              }}
            >
              <Typography variant="h6" fontWeight="bold" textAlign="center">
                WORLD WIDE COVERAGE
              </Typography>
            </Paper>
          </Grid>
          {countries.map((country, index) => {
            return (
              <Grid xs={country.xs} sm={country.sm} md={3}>
                <Paper
                  sx={{
                    padding: "10px",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" textAlign="center">
                    {country.country}
                  </Typography>
                  <Box height="300px" mt="8px">
                    <img
                      src={country.image}
                      width="100%"
                      height="100%"
                      alt={country.country}
                    />
                  </Box>
                  <Typography mt="8px" variant="body2" textAlign="center">
                    {country.cities}
                  </Typography>
                  {country.buttonOrLatency}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default Locations;
