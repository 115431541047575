import {
  Box,
  Button,
  Container,
  Dialog,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { useUserContext } from "./UserContextProvider";
import TicketThread from "./TicketThread";
import Cancel from "@mui/icons-material/Cancel";
import TicketCreator from "./TicketCreator";

export type message = { name: string; message: string };

type TicketThread = {
  threadDetails: { name: string; ticketType: string };
  messages: message[];
}[];

const Tickets = () => {
  const { name } = useUserContext();

  const [showAddTicket, setShowAddTicket] = useState<boolean>(false);

  // Replace this with code to fetch data from Firebase
  const [tickets, setTickets] = useState<TicketThread>([
    {
      threadDetails: { name: "Im moving to APEX", ticketType: "issue" },
      messages: [
        { name: name, message: "Your servers suck so much!" },
        { name: "OP Servers", message: "Fuck you" },
      ],
    },
    {
      threadDetails: {
        name: "Im so sorry I moved to Apex",
        ticketType: "payment",
      },
      messages: [
        {
          name: name,
          message:
            "Please take me back! Please take me back!Please take me back!Please take me back!Please take me back!Please take me back!Please take me back!Please take me back!Please take me back!Please take me back!Please take me back!Please take me back!",
        },
        { name: "OP Servers", message: "Fuck you" },
      ],
    },
  ]);

  return (
    <Stack spacing="16px">
      <Box display="flex" justifyContent="flex-end">
        <Box>
          <Button variant="contained" onClick={() => setShowAddTicket(true)}>
            New Ticket
          </Button>
        </Box>
      </Box>
      {tickets.map((ticket, index) => {
        return (
          <TicketThread
            key={index}
            threadDetails={ticket.threadDetails}
            messages={ticket.messages}
          />
        );
      })}
      <TicketCreator open={showAddTicket} closeFunction={setShowAddTicket} />
    </Stack>
  );
};

export default Tickets;
