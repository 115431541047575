import { TextField, InputAdornment, Typography } from "@mui/material";
import { FC } from "react";
import { updateJem, useJemListener } from "../../../utils/JemStore";

interface ConsoleInputProps {
  handleSubmit: (inputValue: string) => void;
  inputtedCommands: React.MutableRefObject<string[]>;
  arrayPosition: React.MutableRefObject<number>;
}

const ConsoleInput: FC<ConsoleInputProps> = ({
  handleSubmit,
  inputtedCommands,
  arrayPosition,
}) => {
  const inputJem = useJemListener<string>("input-value");

  const handleEnterKeyPress = () => {
    if (inputJem.getValue().trim() === "") {
      return;
    } else {
      console.log(inputJem.getValue());
      handleSubmit(inputJem.getValue());
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleEnterKeyPress();
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      arrayPosition.current -= 1;
      if (arrayPosition.current < 0) {
        arrayPosition.current = 0;
        return;
      }

      updateJem(inputJem, inputtedCommands.current[arrayPosition.current]);
    }
    if (event.key === "ArrowDown") {
      arrayPosition.current += 1;
      if (arrayPosition.current > inputtedCommands.current.length - 1) {
        arrayPosition.current = inputtedCommands.current.length;
        return;
      }

      updateJem(inputJem, inputtedCommands.current[arrayPosition.current]);
    }
  };

  return (
    <TextField
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ color: "black", pl: "8px" }} position="start">
            <Typography>/</Typography>
          </InputAdornment>
        ),
        style: { color: "black" },
      }}
      sx={{ color: "black" }}
      fullWidth
      size="small"
      variant="standard"
      value={inputJem.getValue()}
      onChange={(event) => updateJem(inputJem, event.target.value)}
      onKeyDown={handleKeyPress}
    />
  );
};

export default ConsoleInput;
