import { Paper } from "@mui/material";
import { FC, ReactNode } from "react";

interface AccountDetailContainerProps {
  children: ReactNode;
}

const AccountDetailContainer: FC<AccountDetailContainerProps> = ({
  children,
}) => {
  return (
    <Paper sx={{ backgroundColor: "background.elevation4", p: "16px" }}>
      {children}
    </Paper>
  );
};

export default AccountDetailContainer;
