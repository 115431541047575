import React, { createContext, useContext, useRef, ReactNode } from "react";

export type validButtonColours = "primary" | "error" | "warning" | "info";

export type PopupData = {
  title: string;
  buttonText: string;
  description: string;
  buttonColour: validButtonColours;
  func: () => void;
};

const PopupContext =
  createContext<React.MutableRefObject<PopupData | null> | null>(null);

export function usePopupContext(): React.MutableRefObject<PopupData | null> {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("usePopupContext must be used within a PopupProvider");
  }
  return context;
}

type PopupProviderProps = {
  children: ReactNode;
};

export const PopupProvider: React.FC<PopupProviderProps> = ({ children }) => {
  const popupDataRef = useRef<PopupData | null>({
    title: "",
    buttonText: "",
    buttonColour: "primary",
    description: "",
    func: () => {},
  });

  return (
    <PopupContext.Provider value={popupDataRef}>
      {children}
    </PopupContext.Provider>
  );
};
