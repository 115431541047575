import { Box, ButtonBase, Grid, Icon, Paper, TextField } from "@mui/material";
import React, { FunctionComponent, useRef, useState } from "react";

interface FileButtonProps {
  fileName: string;
  deleteMode: boolean;
  handleClick: (fileName: string) => void;
  viewingDirectory: React.MutableRefObject<string>;
  getIcon: (fileName: string) => JSX.Element;
  fileToBeDeletedName: React.MutableRefObject<string>;
  showPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileButton: FunctionComponent<FileButtonProps> = ({
  fileName,
  handleClick,
  deleteMode,
  fileToBeDeletedName,
  viewingDirectory,
  getIcon,
  showPopUp,
}) => {
  const actingFileFolderName = fileName.endsWith("/")
    ? fileName.slice(0, -1)
    : fileName;

  const nameRef = useRef<string>(actingFileFolderName);

  const [textFieldValue, setTextFieldValue] = useState(actingFileFolderName);

  const prohibetedFromDeletionArray: string[] = [
    "eula.txt",
    ".rcon-cli.env",
    "run.sh",
    "run.bat",
    "user_jvm_args.tsx",
    ".forge-manifest.json",
    ".modrinth-manifest.json",
  ];
  // ------------------------------------------------------------ //
  // These really ought to be stored on firebase so if this needs //
  // updating it doesnt require deploying the site to fix it!     //
  // ------------------------------------------------------------ //

  function detectProhibition(value: string): boolean {
    if (
      prohibetedFromDeletionArray.some(
        (name) =>
          actingFileFolderName.replace(name, "") !== actingFileFolderName
      )
    ) {
      return true;
    }
    if (value.startsWith("minecraft_server") && value.endsWith(".jar")) {
      return true;
    }

    return false;
  }

  function handleDelete() {
    fileToBeDeletedName.current = actingFileFolderName;
    showPopUp(true);
  }

  return (
    <Grid item xs={6} sm={4} md={3} lg={2} xl={2} key={actingFileFolderName}>
      <ButtonBase
        sx={{ width: "100%", height: "100%" }}
        onMouseLeave={() => {
          setTextFieldValue(nameRef.current);
        }}
      >
        <Paper
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "background.elevation1",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ width: "100%" }}
            onClick={
              !deleteMode
                ? () => {
                    handleClick(fileName);
                  }
                : detectProhibition(actingFileFolderName)
                ? () => {}
                : () => {
                    handleDelete();
                  }
            }
          >
            <Icon fontSize="large">{getIcon(fileName)}</Icon>
          </Box>
          <Box sx={{ width: "100%", px: "4px" }}>
            <TextField
              disabled={detectProhibition(actingFileFolderName)}
              variant="standard"
              fullWidth
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  nameRef.current = textFieldValue;
                }
              }}
              inputProps={{ style: { textAlign: "center" }, spellCheck: false }}
            />
          </Box>
        </Paper>
      </ButtonBase>
    </Grid>
  );
};

export default FileButton;
