import { Box } from "@mui/material";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import BlogHeroSection from "../components/blog/BlogHeroSection";
import BlogHome from "../components/blog/BlogHome";

const Blogs = () => {
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <NavBar />
      <BlogHeroSection />
      <BlogHome />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Blogs;
