import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { FC, useRef, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";

import LocationDropDown from "./LocationDropDown";
import UserName from "./UserName";
import Cart from "./Cart";
import SubDomain from "./SubDomain";
import Version from "./Version";
import RamSlider from "./RamSlider";
import Addons from "./Addons";
import ConstructionWarning from "../ConstructionWarming";

interface PlanProps {
  ram: number;
  defaultLocation: string | null;
}

const Plan: FC<PlanProps> = ({ ram, defaultLocation }) => {
  const userNameListeners = useRef<
    React.Dispatch<React.SetStateAction<string>>[]
  >([]);

  const subDomainCheckedListeners = useRef<
    React.Dispatch<React.SetStateAction<boolean>>[]
  >([]);

  const ramListeners = useRef<React.Dispatch<React.SetStateAction<number>>[]>(
    []
  );

  return (
    <Box
      width="100%"
      height="100%"
      sx={{ backgroundColor: "background.elevation4" }}
    >
      <NavBar />
      <ConstructionWarning />
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          paddingY: "32px",
        }}
      >
        <Typography variant="h1" fontSize="48px" textAlign="center">
          Configure your plan
        </Typography>
        <Divider sx={{ my: "32px" }} />
        <Box display="grid" gridTemplateColumns="3fr 1fr" gap="16px">
          <Stack gridColumn="1" spacing="32px">
            <Paper sx={{ padding: "16px" }}>
              <Stack spacing="16px">
                <UserName userNameListeners={userNameListeners} />
                <LocationDropDown defaultLocation={defaultLocation} />
                <SubDomain userNameListeners={userNameListeners} />
                <Version />
                <RamSlider ramListeners={ramListeners} initialValue={ram} />
              </Stack>
            </Paper>
            <Typography
              variant="h2"
              fontSize="24px"
              textAlign="center"
              color="primary"
            >
              Icing on the cake!
            </Typography>
            <Addons subDomainCheckedListeners={subDomainCheckedListeners} />
          </Stack>
          <Box gridColumn="2">
            <Paper sx={{ padding: "16px", position: "sticky", top: "100px" }}>
              <Cart
                initialRam={ram}
                ramListeners={ramListeners}
                userNameListeners={userNameListeners}
                subDomainCheckedListeners={subDomainCheckedListeners}
              />
            </Paper>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Plan;
