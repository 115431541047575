import { Box, Button, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { ServerDataStruct } from "../../../backend/ServerData";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useJemListener } from "../../../utils/JemStore";

interface MyModsProps {

}

const MyMods: FC<MyModsProps> = () => {
  const serverDataJem = useJemListener<ServerDataStruct | null>("server-data")

  if (serverDataJem.getValue() == null) {
    return (
      <p>
        If you can read this something has gone seriously wrong, please contact
        support with code "catastrophe" ASAP.
      </p>
    );
  }
  const modsData = serverDataJem.getValue()!.mods;

  return (
    <Paper sx={{ padding: "8px" }}>
      <Box
        sx={{ p: "8px", backgroundColor: "background.elevation2" }}
        display="flex"
      >
        <Box flex={1} display="flex" height="36.5px" alignItems="center">
          <Typography>Name</Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>Installed Through Us?</Typography>
        </Box>
        <Box flex={1}></Box>
      </Box>
      {modsData?.map((mod, index) => {
        return (
          <Box
            key={index}
            sx={{
              backgroundColor: index % 2 !== 0 ? "background.elevation2" : "",
              p: "8px",
            }}
            display="flex"
          >
            <Box flex={1} display="flex" alignItems="center">
              <Typography>{mod.name}</Typography>
            </Box>
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {!mod.custom_jar ? (
                <DoneIcon fontSize="large" color="primary" />
              ) : (
                <CloseIcon fontSize="large" color="error" />
              )}
            </Box>
            <Box flex={1} display="flex" justifyContent="flex-end">
              <Button variant="contained" color="error">
                Uninstall
              </Button>
            </Box>
          </Box>
        );
      })}
    </Paper>
  );
};

export default MyMods;
