import { FC, memo, useEffect, useState } from "react";
import { logout } from "../../firebase";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEmailContext, useUserContext } from "./UserContextProvider";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useJemListener } from "../../utils/JemStore";

const ProfileInfo: FC = () => {
  const { name, setName } = useUserContext();
  const { email, setEmail } = useEmailContext();

  const [nameDisabled, setNameDisabled] = useState<boolean>(true);
  const [emailDisabled, setEmailDisabled] = useState<boolean>(true);
  const [receiveUpdates, setReceiveUpdates] = useState(false);

  const [localName, setLocalName] = useState<string>(name);
  const [localEmail, setLocalEmail] = useState<string>(email);

  const [changesMade, setChangesMade] = useState<boolean>(false);

  const [arrowRotation, setArrowRotation] = useState<number>(0);
  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const handleArrowClick = () => {
    setArrowRotation(arrowRotation + 180);
    setShowDeleteIcon(!showDeleteIcon);
  };

  useEffect(() => {
    setLocalName(name);
    setLocalEmail(email);
  }, [name, email]);

  useEffect(() => {
    if (localName !== name || localEmail !== email) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [localName, localEmail, name, email]);

  const handleEditClick = (fieldName: string) => {
    if (fieldName === "name") {
      setNameDisabled(!nameDisabled);
    } else if (fieldName === "email") {
      setEmailDisabled(!emailDisabled);
    }
  };

  const handleCheckboxChange = () => {
    setReceiveUpdates(!receiveUpdates);
  };

  if (name == "" || name == null || name == undefined) {
    return <Typography>Fetching your data...</Typography>;
  }

  return (
    <Stack spacing="16px">
      <TextField
        disabled={nameDisabled}
        defaultValue={name}
        onChange={(e) => {
          setLocalName(e.target.value);
          console.log(localName);
        }}
        InputProps={{
          endAdornment: (
            <EditIcon
              onClick={(e) => {
                handleEditClick("name");
              }}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
      />
      <TextField
        disabled={emailDisabled}
        defaultValue={email}
        onChange={(e) => {
          setLocalEmail(e.target.value);
          console.log(localEmail);
        }}
        InputProps={{
          endAdornment: (
            <EditIcon
              onClick={() => handleEditClick("email")}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={receiveUpdates}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="I would like to recieve regular emails about our products and services"
      />

      <Box display="flex">
        <Box flex={1} mr="8px">
          <Button
            variant="contained"
            color="warning"
            onClick={logout}
            fullWidth
          >
            Logout
          </Button>
        </Box>
        <Box flex={1} ml="8px">
          <Button variant="contained" color="warning" fullWidth>
            Request password reset
          </Button>
        </Box>
      </Box>

      {changesMade && (
        <Button variant="contained" color="primary">
          Save Changes
        </Button>
      )}
      <Box>
        <Paper sx={{ display: "flex " }}>
          <Box flex={1}></Box>
          <Typography
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="error"
            textAlign="center"
          >
            DANGER ZONE
          </Typography>
          <Box
            flex={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton onClick={handleArrowClick} size="medium">
              <KeyboardArrowDownIcon
                fontSize="medium"
                style={{
                  transform: `rotate(${arrowRotation}deg)`,
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </IconButton>
          </Box>
        </Paper>
        <Collapse in={showDeleteIcon}>
          <Box
            width="100%"
            borderLeft="1px solid white"
            borderBottom="1px solid white"
            borderRight="1px solid white"
            p="8px"
          >
            <Button
              variant="contained"
              fullWidth
              color="error"
              onClick={() => setShowDeleteDialog(true)}
            >
              Delete Account?
            </Button>
          </Box>
        </Collapse>
      </Box>
      <Dialog open={showDeleteDialog}>
        <Paper sx={{ width: "320px", p: "8px" }}>
          <Paper
            sx={{
              backgroundColor: "background.elevation4",
              p: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight="bold">DELETE YOUR ACCOUNT?</Typography>
            <IconButton onClick={() => setShowDeleteDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Paper>

          <Button
            sx={{ mt: "8px" }}
            variant="contained"
            color="error"
            fullWidth
          >
            Send deletion code
          </Button>
        </Paper>
      </Dialog>
    </Stack>
  );
};

export default memo(ProfileInfo);
