import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FunctionComponent, useState } from "react";

interface TicketCreatorProps {
  open: boolean;
  closeFunction: React.Dispatch<React.SetStateAction<boolean>>;
}

const TicketCreator: FunctionComponent<TicketCreatorProps> = ({
  open,
  closeFunction,
}) => {
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [selectedIssue, setSelectedIssue] = useState(
    "What do you need help with?"
  );

  return (
    <Dialog open={open}>
      <Paper sx={{ p: "8px", width: "300px" }}>
        <Stack spacing="16px">
          <Paper
            sx={{
              p: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "background.elevation4",
            }}
          >
            <Typography fontWeight="bold">CREATE NEW TICKET</Typography>
            <IconButton color="error" onClick={() => closeFunction(false)}>
              <Cancel />
            </IconButton>
          </Paper>

          <FormControl>
            <InputLabel>What do you need help with?</InputLabel>
            <Select
              value={selectedIssue}
              label="What do you need help with?"
              onChange={(e) => setSelectedIssue(e.target.value)}
              placeholder="What do you need help with?"
            >
              <MenuItem value="issue">Issue</MenuItem>
              <MenuItem value="payment">Payment</MenuItem>
            </Select>
          </FormControl>

          <TextField
            placeholder="Subject"
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            placeholder="Initial Message"
            multiline
            minRows={12}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button variant="contained">Submit Ticket</Button>
        </Stack>
      </Paper>
    </Dialog>
  );
};

export default TicketCreator;
