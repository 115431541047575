import { Box, Container, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import controlPanelCapture from "../../assets/ControlPanelCapture.png";
import serverPropertiesCapture from "../../assets/serverPropertiesCapture.png";
import consoleCapture from "../../assets/consoleCapture.png";
import quickButtonCapture from "../../assets/quickButtonCapture.png";
import fileCapture from "../../assets/fileCapture.png";
import { useState } from "react";

type Slide = {
  title: string;
  description: string;
  image: string;
};

type Slides = Slide[];

const NewCarousel: React.FC = () => {
  const carouselSlides: Slides = [
    {
      title: "Bespoke Control Panel",
      description:
        "Customize and fine-tune your server settings with our tailored control panel",
      image: controlPanelCapture,
    },
    {
      title: "Manage your server with intuitive UI everywhere for everything",
      description:
        "Effortlessly oversee your server operations using an intuitive interface designed for all your needs.",
      image: serverPropertiesCapture,
    },
    {
      title: "Easy to use console with pre made quick command buttons",
      description:
        "Simplify server command execution through an accessible console equipped with convenient preset commands.",
      image: consoleCapture,
    },
    {
      title: "Easy to use console with pre made quick command buttons",
      description:
        "Need to make your own for repeated commands for your plugins or mods, it's done in a few clicks and lasts forever",
      image: quickButtonCapture,
    },
    {
      title: "All your files in one place",
      description:
        "Efficiently manage your server files from a single location, including uploads, deletions, edits, and more, all without the need for an FTP server",
      image: fileCapture,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselSlides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselSlides.length - 1 : prevIndex - 1
    );
  };

  const currentSlide = carouselSlides[currentIndex];

  return (
    <Box paddingY="40px">
      <Container maxWidth="lg" disableGutters>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <IconButton size="large" onClick={prevSlide}>
              <ArrowBackIosIcon
                fontSize="large"
                color="primary"
                aria-label="Left"
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              borderWidth: "1px",
              borderColor: "#4BB543",
              borderStyle: "dashed",
            }}
          >
            <img
              src={currentSlide.image}
              style={{ width: "100%", aspectRatio: "16/9" }}
              alt={currentSlide.description}
            />
          </Box>
          <Box>
            <IconButton size="large" onClick={nextSlide} aria-label="Right">
              <ArrowForwardIosIcon fontSize="large" color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Typography
            mt="20px"
            variant="h5"
            fontWeight="bold"
            textAlign="center"
          >
            {currentSlide.title.toLocaleUpperCase()}
          </Typography>
          <Typography mt="10px" variant="h6" textAlign="center" px="16px">
            {currentSlide.description}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default NewCarousel;
