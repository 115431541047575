import { Container, Typography } from "@mui/material";

const PricingHeroSection = () => {
  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        paddingY: "40px",
      }}
    >
      <Typography
        variant="h1"
        fontWeight="bold"
        style={{ fontSize: "32px" }}
        textAlign="center"
      >
        TOP QUALITY, AFFORDABLE RATE
      </Typography>
      <Typography variant="h2" textAlign="center" style={{ fontSize: "16px" }}>
        Hit the ground running in minutes
      </Typography>
    </Container>
  );
};

export default PricingHeroSection;
