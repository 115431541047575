import { Stack } from "@mui/material";
import Invoice, { InvoiceProps } from "./Invoice";

const Payments = () => {
  // get me from firebase
  const invoices: InvoiceProps[] = [
    {
      paid: true,
      invoiceNumber: 1,
      date: "02/10/2023",
      serverInvoiceDetails: [
        {
          name: "TruthfulMoment",
          ram: 6,
          cost: 16,
        },
        {
          name: "MuscularBathTimeBoys",
          ram: 1,
          cost: 2.5,
        },
      ],
    },
    {
      paid: false,
      invoiceNumber: 2,
      date: "02/11/2023",
      serverInvoiceDetails: [
        {
          name: "testing",
          ram: 4,
          cost: 12,
        },
      ],
    },
  ];

  return (
    <Stack spacing="16px">
      {invoices.map((invoice, index) => {
        return (
          <Invoice
            key={index}
            paid={invoice.paid}
            invoiceNumber={invoice.invoiceNumber}
            date={invoice.date}
            serverInvoiceDetails={invoice.serverInvoiceDetails}
          />
        );
      })}
    </Stack>
  );
};

export default Payments;
