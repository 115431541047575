import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link } from "react-router-dom";
import { getValue } from "@testing-library/user-event/dist/utils";
import { ReactComponent as Logo } from "../assets/Logo.svg";

import { auth } from "../firebase";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { logout } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const pages = ["pricing", "features", "blog", "support"];
const settings = {
  "Account Details": "account-details",
  "My Servers": "Dashboard",
};

function ResponsiveAppBar() {
  const [user, loading, error] = useAuthState(auth);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#181919" }}>
      <Container disableGutters>
        <Toolbar disableGutters>
          {/*<Logo />*/}
          <Typography
            variant="h4"
            color="white"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: "none", md: "flex" },
              fontWeight: "bold",
            }}
          >
            OP • SERVERS
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Link to={`/${page}`} key={page}>
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography key={page} textAlign="center">
                      {page}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            color="white"
            sx={{
              pl: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: "bold",
            }}
          >
            OP • SERVERS
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "right",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Link key={page} to={`/${page}`}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {!user || loading ? (
              <Button
                variant="contained"
                sx={{ display: "flexgrow", backgroundColor: "primary", mr: 2 }}
                component={Link}
                to="/login"
                color="primary"
              >
                <Typography color="white">Sign In</Typography>
              </Button>
            ) : (
              <>
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{
                    color: "white",
                    "& .MuiSvgIcon-root": {
                      fontSize: "2.0rem", // Adjust the size as needed
                    },
                  }}
                >
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {Object.entries(settings).map(([setting, value]) => (
                    <Link key={setting} to={`/${value}`}>
                      <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography key={setting} textAlign="center">
                          {setting}
                        </Typography>
                      </MenuItem>
                    </Link>
                  ))}
                  <Link to="/">
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu();
                        logout();
                      }}
                    >
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Link>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
