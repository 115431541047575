import {
  Box,
  ButtonBase,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { PresetWorldsDataStruct } from "../../../backend/WorldData";
import Grid from "@mui/material/Unstable_Grid2";
import { usePopupContext } from "./PopupContextProvider";
import { useJem } from "../../../utils/JemStore";
import MapTile from "./MapTile";

interface WorldSearchProps {
  presetWorldsData: React.MutableRefObject<PresetWorldsDataStruct | null>;
  installWorld: (worldName: string) => void;
}
const mapCategories = [
  "Adventure",
  "Survival",
  "Creative",
  "Parkour",
  "Puzzle",
];

const WorldSearch = ({ presetWorldsData, installWorld }: WorldSearchProps) => {
  // const popupData = usePopupContext();
  // const showpopup = useJem("world-popup");

  // const updatePopup = () => {
  //   popupData.current = {
  //     title: "install this map?",
  //     description: "Install this map"
  //   }
  // }

  return (
    <>
      <Grid container spacing={1} mb="8px">
        <Grid xs={3} display="flex" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            COMMUNITY MAPS
          </Typography>
        </Grid>
        <Grid xs={6}>
          <TextField fullWidth placeholder="Search..." />
        </Grid>
        <Grid xs={3}>
          <Select fullWidth>
            {mapCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Paper
        sx={{
          backgroundColor: "background.elevation4",
          display: "flex",
          flex: 1,
          padding: { xs: "4px", md: "8px" },
          flexWrap: "wrap",
          overflowY: "auto",
          width: "100%",
        }}
      >
        {Object.entries(presetWorldsData.current?.worlds ?? {}).map(
          ([mapName, worldData]) => {
            return (
              <MapTile
                key={mapName}
                mapName={mapName}
                installWorld={installWorld}
                author={worldData.author}
                categories={worldData.categories}
                reference={worldData.ref}
                version={worldData.version}
              />
            );
          }
        )}
      </Paper>
    </>
  );
};

export default WorldSearch;
