import { Box } from "@mui/material";
import { useState } from "react";
import Overview from "./Overview";
import WorldCreator from "./WorldCreator";
import { PresetWorldsDataStruct } from "../../../backend/WorldData";

interface WorldProps {
  presetWorldsData: React.MutableRefObject<PresetWorldsDataStruct | null>;
  installWorld: (worldName: string) => void;
  setBackupListData: React.MutableRefObject<
    (data: { [backupName: string]: number }) => void
  >;
  queryBackupList: () => void;
  restoreSpecificBackup: (backupName: string) => void;
  deleteSpecificBackup: (backupName: string) => void;
  serverPropertiesData: React.MutableRefObject<{
    [property: string]: string;
  } | null>;
  modifyServerProperties: (
    propertiesToModify: { [property: string]: string },
    renameWorldFolderToo?: boolean
  ) => void;
  downloadWorldQuery: (worldName: string) => void;
}

const World = ({
  presetWorldsData,
  installWorld,
  setBackupListData,
  queryBackupList,
  restoreSpecificBackup,
  deleteSpecificBackup,
  serverPropertiesData,
  modifyServerProperties,
  downloadWorldQuery,
}: WorldProps) => {
  const overView: JSX.Element = (
    <Overview
      presetWorldsData={presetWorldsData}
      installWorld={installWorld}
      changeDisplayToOverview={() => setDisplay(worldCreator)}
      setBackupListData={setBackupListData}
      queryBackupList={queryBackupList}
      restoreSpecificBackup={restoreSpecificBackup}
      deleteSpecificBackup={deleteSpecificBackup}
      serverPropertiesData={serverPropertiesData}
      modifyServerProperties={modifyServerProperties}
      downloadWorldQuery={downloadWorldQuery}
    />
  );
  const [display, setDisplay] = useState<JSX.Element>(overView);
  const worldCreator: JSX.Element = (
    <WorldCreator handleBack={() => setDisplay(overView)} />
  );

  return (
    <Box pr="16px" pb="16px" height="100%" width="100%">
      {/*TODO: reset any world that might exist through mods e.g. planets or twilighit forest or aether etc
              reset end, reset nether
              multiple worlds (this would need to be paid, some of these others including number of backups need to be cost benefit analysed)
              world sharing (also would have to make this on a forum for our site)
              backups 
              download worlds from planet minecraft with api?
              upload world
              download world
              reset world
              world name
              world generation (with preview if possible)
              box / tab / popup*/}

      {display}
    </Box>
  );
};

export default World;
