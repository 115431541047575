import { ButtonBase, Paper } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { updateJem, useJem } from "../../../utils/JemStore";
import { usePopupContext } from "./PopupContextProvider";

const BuyBackupTile: React.FC = () => {
  const popupJem = useJem("world-popup");
  const popupData = usePopupContext();

  const handlePurchase = () => {
    alert("this should bring up a payment gateway");
  };

  const handleClick = () => {
    popupData.current = {
      title: "Buy new backup slot",
      description: "Get another backup slot for just 99 pence",
      func: handlePurchase,
      buttonColour: "primary",
      buttonText: "purchase!",
    };
    updateJem(popupJem, true);
  };

  return (
    <Paper
      sx={{
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
      }}
    >
      <ButtonBase
        sx={{ width: "100%", height: "100%" }}
        onClick={() => handleClick()}
      >
        <LockOpenIcon sx={{ fontSize: "64px" }} />
      </ButtonBase>
    </Paper>
  );
};

export default BuyBackupTile;
