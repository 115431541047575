import { FormControl, FormLabel, Slider } from "@mui/material";
import { FC, useEffect, useState } from "react";

interface RamSliderProps {
  ramListeners: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<number>>[]
  >;
  initialValue: number;
}

const RamSlider: FC<RamSliderProps> = ({ ramListeners, initialValue }) => {
  const [ram, setRam] = useState<number>(initialValue);

  useEffect(() => {
    ramListeners.current.push(setRam);
  }, []);

  const handleChange = (value: number | number[]) => {
    if (typeof value === "number") {
      ramListeners.current.forEach((ramSetter) => {
        ramSetter(value);
      });
    }
  };

  return (
    <FormControl>
      <FormLabel>Ram: {ram}GB</FormLabel>

      <Slider
        value={ram}
        onChange={(e, value) => {
          if (value !== null && value !== undefined) {
            handleChange(value);
          } else {
            // Handle the case where value is null or undefined
            handleChange(1); // Provide a default value or handle it as needed
          }
        }}
        valueLabelDisplay="auto"
        aria-labelledby="integer-slider"
        step={1}
        marks
        min={1}
        max={16}
      />
    </FormControl>
  );
};

export default RamSlider;
