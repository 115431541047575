import { Box, Paper } from "@mui/material";
import { FC } from "react";
import WorldProperties from "./WorldProperties";
import BackUps from "./Backups";
import WorldSearch from "./WorldSearch";
import { PresetWorldsDataStruct } from "../../../backend/WorldData";
import { PopupProvider } from "./PopupContextProvider";
import { JemContextProvider } from "../../../utils/JemStore";
import { WorldPopup } from "./WorldPopups";

interface OverViewProps {
  changeDisplayToOverview: () => void;
  presetWorldsData: React.MutableRefObject<PresetWorldsDataStruct | null>;
  installWorld: (worldName: string) => void;
  setBackupListData: React.MutableRefObject<
    (data: { [backupName: string]: number }) => void
  >;
  queryBackupList: () => void;
  restoreSpecificBackup: (backupName: string) => void;
  deleteSpecificBackup: (backupName: string) => void;
  serverPropertiesData: React.MutableRefObject<{
    [property: string]: string;
  } | null>;
  modifyServerProperties: (
    propertiesToModify: { [property: string]: string },
    renameWorldFolderToo?: boolean
  ) => void;
  downloadWorldQuery: (worldName: string) => void;
}

const gridContainerStyle = {
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gridTemplateRows: "repeat(12, 1fr)",
  gap: "16px",
};

const paperStyles = {
  height: "100%",
  padding: "8px",
  overflowY: "auto",
  width: "100%",
};

// type popupData = {
//   title: string;
//   buttonText: string;
//   description: string;
//   func: (param: string) => void;
// };

const Overview: FC<OverViewProps> = ({
  changeDisplayToOverview,
  presetWorldsData,
  installWorld,
  setBackupListData,
  queryBackupList,
  restoreSpecificBackup,
  deleteSpecificBackup,
  serverPropertiesData,
  modifyServerProperties,
  downloadWorldQuery,
}) => {
  // const getBackUpNamesAndImages = (): [string, string][] => {
  //   return [
  //     ["really long world name", ""],
  //     ["2", ""],
  //     ["3", ""],
  //     ["4", ""],
  //     ["5", ""],
  //     ["6", ""],
  //     ["7", ""],
  //     ["8", ""],
  //     ["9", ""],
  //     ["10", ""],
  //     ["11", ""],
  //     ["12", ""],
  //   ];
  //   /*"Dem get the images and back up names from fire base, and put them" +
  //     "in an array of the form [string, string][] xx"*/
  // };

  return (
    <PopupProvider>
      <JemContextProvider contextKey="world-popup" defaultValue={false}>
        <Box
          sx={{
            "@media (min-width: 900px)": gridContainerStyle,
          }}
        >
          <Box
            sx={{
              "@media (min-width: 900px)": {
                gridColumn: "1 / span 6",
                gridRow: "1/ span 6",
              },
              "@media (max-width: 900px) and (min-width: 0px)": {
                mb: "16px",
              },
            }}
          >
            <Paper sx={paperStyles}>
              <WorldProperties
                downloadWorldQuery={downloadWorldQuery}
                modifyServerProperties={modifyServerProperties}
                serverPropertiesData={serverPropertiesData}
                worldProperties={{
                  hasModdedDimensions: true,
                }}
              />
            </Paper>
          </Box>
          <Box
            sx={{
              "@media (min-width: 900px)": {
                gridColumn: "1 / span 6",
                gridRow: "7/ span 6",
              },
              "@media (max-width: 900px) and (min-width: 0px)": {
                mb: "16px",
                height: "320px",
              },
            }}
          >
            <Paper sx={paperStyles}>
              <BackUps
                setBackupListData={setBackupListData}
                queryBackupList={queryBackupList}
                restoreSpecificBackup={restoreSpecificBackup}
                deleteSpecificBackup={deleteSpecificBackup}
              />
            </Paper>
          </Box>
          <Box
            sx={{
              "@media (min-width: 900px)": {
                gridColumn: "7/ span 6",
                gridRow: "1 / span 12",
              },
              "@media (max-width: 900px) and (min-width: 0px)": {
                mb: "16px",
                width: "100%",
                height: "480px",
              },
            }}
          >
            <Paper
              sx={paperStyles}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <WorldSearch
                presetWorldsData={presetWorldsData}
                installWorld={installWorld}
              />
            </Paper>
          </Box>
        </Box>
        <WorldPopup />
      </JemContextProvider>
    </PopupProvider>
  );
};

export default Overview;
