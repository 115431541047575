import { Box, Icon, IconButton, Paper, Typography } from "@mui/material";
import forgeIcon from "../../../assets/anvil-impact-svgrepo-com.svg";
import SettingsIcon from "@mui/icons-material/Settings";

import { TileColours } from "./Styles";
import { FC, version } from "react";
import { type } from "@testing-library/user-event/dist/type";
import { ServerDataStruct } from "../../../backend/ServerData";
import { useJemListener } from "../../../utils/JemStore";

interface Tile_VersionProps {

}

const Tile_Version: FC<Tile_VersionProps> = () => {
  const serverDataJem = useJemListener<ServerDataStruct | null>("server-data")
  return (
    <Paper
      sx={{
        flex: 1,
        backgroundColor: TileColours["Paper.Green"],
        padding: "10px",
        display: "flex",
      }}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Box flex={1} display="flex">
          <Box flex={1} display="flex" pr="5px">
            <Paper
              elevation={0}
              sx={{
                backgroundColor: TileColours["Icon.Green"],
                flex: 1,
                display: "flex",
              }}
            >
              <img
                src={forgeIcon}
                alt="SVG"
                style={{ width: "100%", maxHeight: "100%", fill: "white" }}
              />
            </Paper>
          </Box>
          <Box flex={2} pl="5px" display="flex" flexDirection="column">
            <Box flex={1}>
              <Typography variant="body2">VERSION</Typography>
            </Box>
            <Box
              flex={2}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Typography variant="h5" fontWeight="bold">
                {serverDataJem.getValue()?.environment_variables["TYPE"].toUpperCase() ?? "VANILLA"}
              </Typography>
              <Typography fontStyle="italic">{serverDataJem.getValue()?.environment_variables["VERSION"].toUpperCase()}</Typography>
            </Box>
          </Box>
          <Box
            flex={1}
            display={{
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "flex",
              xl: "flex",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Box position="relative" height="100%">
              <Box
                position="absolute"
                sx={{ top: "25%", left: "-15px", zIndex: 2 }}
              >
                <IconButton>
                  <SettingsIcon fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box
            flex={1}
            display={{
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "none",
              xl: "none",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Box position="relative" height="100%">
              <Box
                position="absolute"
                sx={{ top: "25%", left: "-15px", zIndex: 2 }}
              >
                <IconButton>
                  <SettingsIcon fontSize="medium" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Tile_Version;
