import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
} from "@mui/material";
import GoodPaper from "../../GoodPaper";
import PlayerPopup from "../../Popup";
import { useState } from "react";
import { useJemListener } from "../../../utils/JemStore";

export type PlayerTableRow = {
  player: string;
  totalConnects: string;
  status: string;
  timeOnline: string;
  lastQuit: string;
  manage: string;
}

const PlayerTable = () => {
  const playersTableJem = useJemListener<PlayerTableRow[]>("players-table");
  const [clickedPlayerName, setClickedPlayerName] = useState<string>("");
  const [clickedTotalConnects, setclickedTotalConnects] = useState<string>("");
  const [clickedStatus, setclickedStatus] = useState<string>("");
  const [clickedTimeOnline, setclickedTimeOnline] = useState<string>("");
  const [clickedLastQuit, setclickedLastQuit] = useState<string>("");
  const [playerPopupOpen, setPlayerPopupOpen] = useState<boolean>(false);

  const handleClick = (
    name: string,
    totalConnects: string,
    status: string,
    timeOnline: string,
    lastQuit: string
  ) => {
    setClickedPlayerName(name);
    setclickedTotalConnects(totalConnects);
    setclickedStatus(status);
    setclickedTimeOnline(timeOnline);
    setclickedLastQuit(lastQuit);
    setPlayerPopupOpen(true);
  };

  const handleClose = () => {
    setClickedPlayerName("");
    setPlayerPopupOpen(false);
  };

  return (
    <GoodPaper elevation={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell>Total Connects</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Time Online</TableCell>
              <TableCell>Last Quit</TableCell>
              <TableCell>Manage</TableCell>
              {/* Add more TableCells for each column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {playersTableJem.getValue().map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.player}</TableCell>
                <TableCell>{row.totalConnects}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.timeOnline}</TableCell>
                <TableCell>{row.lastQuit}</TableCell>
                <TableCell>
                  <div>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClick(
                          row.player,
                          row.totalConnects,
                          row.status,
                          row.timeOnline,
                          row.lastQuit
                        );
                      }}
                    >
                      Manage
                    </Button>
                  </div>
                </TableCell>
                {/* Render more TableCell components based on your data structure */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ paddingTop: "4px" }}></Box>
      <PlayerPopup
        name={clickedPlayerName}
        totalConnects={clickedTotalConnects}
        status={clickedStatus}
        timeOnline={clickedTimeOnline}
        lastQuit={clickedLastQuit}
        open={playerPopupOpen}
        handleClose={handleClose}
      />
    </GoodPaper>
  );
};

export default PlayerTable;
