import { Button, Typography } from "@mui/material";
import { FC, useState } from "react";

interface ButtonOrLatencyProps {
  cities: string[];
  ips: string[];
}

enum PingState {
  UnClicked = "unclicked",
  Waiting = "waiting",
}

const ButtonOrLatency: FC<ButtonOrLatencyProps> = ({ ips, cities }) => {
  const [latencies, setLatencies] = useState<{
    [city: string]: number | PingState;
  }>(
    Object.fromEntries(
      cities.map((c) => {
        return [c, PingState.UnClicked];
      })
    )
  );

  const pingCities = () => {
    setLatencies((prevState) => Object.fromEntries(Object.keys(prevState).map((city) => [city, PingState.Waiting])));
  };

  return (
    <>
      {Object.values(latencies)[0] === PingState.UnClicked ? (
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: "8px" }}
          onClick={pingCities}
        >
          Test ping
        </Button>
      ) : null}

      {Object.keys(latencies).map(city => {
        console.log(city)
        return latencies[city] === PingState.UnClicked ? null : latencies[city] ===
          PingState.Waiting ? (
          <Typography>{city}: ...</Typography>
        ) : null;
      })}
    </>
  );
};

export default ButtonOrLatency;
